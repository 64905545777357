import { Card, CardContent, Typography } from "@mui/material"
import { useGetList } from "react-admin"

interface PropertyUsagesProps {
  bookingId: string
}

interface PropertyUsageElementProps {
  amount: number
  from: string
  to: string
  type: "debit" | "credit"
  code?: string
  kind: string
  is_pending?: boolean
}

const PropertyUsageElement = (usage: PropertyUsageElementProps) => {
  const code = usage.code ? `code: (${usage.code})` : ""
  const formattedKind = usage.kind.replaceAll("_", " ")
  return (
    <li>
      <strong>{formattedKind}:</strong> {usage.amount}, period: [{usage.from} - {usage.to}]
    </li>
  )
}

export const PropertyUsages = ({ bookingId }: PropertyUsagesProps) => {
  const { data: usages } = useGetList("property_usages", {
    filter: {
      booking_id: bookingId,
    },
  })

  return (
    <Card style={{ padding: 0 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Property Usages {usages?.length && usages[0].is_pending && "(pending)"}
        </Typography>

        <div>
          <ul>{usages?.map((usage) => <PropertyUsageElement {...usage} />)}</ul>
        </div>
      </CardContent>
    </Card>
  )
}
