import { InputProps, SelectInput } from "react-admin"

export const choices = [
  { id: "flat_fee", name: "Flat fee" },
  { id: "per_unit", name: "Per unit" },
  // { id: "ad_hoc", name: "Ad hoc" },
]

export const ProductPricingModelSelectInput: React.FC<InputProps> = (props) => (
  <SelectInput label="Pricing Model" {...props} choices={choices} />
)
