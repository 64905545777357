import React from "react"
import { useRecordContext } from "react-admin"

const CalendarTitle: React.FC = () => {
  const record = useRecordContext()

  if (!record) return null

  return <span>{record.title}</span>
}

export { CalendarTitle }
