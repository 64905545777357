import EntryStatusIcon from "@mui/icons-material/LocalOffer"

import { EntryStatusRefField } from "./EntryStatusRefField"
import {
  EntryStatusCreate,
  EntryStatusEdit,
  EntryStatusList,
  EntryStatusShow,
} from "./entry_statuses"

const resource = {
  icon: EntryStatusIcon,
  // list: EntryStatusList,
  show: EntryStatusShow,
  create: EntryStatusCreate,
  edit: EntryStatusEdit,
}

export {
  EntryStatusIcon,
  EntryStatusRefField,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
