import React from "react"
import { AutocompleteInputProps } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const OwnerRefInput: React.FC<Props> = (props) => {
  return (
    <AutocompleteInputLocal
      source="customer_id"
      {...props}
      optionText={(choice) => `${choice.display_name} (${choice.short_id})`}
      perPage={350}
      resource="customers"
    />
  )
}

export { OwnerRefInput }
