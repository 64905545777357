import { Alert, Box, Card, CardContent, Divider, Typography } from "@mui/material"
import { sortBy } from "lodash"
import { DateTime } from "luxon"
import { Loading, useGetOne } from "react-admin"

interface Props {
  quotaId: string
}

interface Active {
  limit: number
  value: number
}

interface Yearly {
  year: number
  limit: number
  value: number
  code?: string[]
}

interface Utilization {
  year: number
  limit: number
  peak: number
  low: number
  medium: number
  high: number
}

interface UsageApiResponse {
  id: string
  active_segments?: Active
  inquired_segments?: Active
  long_stays_per_year: Yearly[]
  nights_per_year: Yearly[]
  active_bookings_including_special_date_per_year: Yearly[]
  active_segments_in_mhp_per_year: Yearly[]
  seasonal_utilization_per_year: Utilization[]
}

interface UsageApiError {
  error: {
    message?: string
  }
}

const SeasonalUtilization = ({ utilization }: { utilization: Utilization[] }) => {
  if (!utilization) return null
  return (
    <div>
      {sortBy(utilization, ["year"]).map((u) => (
        <div key={`utilization${u.year}`}>
          <Typography color="text.secondary">{u.year}</Typography>
          <ul>
            <li>
              Peak: {u.peak}/<b>{u.limit}</b>
            </li>
            <li>
              High: {u.high}/<b>{u.limit}</b>
            </li>
            <li>
              Medium: {u.medium}/<b>{u.limit}</b>
            </li>
            <li>
              Low: {u.low}/<b>{u.limit}</b>
            </li>
          </ul>
        </div>
      ))}
    </div>
  )
}

const ActiveMetric = ({ name, params }: { name: string; params: Active | undefined }) => {
  if (!params) return null

  return (
    <div>
      {name}: {params.value}/<b>{params.limit}</b>
    </div>
  )
}
const YearlyMetric = ({ name, params }: { name: string; params: Yearly[] }) => {
  if (!params.length) return null
  return (
    <div>
      <Typography color="text.secondary">{name}:</Typography>
      <ul>
        {params.map((p) => (
          <li key={`${name}${p.year}`}>
            {p.year}: {p.value}/<b>{p.limit}</b>{" "}
            {p.code?.length ? <span>code: {p.code.join(", ")}</span> : null}
          </li>
        ))}
      </ul>
    </div>
  )
}

const DisplayError = ({ error }: UsageApiError) => {
  return (
    <Alert severity="warning">
      Something went wrong: <strong>{error?.message}</strong>
    </Alert>
  )
}

export const QuotaUsage = ({ quotaId }: Props) => {
  const now = DateTime.now()
  const lastYear = now.minus({
    year: 1,
  }).year

  const nextYear = now.plus({
    year: 1,
  }).year

  const {
    data: quotaUsage,
    isLoading,
    error,
  } = useGetOne<UsageApiResponse>(
    "booking_quotas",
    {
      id: `${quotaId}/usage?from=${lastYear}&to=${nextYear}`,
      meta: "tests",
    },
    {
      retry: false,
    },
  )

  if (isLoading) return <Loading />

  if (error) return <DisplayError error={error} />

  if (!quotaUsage) return null

  return (
    <div>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Current Active/Inquired Segments
          </Typography>
          <ActiveMetric name="Active segments" params={quotaUsage.active_segments} />
          <ActiveMetric name="Inquired segments" params={quotaUsage.inquired_segments} />
        </CardContent>
      </Card>

      <Box mb={2} />

      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Metrics per year
          </Typography>
          <YearlyMetric name="Nights" params={quotaUsage.nights_per_year} />
          <YearlyMetric name="Long stays" params={quotaUsage.long_stays_per_year} />
          <YearlyMetric
            name="Bookings with special days"
            params={quotaUsage.active_bookings_including_special_date_per_year}
          />
          <YearlyMetric
            name="Segments in main holiday periods"
            params={quotaUsage.active_segments_in_mhp_per_year}
          />
        </CardContent>
      </Card>

      <Box mb={2} />

      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Seasonal utilization:
          </Typography>
          <SeasonalUtilization utilization={quotaUsage.seasonal_utilization_per_year} />
        </CardContent>
      </Card>
    </div>
  )
}
