import { AutocompleteInputProps } from "react-admin"
import { useFormContext } from "react-hook-form"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const CalendarRefInput = (props: Props) => {
  const { resetField } = useFormContext()

  return (
    <AutocompleteInputLocal
      source="calendar_id"
      {...props}
      optionText="title"
      resource="calendars"
      onChange={() => {
        resetField("customer_id")
        resetField("user_id")
      }}
    />
  )
}

export { CalendarRefInput }
