import GroupIcon from "@mui/icons-material/AccountCircle"

import { GroupsList, GroupShow, GroupCreate, GroupEdit } from "./groups"

export const groupsResource = {
  icon: GroupIcon,
  list: GroupsList,
  show: GroupShow,
  create: GroupCreate,
  edit: GroupEdit,
}

export default groupsResource
