import {
  PropertyUsageCreate,
  PropertyUsageEdit,
  PropertyUsageList,
  PropertyUsageShow,
} from "./property_usages"

const resource = {
  list: PropertyUsageList,
  show: PropertyUsageShow,
  create: PropertyUsageCreate,
  edit: PropertyUsageEdit,
}

export default resource
