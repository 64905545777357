import React from "react"
import { ReferenceInputProps, InputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
  calendar_entry_id?: string
}

const GuestTemplateRefInput: React.FC<
  Props & Omit<ReferenceInputProps, "reference" | "children">
> = (props) => (
  <ReferenceInput
    source={props.source ?? "guest_template_id"}
    reference="guest_templates"
    filter={{ calendar_entry_id: props.calendar_entry_id }}
  >
    <SelectInput fullWidth={props.fullWidth} optionText="full_name" {...props} />
  </ReferenceInput>
)

GuestTemplateRefInput.defaultProps = {
  source: "guest_template_id",
}

export { GuestTemplateRefInput }
