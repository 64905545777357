import { AutocompleteInputProps } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const ContactRefInput = (props: Props) => {
  return (
    <AutocompleteInputLocal
      perPage={450}
      source="contact_id"
      {...props}
      optionText="name"
      resource="contacts"
      label="Contact"
      sort={{ field: "created_at", order: "DESC" }}
    />
  )
}

export { ContactRefInput }
