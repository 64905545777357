import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

interface Props {
  source?: string
}

const UserRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField source="user_id" {...props} reference="users" link="show" label="User">
    <TextField source="full_name" />
  </ReferenceField>
)

UserRefField.defaultProps = {
  source: "user_id",
}

export { UserRefField }
