import { isNumber } from "lodash"
import React from "react"
import {
  NumberInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  SimpleForm,
  required,
  FormDataConsumer,
  useGetOne,
  useRecordContext,
  number,
  minValue,
  maxValue,
} from "react-admin"

import { BillDto, BillGroupDto, LineItemDto } from "@/types/Bill"

import { MoneyInput } from "../../../inputs/MoneyInput"
import { BillGroupRefField, BillGroupRefInput } from "../bill_groups"
import { CategoryRefInput } from "../product_categories"
import { BillableAmountFromSelectInput } from "../product_categories/inputs/BillableAmountFromSelectInput"
import { ProductRefField, ProductRefInput } from "../products"
import { PricingModelSelectInput } from "./PricingModelSelectInput"
import { UnitSelectInput } from "./UnitSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillLineItemForm: React.FC<Props> = ({ isExisting = false }) => {
  const record = useRecordContext<LineItemDto>()
  const { data: group } = useGetOne<BillGroupDto>(
    "bill_groups",
    { id: record?.group_id ?? "" },
    { enabled: !!record?.group_id },
  )
  const { data: bill } = useGetOne<BillDto>(
    "bills",
    { id: record?.bill_id ?? group?.bill_id ?? "" },
    { enabled: !!record?.bill_id || !!group?.bill_id },
  )

  return (
    <SimpleForm>
      {!record?.bill_id &&
        (isExisting ? (
          <Labeled source="group_id" fullWidth>
            <BillGroupRefField source="group_id" />
          </Labeled>
        ) : (
          <BillGroupRefInput
            source="group_id"
            validate={[required()]}
            InputProps={{ disabled: true }}
          />
        ))}

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            {isExisting ? (
              <Labeled source="source_product_id" fullWidth>
                <ProductRefField source="source_product_id" />
              </Labeled>
            ) : (
              <>
                <CategoryRefInput
                  source="source_category_id"
                  property_id={bill?.property_id}
                  helperText="Select a category to create line item from existing product"
                />
                {formData.source_category_id && (
                  <ProductRefInput
                    source="source_product_id"
                    fullWidth
                    category_id={formData.source_category_id}
                  />
                )}
              </>
            )}

            <NumberInput source="quantity" defaultValue={1} />

            {!formData.source_product_id && <TextInput source="product_code" />}
            <br />
            {!formData.source_product_id && <TextInput source="title" />}
            <br />
            {!formData.source_product_id && <TextInput source="description" />}
            <br />
            {!formData.source_product_id && (
              <PricingModelSelectInput source="pricing_model" validate={[required()]} />
            )}

            {formData.pricing_model === "per_unit" && (
              <>
                <UnitSelectInput source="unit" />
                <NumberInput source="unit_count" />
              </>
            )}

            {!formData.source_product_id && <MoneyInput source="price_amount" required />}

            <NumberInput
              source="vat_rate"
              validate={[number(), minValue(0), maxValue(100)]}
              disabled={group?.is_condensed}
              defaultValue={isNumber(group?.vat_rate) ? group?.vat_rate : undefined}
              helperText={
                group?.is_condensed
                  ? "Can only be set on group level"
                  : "As integer only (eg. 7 or 19). For Net = Gross leave empty."
              }
            />

            <BillableAmountFromSelectInput
              source="billable_amount_from"
              helperText="The amount that has actually to be payed"
            />

            {!formData.source_product_id && !group?.is_condensed && (
              <>
                <NumberInput
                  source="discount_percentage"
                  helperText="A percentage will be discounted from this item."
                />
                <TextInput
                  source="discount_title"
                  helperText="Will be shown to describe the discount to customers."
                />
              </>
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  )
}
