import PropTypes from "prop-types"
import React from "react"
import { useNotify, Button, Confirm, useRecordContext, useCreate, useRefresh } from "react-admin"

const GenerateUniqueNumberButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [open, setOpen] = React.useState(false)

  const showGenerateBillNumberButton =
    record && !record?.unique_number && ["approved"].includes(record?.current_status)

  const [create, { isLoading }] = useCreate<any, Error>(undefined, undefined, {
    onError: (error) => {
      notify(`error: ${error.message}`, { type: "error" })
    },
    onSuccess() {
      refresh()
      notify(`Unique number has been added`, { type: "success" })
    },
  })

  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    create(`bills/${record.id}/unique_number`)
    setOpen(false)
  }

  if (!showGenerateBillNumberButton) return null

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        label="Generate Bill Number"
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Generate unique number for bill #${record && record.id}`}
        content="You are about to generate the unique invoice number. This action cannot be reverted. Only a cancellation is possible in case of any mistakes in the invoice. Are you sure that you want to continue? "
        cancel="Cancel"
        confirm="Yes"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

GenerateUniqueNumberButton.propTypes = {
  record: PropTypes.any,
}

export default GenerateUniqueNumberButton
