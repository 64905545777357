import React from "react"
import {
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  DateInput,
  FormDataConsumer,
} from "react-admin"

import { CalendarEntryRefField, CalendarEntryRefInput } from "../calendar_entries"
import { GuestTemplateRefInput } from "./GuestTemplateRefInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const GuestForm: React.FC<Props> = ({ isExisting = false, ...rest }) => {
  return (
    <SimpleForm {...rest}>
      {isExisting ? (
        <Labeled source="calendar_entry_id" fullWidth>
          <CalendarEntryRefField fullWidth source="calendar_entry_id" />
        </Labeled>
      ) : (
        <CalendarEntryRefInput source="calendar_entry_id" validate={[required()]} />
      )}

      <FormDataConsumer>
        {({ formData }) =>
          formData.calendar_entry_id && (
            <GuestTemplateRefInput
              source="guest_template_id"
              calendar_entry_id={formData.calendar_entry_id}
              isExisting={isExisting}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          !formData.guest_template_id && (
            <>
              <TextInput source="first_name" />
              <TextInput source="last_name" />
              <TextInput source="id_card_number" />
              <DateInput source="birth_date" />
              <TextInput source="email" />
              <TextInput source="phone" />
              <TextInput source="internal_notes" multiline rows={3} />
            </>
          )
        }
      </FormDataConsumer>

      <DateInput source="attended_from" />
      <DateInput source="attended_to" />
    </SimpleForm>
  )
}
