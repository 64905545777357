import polyglotI18nProvider from "ra-i18n-polyglot"
import { TranslationMessages } from "react-admin"
import englishMessages from "ra-language-english"

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
}

// const i18nProvider = polyglotI18nProvider(locale =>
//     locale === 'fr' ? frenchMessages : englishMessages,
//     'en' // Default locale
// );

// const i18nProvider = polyglotI18nProvider(() => ENi18n, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });
export const i18nProvider = polyglotI18nProvider((locale) => customEnglishMessages, "en", {
  allowMissing: true,
  onMissingKey: (key: any, _: any, __: any) => key,
})
