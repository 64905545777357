import { AutocompleteInput, ReferenceInput } from "react-admin"

const OpcoRefInput = () => {
  const filterToQuery = (searchText: string) => ({ search: searchText })

  return (
    <ReferenceInput
      filter={{ type: "opco" }}
      reference="companies"
      source="opco_id"
      label="OpCo"
      perPage={100}
    >
      <AutocompleteInput
        filterToQuery={filterToQuery}
        label="OpCo"
        optionText="name"
        debounce={500}
      />
    </ReferenceInput>
  )
}

export { OpcoRefInput }
