import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  NumberField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  PaginationProps,
  Pagination,
  BooleanField,
  DeleteButton,
} from "react-admin"

import appConfig from "../../../config/app.config"
import { SelectTimezoneInput } from "../../../inputs"
import { UserRefField } from "../../iam/users"
import { OwnerRefField } from "../../real_estate/owners"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { CreateMemberButton } from "../calendar_members/CreateMemberButton"
import { QuotaRefField } from "../quotas"
import { CalendarReferenceManyField } from "./CalendarReferenceManyField"
import { CalendarTitle } from "./CalendarTitle"
import { CalendarRefField } from "./CalendarRefField"
import { AddPropertyToCalendarButton } from "../../real_estate/properties/AddPropertyToCalendarButton"
import { useLocation } from "react-router-dom"

const CalendarPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const CalendarList = () => (
  <List
    exporter={false}
    pagination={<CalendarPagination />}
    perPage={appConfig.paginationOptions[0]}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="title" />
      <TextField source="resource_timezone" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const CalendarShow = () => {
  const router = useLocation()
  return (
    <Show title={<CalendarTitle />}>
      <TabbedShowLayout>
        <Tab label="Calendar">
          <CalendarReferenceManyField label={false} />
        </Tab>

        <Tab label="Access" path="members">
          <CreateMemberButton />
          <ReferenceManyField
            reference="calendar_members"
            target="calendar_id"
            // sort={{ field: "created_at", order: "DESC" }}
            label={false}
          >
            <Datagrid bulkActionButtons={false}>
              <UserRefField source="user_id" />
              <OwnerRefField source="customer_id" />
              <QuotaRefField source="default_quota_id" label="Default Quota" />
              <DateField source="created_at" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Properties" path="properties">
          <AddPropertyToCalendarButton />
          <ReferenceManyField reference="calendar_properties" target="calendar_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <CalendarRefField source="calendar_id" />
              <PropertyRefField source="property_id" />
              <BooleanField source="is_main" />
              <DateField source="created_at" />
              <UserRefField source="created_by" />
              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Calendar Details">
          <PropertyRefField source="property_id" />
          <TextField source="title" />
          <TextField source="id" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <TextField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const CalendarCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="title" validate={[required()]} />
      <SelectTimezoneInput source="resource_timezone" />
    </SimpleForm>
  </Create>
)

export const CalendarEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="title" />
      <SelectTimezoneInput source="resource_timezone" />
    </SimpleForm>
  </Edit>
)
