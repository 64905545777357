import ProviderIcon from "@mui/icons-material/Assignment"

import { ProviderAccountShow } from "./ProviderAccountShow"
import { ProviderAccountsList } from "./ProviderAccountsList"
import { ProviderAccountCreate, ProviderAccountEdit } from "./provider_accounts"

const providerAccountsResource = {
  show: ProviderAccountShow,
  create: ProviderAccountCreate,
  edit: ProviderAccountEdit,
  list: ProviderAccountsList,
  icon: ProviderIcon,
}

export default providerAccountsResource

export { ProviderIcon }
