import CategoryIcon from "@mui/icons-material/Label"

import { CategoryRefField } from "./CategoryRefField"
import { CategoryRefInput } from "./CategoryRefInput"
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from "./product_categories"

const resource = {
  icon: CategoryIcon,
  list: CategoryList,
  show: CategoryShow,
  create: CategoryCreate,
  edit: CategoryEdit,
}

export {
  CategoryIcon,
  CategoryRefField,
  CategoryRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
