import { DateField, Show, Tab, TabbedShowLayout, TextField } from "react-admin"

import { OwnerRefField } from "../owners"

export const GuestTemplateView = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Guest Template Details">
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="id_card_number" />
        <DateField source="birth_date" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="internal_notes" />
        <OwnerRefField source="customer_id" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <TextField source="membership_id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
