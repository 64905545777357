import { Alert } from "@mui/material"
import {
  ReferenceManyField,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Show,
  Create,
  Edit,
  RedirectionSideEffect,
  FunctionField,
  DeleteButton,
  SimpleShowLayout,
  BooleanField,
} from "react-admin"
import { PropertyUsageForm } from "./propertyUsageForm"
import { PropertyUsageKindChoices } from "./UsageKindSelectInput"

export const PropertyUsageList = () => (
  <ReferenceManyField
    reference="property_usages"
    source="id"
    target="booking_id"
    sort={{ field: "created_at", order: "DESC" }}
    label="All Property usages"
  >
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      empty={<Alert severity="info">No property usages yet</Alert>}
    >
      <FunctionField
        label="Kind (type of usage)"
        render={(record: { kind: string }) =>
          PropertyUsageKindChoices.find((p) => p.id === record.kind)?.name
        }
      />

      <DateField source="from" />
      <DateField source="to" />
      <TextField source="amount" />
      <TextField source="code" />
      <BooleanField source="is_pending" />
      <TextField source="type" />
      <EditButton />
      <DeleteButton mutationMode="pessimistic" redirect={false} />
    </Datagrid>
  </ReferenceManyField>
)

export const PropertyUsageShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="kind" />
      <DateField source="from" />
      <DateField source="to" />
      <TextField source="amount" />
      <TextField source="code" />
      <TextField source="type" />
      <BooleanField source="is_pending" />
    </SimpleShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) => {
  return data ? `calendar_entries/${data.booking_id}/show/property_usage` : "show"
}

export const PropertyUsageCreate = (props: any) => {
  return (
    <Create redirect={redirect}>
      <PropertyUsageForm isExisting={false} />
    </Create>
  )
}

export const PropertyUsageEdit = () => {
  return (
    <Edit redirect={redirect}>
      <PropertyUsageForm isExisting={true} />
    </Edit>
  )
}
