import BillPaymentStatusIcon from "@mui/icons-material/LocalOffer"

import { BillPaymentStatusRefField } from "./BillPaymentStatusRefField"
import {
  BillPaymentStatusCreate,
  BillPaymentStatusEdit,
  BillPaymentStatusList,
  BillPaymentStatusShow,
} from "./bill_payment_statuses"

const resource = {
  icon: BillPaymentStatusIcon,
  list: BillPaymentStatusList,
  show: BillPaymentStatusShow,
  create: BillPaymentStatusCreate,
  edit: BillPaymentStatusEdit,
}

export { BillPaymentStatusIcon, BillPaymentStatusRefField }

export default resource
