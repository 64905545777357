import { ListedPropertiesCrete } from "./ListedPropertiesCreate"
import { ListedPropertiesEdit } from "./ListedPropertiesEdit"
import { ListedPropertiesList } from "./ListedPropertiesList"
import { ListedPropertiesShow } from "./ListedPropertiesShow"

const listedPropertiesResource = {
  show: ListedPropertiesShow,
  edit: ListedPropertiesEdit,
  list: ListedPropertiesList,
  create: ListedPropertiesCrete,
}

export default listedPropertiesResource
