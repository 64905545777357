import { InputProps, SelectInput } from "react-admin"

// import { EntryTypeChoices } from "../constants"
export const TransparencyChoices = [
  { id: "opaque", name: "opaque" },
  { id: "transparent", name: "transparent" },
]

export const SelectEntryTransparencyInput: React.FC<InputProps> = (props) => (
  <SelectInput
    helperText="opaque = blocking, transparent = non blocking. More specific rules (eg. for bookings) may overwrite this."
    {...props}
    choices={TransparencyChoices}
  />
)
