import PropertyIcon from "@mui/icons-material/House"

import { PropertyRefField } from "./PropertyRefField"
import { PropertyRefInput } from "./PropertyRefInput"
import { PropertyTitle } from "./PropertyTitle"
import { PropertyList, PropertyShow, PropertyCreate, PropertyEdit } from "./properties"

const propertiesResource = {
  icon: PropertyIcon,
  list: PropertyList,
  show: PropertyShow,
  create: PropertyCreate,
  edit: PropertyEdit,
}

export {
  PropertyIcon,
  PropertyRefField,
  PropertyRefInput,
  PropertyTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default propertiesResource
