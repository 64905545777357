import { RedirectionSideEffect, Tab, TabbedShowLayout, useRecordContext } from "react-admin"
import { Create, Datagrid, DateField, Edit, List, Show, NumberField, TextField } from "react-admin"

import CentAsEuroField from "../../../fields/CentAsEuroField"
import { ProductRefField, ProductRefInput } from "../products"
import { PriceForm } from "./PriceForm"

const priceFilters = [<ProductRefInput source="product_id" />]

export const PriceList = () => (
  <List filters={priceFilters} exporter={false}>
    <Datagrid rowClick="show">
      <ProductRefField source="product_id" />
      <TextField source="title" />
      <NumberField source="precedence" />
      <DateField source="valid_from" />
      <DateField source="valid_to" />
      <CentAsEuroField source="amount" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
)

const PriceTitle = () => {
  const record = useRecordContext()

  return <span>{record ? record.title : ""} [Price]</span>
}

export const PriceShow = () => (
  <Show title={<PriceTitle />}>
    <TabbedShowLayout>
      <Tab label="Price Details">
        <ProductRefField source="product_id" />
        <TextField source="title" />
        {/* <TextField source="title_de" /> */}
        <CentAsEuroField source="amount" />
        <NumberField source="precedence" />
        <DateField source="valid_from" />
        <DateField source="valid_to" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? (data.product_id ? `products/${data.product_id}/show/prices` : "edit") : "show"

export const PriceCreate = () => (
  <Create redirect={redirect}>
    <PriceForm isExisting={false} />
  </Create>
)

export const PriceEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <PriceForm isExisting />
  </Edit>
)
