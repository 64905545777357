export const salutationChoices = [
  { id: "ms", name: "Ms" },
  { id: "mr", name: "Mr" },
  { id: "other", name: "Other" },
]

export const typeChoices = [
  { id: "person", name: "Person" },
  { id: "company", name: "Company" },
]
