import { detailedDiff } from "deep-object-diff"
import { pick } from "lodash"

interface IDiffDetails {
  added: Record<string, undefined> | {}
  deleted: Record<string, undefined> | {}
  updated: Record<string, undefined> | {}
}

const getKeysTypedAsArrayOrObject = (object: Record<string, unknown>) => {
  return Object.keys(object).filter(
    (key) =>
      Array.isArray(object[key]) || (typeof object[key] === "object" && object[key] !== null),
  )
}

export const countDiff = (
  newElements: Record<string, unknown>,
  prevElements: Record<string, unknown>,
  fieldsAlwaysToSent: string[] = [],
) => {
  const newKeysAsArrayOrObject = getKeysTypedAsArrayOrObject(newElements)

  const detailed = detailedDiff(prevElements, newElements) as IDiffDetails

  const newDataTypedAsArrayOrObject = pick(newElements, newKeysAsArrayOrObject)

  //Some of the fields have to be sent always. For example for the validation reason. The validation on the controller layer has no access to the DB so to compare 2 fields both need to be sent in the payload
  const alwaysSent = pick(prevElements, fieldsAlwaysToSent)

  const returnedData = {
    ...alwaysSent,
    ...detailed.added,
    ...detailed.updated,
    ...newDataTypedAsArrayOrObject,
  }

  return returnedData
}
