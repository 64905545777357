import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

interface Props {
  source?: string
}

const OwnershipRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    link="show"
    source="ownership_id"
    {...props}
    label="Ownerships"
    reference="ownerships"
  >
    <TextField source="id" />
  </ReferenceField>
)

OwnershipRefField.defaultProps = {
  label: "Ownership",
  source: "ownership_id",
}

export { OwnershipRefField }
