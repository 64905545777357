export const DocumentTypeChoices = [
  { id: "invoice", name: "Invoice" },
  { id: "payment_request", name: "Payment Request" },
]

export const UnitChoices = [
  { id: "night", name: "Night" },
  { id: "guest", name: "Guest" },
  { id: "night_and_guest", name: "Night and Guest" },
  { id: "pet", name: "Pet" },
  { id: "local_tourism_tax_total_count", name: "Total number of Local Tourism Tax" },
  { id: "requested_laundry_packages", name: "Requested Laundry Packages" },
  { id: "requested_bedrooms", name: "Requested Bedrooms" },
]

export const BillLanguageCodeChoices = [{ id: "de", name: "DE" }]

export const BillStatusChoices = [
  { id: "draft", name: "Draft" },
  { id: "approved", name: "Approved" },
  { id: "finalized", name: "Finalized" },
  { id: "closed", name: "Closed" },
  { id: "cancelled", name: "Cancelled" },
]

export const PaymentStatusChoices = [
  { id: "draft", name: "Draft" },
  { id: "awaiting_payment", name: "Awaiting Payment" },
  { id: "past_due", name: "Past Due" },
  { id: "paid", name: "Paid" },
  // { id: "payment_failed", name: "Payment Failed" },
  { id: "voided", name: "Voided" },
  { id: "uncollectible", name: "Uncollectible" },
]
