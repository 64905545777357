import { Alert } from "@mui/material"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  BooleanField,
  List,
  Show,
  NumberField,
  TextField,
  BooleanInput,
  RedirectionSideEffect,
  ShowButton,
  EditButton,
  TabbedShowLayout,
  Tab,
  useGetRecordId,
  ReferenceManyField,
  FunctionField,
  ReferenceField,
} from "react-admin"

import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { AddQuotaShareButton } from "../quota_shares/AddQuotaShareButton"
import { QuotaForm } from "./QuotaForm"
import { QuotaUsage } from "./Usage"
import { QuotaRefField } from "./QuotaRefField"
import { AddNewYearButton } from "../quota_limits/AddNewYearButton"
import { PropertyUsageKindChoices } from "../property_usages/UsageKindSelectInput"
import { ICalendarEntry } from "../../../types/ICalendarEntry"
import { formatDate } from "../../../utils/format-date"
import { isString } from "lodash"

const quotaFilters = [
  <PropertyRefInput source="property_id" />,
  <OwnerRefInput source="customer_id" />,
  <BooleanInput source="is_disabled" />,
]

export const QuotaList = () => (
  <List filters={quotaFilters} exporter={false} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <OwnerRefField source="customer_id" />
      <TextField source="title" />
      <NumberField source="share_count" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const QuotaShow = () => {
  const recordId = useGetRecordId()
  return (
    <Show>
      <TabbedShowLayout spacing={2}>
        <Tab label="Quota Details">
          <PropertyRefField source="property_id" />
          <OwnerRefField source="customer_id" />
          <TextField source="title" />
          <BooleanField source="is_disabled" />
          <BooleanField source="cannot_book_holiday_periods" />
          <TextField source="internal_notes" />
        </Tab>

        <Tab label="Quota shares" path="quota_shares">
          <AddQuotaShareButton />
          <ReferenceManyField
            reference="booking_quota_shares"
            target="quota_id"
            sort={{ field: "created_at", order: "ASC" }}
            label={false}
          >
            <Datagrid
              bulkActionButtons={false}
              empty={<Alert severity="info">No quota shares for this quota yet.</Alert>}
            >
              <NumberField source="share_number" />
              <DateField source="valid_from" />
              <DateField source="valid_to" />
              <DateField source="created_at" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Quota Usage">
          <QuotaUsage quotaId={recordId.toString()} />
        </Tab>

        <Tab label="Quota limits" path="quota_limits">
          <AddNewYearButton />
          <ReferenceManyField reference="quota_limits" target="quota_id" label={false}>
            <Datagrid bulkActionButtons={false} rowClick="show">
              <QuotaRefField source="quota_id" />
              <TextField source="year" />
              <NumberField source="max_nights" />
              <NumberField source="max_stays" />
              <NumberField source="max_inquired_segments" />
              <NumberField source="max_active_segments" />
              <NumberField source="max_active_segments_in_mhp" />
              <NumberField source="max_active_bookings_including_special_date" />
              <DateField source="created_at" showTime />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Property Usages">
          <ReferenceManyField
            reference="property_usages"
            source="id"
            target="quota_id"
            sort={{ field: "created_at", order: "DESC" }}
            label="Property Usages"
          >
            <Datagrid
              rowClick="show"
              bulkActionButtons={false}
              empty={<Alert severity="info">No property usages yet</Alert>}
            >
              <FunctionField
                label="Kind (type of usage)"
                render={(record: { kind: string }) =>
                  PropertyUsageKindChoices.find((p) => p.id === record.kind)?.name
                }
              />

              <DateField source="from" />
              <DateField source="to" />
              <TextField source="amount" />
              <TextField source="code" />
              <BooleanField source="is_pending" />
              <TextField source="type" />
              <ReferenceField
                label="Event"
                reference="calendar_entries"
                link="show"
                source="booking_id"
                sortBy="created_at"
                sortByOrder="ASC"
              >
                <FunctionField
                  label="Name"
                  render={(record: ICalendarEntry) =>
                    `${formatDate(record.start_date)}-${formatDate(record.end_date)} (${[
                      record.type,
                      record.booking_type,
                      record.season_type,
                    ]
                      .filter(isString)
                      .join("/")})`
                  }
                />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <TextField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const redirect: RedirectionSideEffect = (resource, id, data) => {
  return data ? `customers/${data.customer_id}/show/quotas` : "show"
}

export const QuotaCreate = () => (
  <Create redirect={redirect}>
    <QuotaForm isExisting={false} />
  </Create>
)

export const QuotaEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <QuotaForm isExisting />
  </Edit>
)
