import React from "react"
import { AutocompleteInputProps } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const PropertyRefInput: React.FC<Props> = (props) => {
  return (
    <AutocompleteInputLocal
      source="property_id"
      {...props}
      optionText="internal_name"
      resource="properties"
    />
  )
}

export { PropertyRefInput }
