import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddBillButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/bills/create",
      }}
      state={{
        record: {
          booking_id: record.id,
          customer_id: record.customer_id,
          check_in_day: record.start_date,
          check_out_day: record.end_date,
        },
      }}
    >
      <ButtonIcon /> Create New Bill
    </Button>
  )
}
