import PropertyIcon from "@mui/icons-material/House"
import { ContactCreate, ContactEdit, ContactList, ContactShow } from "./contacts"

const contactsResource = {
  icon: PropertyIcon,
  list: ContactList,
  show: ContactShow,
  create: ContactCreate,
  edit: ContactEdit,
}

export default contactsResource
