import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
  ShowButton,
  EditButton,
  RedirectionSideEffect,
  BooleanField,
} from "react-admin"
import { OwnerRefField } from "resources/real_estate/owners"
import { OwnershipRefField } from "resources/real_estate/ownerships/OwnershipRefField"
import { PropertyRefField } from "resources/real_estate/properties"
import { CalendarEntryRefField } from "resources/scheduling/calendar_entries"

import { RecipientProfileForm } from "./RecipientProfileForm"

// const listFilters = []

export const RecipientProfileList = () => (
  <List
    // filters={listFilters}
    exporter={false}
  >
    <Datagrid rowClick="show">
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const RecipientProfileShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Profile Details">
        <OwnerRefField source="customer_id" />
        <PropertyRefField source="property_id" />
        <OwnershipRefField source="ownership_id" />
        <CalendarEntryRefField source="booking_id" />
        <TextField source="booking_type" />

        <TextField source="profile_name" />

        <TextField source="internal_notes" />
      </Tab>

      <Tab label="Billing Contact">
        <TextField source="full_name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="address_field_1" />
        <TextField source="address_field_2" />
        <TextField source="address_city" />
        <TextField source="address_postal_code" />
        <TextField source="address_country" />
        <TextField source="tax_id" label="Tax ID" />
        <TextField source="vat_id" label="VAT ID" />
        <TextField source="commercial_register_number" />
        <TextField source="local_court" />
        <TextField source="tax_exempt" />
      </Tab>

      <Tab label="SEPA">
        <BooleanField source="has_sepa" />
        <TextField source="sepa_mandate_ref" />
        <TextField source="sepa_merchant_name" />
        <DateField source="sepa_signature_date" />
        <TextField source="sepa_payment_type" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `customers/${data.customer_id}/show/billing_recipient_profiles` : "show"

export const RecipientProfileCreate = () => (
  <Create redirect={redirect}>
    <RecipientProfileForm isExisting={false} />
  </Create>
)
export const RecipientProfileEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <RecipientProfileForm isExisting />
  </Edit>
)
