import { ChronoUnit, LocalDate } from "@js-joda/core"
import { isString } from "lodash"

export function calculateNightsBetween(d1: string | LocalDate, d2: string | LocalDate): number {
  if (isString(d1)) {
    d1 = LocalDate.parse(d1)
  }

  if (isString(d2)) {
    d2 = LocalDate.parse(d2)
  }

  return d1.until(d2, ChronoUnit.DAYS)
}
