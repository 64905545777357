import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import { green, purple, red } from "@mui/material/colors"
import makeStyles from "@mui/styles/makeStyles"
import { get } from "lodash"
import React from "react"
import { FieldProps, useRecordContext } from "react-admin"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

const BillStatusField: React.FC<FieldProps> = ({ source, emptyText }) => {
  const record = useRecordContext()
  const classes = useStyles()

  if (!record) return null

  let status = get(record, source ?? "current_status")

  if (!status && emptyText) {
    status = emptyText
  }

  let color = "black"

  switch (status) {
    case "draft":
      color = ""
      break
    case "approved":
      color = "#2196f3"
      break
    case "finalized":
      color = purple[500]
      break
    case "closed":
      color = green[500]
      break
    case "cancelled":
      color = red[500]
      break

    default:
      color = red[500]
      break
  }

  return (
    <div className={classes.root}>
      {status && <RadioButtonCheckedIcon className={classes.icon} style={{ color }} />}
      {status}
    </div>
  )
}

BillStatusField.defaultProps = {
  label: "Bill Status",
}

export default BillStatusField
