import { ReferenceInputProps, InputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const BillRefInput = (
  props: Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">,
) => (
  <ReferenceInput source="bill_id" reference="bills">
    <SelectInput fullWidth={props.fullWidth} optionText="id" {...props} />
  </ReferenceInput>
)

BillRefInput.defaultProps = {
  source: "bill_id",
}

export { BillRefInput }
