import { Typography, Card, CardContent, Grid, Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { FC } from "react"
import {
  TextField,
  RaRecord,
  Labeled,
  DateField,
  NumberField,
  ReferenceManyField,
  Datagrid,
} from "react-admin"
import { ClipboardListField } from "react-admin-clipboard-list-field"

import { OwnerRefField } from "../../real_estate/owners"
import { CalendarRefField } from "../../scheduling/calendars"

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    // TODO:
    // [theme.breakpoints.down("xl")]: {
    //   display: "none",
    // },
  },
}))

const createCopyRows = (value: any) => {
  return [value, `UserId("${value})"`]
}

interface AsideProps {
  record?: RaRecord
}

export const UserEditAside: FC<AsideProps> = ({ record }) => {
  const classes = useAsideStyles()

  return (
    <div className={classes.root}>
      <>
        <Box m="0 0 1em 1em">
          <Card>
            <CardContent>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6" gutterBottom>
                    Info
                  </Typography>

                  <Labeled source="id" resource="user" label="User ID">
                    <TextField source="id" resource="user" record={record} />
                  </Labeled>

                  <Labeled source="account_id" resource="user" label="Identity Platform ID">
                    <TextField source="account_id" resource="user" record={record} />
                  </Labeled>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Labeled source="created_at" resource="user">
                        <DateField showTime source="created_at" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                      <Labeled source="updated_at" resource="user">
                        <DateField showTime source="updated_at" />
                      </Labeled>
                    </Grid>
                  </Grid>

                  <Labeled source="version" resource="user">
                    <NumberField source="version" />
                  </Labeled>

                  <Labeled source="created_by" resource="user">
                    <TextField source="created_by" />
                  </Labeled>
                </Box>
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Calendar Access
              </Typography>
              <ReferenceManyField
                reference="calendar_members"
                target="user_id"
                // pagination={<Pagination />}
                fullWidth
              >
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <CalendarRefField />
                  <OwnerRefField />
                  <DateField source="created_at" label="Created" />
                </Datagrid>
              </ReferenceManyField>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Related Owners
              </Typography>
              <ReferenceManyField
                reference="customer_memberships"
                target="user_id"
                // pagination={<Pagination />}
                fullWidth
              >
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <OwnerRefField />
                  <DateField source="created_at" label="Created" />
                </Datagrid>
              </ReferenceManyField>
            </CardContent>
          </Card>
        </Box>
      </>
    </div>
  )
}
