import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddGroupButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/bill_groups/create",
      }}
      state={{ record: { bill_id: record.id } }}
    >
      <ButtonIcon /> Add Group
    </Button>
  )
}
