import BillGroupIcon from "@mui/icons-material/FolderOpen"

import { BillGroupRefField } from "./BillGroupRefField"
import { BillGroupRefInput } from "./BillGroupRefInput"
import { BillGroupCreate, BillGroupEdit, BillGroupList, BillGroupShow } from "./bill_groups"

const resource = {
  icon: BillGroupIcon,
  list: BillGroupList,
  show: BillGroupShow,
  create: BillGroupCreate,
  edit: BillGroupEdit,
}

export {
  BillGroupIcon,
  BillGroupRefField,
  BillGroupRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
