import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Show,
  NumberField,
  TextField,
  RedirectionSideEffect,
  ShowButton,
  EditButton,
  TabbedShowLayout,
  Tab,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
} from "react-admin"

import { QuotaRefField } from "../quotas"
import { QuotaShareForm } from "./QuotaShareForm"

const quotaFilters = [<QuotaRefField source="quota_id" />]

export const QuotaSharesList = () => (
  <List filters={quotaFilters} exporter={false} sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <NumberField source="share_number" />
      <DateField source="valid_from" />
      <DateField source="valid_to" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const QuotaShareShow = () => {
  return (
    <Show>
      <TabbedShowLayout spacing={2}>
        <Tab label="Quota Share Details">
          <QuotaRefField source="quota_id" />
          <NumberField source="share_number" />
          <DateField source="valid_from" />
          <DateField source="valid_to" />
          <TextField source="internal_notes" />
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <TextField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const redirect: RedirectionSideEffect = (resource, id, data) => {
  return data ? `booking_quotas/${data.quota_id}/show/quota_shares` : "show"
}

export const QuotaShareCreate = () => (
  <Create redirect={redirect}>
    <QuotaShareForm isExisting={false} />
  </Create>
)

const UserEditToolbar = (props: any) => {
  const record = useRecordContext()
  const bound = redirect.bind("quota", undefined, undefined, record)
  return (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <SaveButton />
      <DeleteButton redirect={bound} {...props} />
    </Toolbar>
  )
}

export const QuotaShareEdit = (data: any) => {
  return (
    <Edit mutationMode="pessimistic" redirect={redirect}>
      <QuotaShareForm isExisting toolbar={<UserEditToolbar />} />
    </Edit>
  )
}
