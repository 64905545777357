import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const GuestAddButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/calendar_entry_guests/create",
      }}
      state={{
        record: {
          calendar_entry_id: record.id,
        },
      }}
    >
      <ButtonIcon /> Add new guest
    </Button>
  )
}
