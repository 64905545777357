import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

interface Props {
  source?: string
}

const OwnerRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField link="show" source="customer_id" {...props} label="Owners" reference="customers">
    <TextField source="display_name" />
  </ReferenceField>
)

OwnerRefField.defaultProps = {
  label: "Owner",
  source: "customer_id",
}

export { OwnerRefField }
