import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import makeStyles from "@mui/styles/makeStyles"
import { get } from "lodash"
import { FC, memo } from "react"
import { FieldProps, useRecordContext } from "react-admin"

import { CalendarEntry } from "../../types"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

interface Props extends FieldProps<CalendarEntry> {
  size?: string
}

const EntryStatusField: FC<Props> = ({ source }) => {
  const record = useRecordContext()
  const classes = useStyles()

  if (!record) return null

  const status = get(record, source ?? "current_status")
  let color = "black"

  switch (status) {
    case "requested":
      color = "#2196f3"
      break
    case "tentative":
      color = ""
      break
    case "confirmed":
      color = "green"
      break
    case "declined":
      color = "red"
      break
    case "cancelled":
      color = "red"
      break

    default:
      break
  }

  return (
    <div className={classes.root}>
      {status && <RadioButtonCheckedIcon className={classes.icon} style={{ color: color }} />}
      {status}
    </div>
  )
}

EntryStatusField.defaultProps = {
  label: "Status",
}

export default memo<Props>(EntryStatusField)
