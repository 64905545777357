import { AutocompleteInputProps } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const GroupRefInput = (props: Props) => {
  return (
    <AutocompleteInputLocal
      source="group_id"
      {...props}
      optionText="name"
      resource="groups"
      label="Group"
    />
  )
}

export { GroupRefInput }
