import { SelectInput, SelectInputProps } from "react-admin"

export const GuestlistIdCardRequirementsChoices = [
  { id: "one_id_card_number", name: "Only an ID card number from one guest required" },
  { id: "all_id_card_numbers", name: "ID card numbers for all guests required" },
]

export const GuestlistIdCardRequirementsSelectInput: React.FC<SelectInputProps> = (props) => (
  <SelectInput {...props} choices={GuestlistIdCardRequirementsChoices} />
)
