import PriceIcon from "@mui/icons-material/Euro"

import { PriceRefField } from "./PriceRefField"
import { PriceRefInput } from "./PriceRefInput"
import { PriceCreate, PriceEdit, PriceList, PriceShow } from "./product_prices"

const resource = {
  icon: PriceIcon,
  // list: PriceList,
  show: PriceShow,
  create: PriceCreate,
  edit: PriceEdit,
}

export {
  PriceIcon,
  PriceRefField,
  PriceRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
