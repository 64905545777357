import IconKeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { Drawer } from "@mui/material"
import { get } from "lodash"
import { DateTime } from "luxon"
import React from "react"
import {
  Button,
  DateField,
  TextField,
  EditButton,
  ReferenceManyFieldProps,
  ResourceContextProvider,
  SimpleShowLayout,
  useGetList,
  useRecordContext,
  useGetMany,
  Identifier,
} from "react-admin"
import { SlotInfo, View } from "react-big-calendar"
import { UserRefField } from "resources/iam/users"
import { OwnerRefField } from "resources/real_estate/owners"
import EntryStatusField from "resources/scheduling/calendar_entries/fields/EntryStatusField"

import { ICalendarEntry, ICalendarEntryWithNames } from "@/types/ICalendarEntry"

import { EventCalendarView } from "./calendar_view/EventCalendarView"
import { LocalDate, convert } from "@js-joda/core"
import { AddStatusButton } from "../calendar_entries/AddStatusButton"

function normalizeResource<T extends { id: Identifier }>(data: T[]) {
  return data.reduce<Record<string, T>>((acc, val) => {
    if (val?.id) {
      acc[val.id] = val
    }
    return acc
  }, {})
}

interface CalendarReferenceManyFieldProps {
  reference?: string
  target?: string
  resource?: string
  showDate?: LocalDate
}

const CalendarReferenceManyField: React.FC<
  CalendarReferenceManyFieldProps &
    Omit<Omit<ReferenceManyFieldProps, "target">, "reference" | "children">
> = (props) => {
  const {
    // children,
    filter,
    // page = 1,
    // perPage,
    reference = "calendar_entries",
    resource,
    // sort,
    source,
    target,
    showDate,
  } = props
  const record = useRecordContext<ICalendarEntry>()
  const [range, setRange] = React.useState<[string, string]>([
    DateTime.now().minus({ months: 1 }).toISO(),
    DateTime.now().plus({ months: 1 }).toISO(),
  ])
  const [enhancedEntries, setEnhancedEntries] = React.useState<ICalendarEntryWithNames[]>([])
  const [selectedSlot, setSelectedSlot] = React.useState<SlotInfo | undefined>(undefined)
  const [selected, setSelected] = React.useState()

  const { data: entriesData } = useGetList<ICalendarEntry>("calendar_entries", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "start_date", order: "DESC" },
    filter: {
      start_date__gte: range?.[0],
      end_date__lte: range?.[1],
      calendar_id: get(record, source ?? "calendar_id"),
    },
  })

  const { data: usersData } = useGetMany(
    "users",
    {
      ids:
        entriesData
          ?.filter((e: ICalendarEntry) => e.user_id)
          ?.map((e: ICalendarEntry) => e.user_id) ?? [],
    },
    { enabled: !!entriesData },
  )

  const { data: customersData } = useGetMany(
    "customers",
    {
      ids:
        entriesData
          ?.filter((e: ICalendarEntry) => e.customer_id)
          ?.map((e: ICalendarEntry) => e.customer_id) ?? [],
    },
    { enabled: !!entriesData },
  )

  React.useEffect(() => {
    const usersDataMap = normalizeResource(usersData ?? [])
    const customersDataMap = normalizeResource(customersData ?? [])

    const data: ICalendarEntryWithNames[] =
      entriesData?.map((e) => {
        return {
          ...e,
          user_name: usersDataMap?.[e?.user_id]?.display_name,
          customer_name: customersDataMap?.[e?.customer_id]?.display_name,
        }
      }) ?? []

    setEnhancedEntries(data)
  }, [usersData, customersData, entriesData])

  React.useEffect(() => {
    if (showDate) {
      // First and last day of month
      const newStart = showDate.withDayOfMonth(1)
      const newEnd = showDate.plusMonths(1).withDayOfMonth(1).minusDays(1)

      // Add a week before and after the range
      // FIXME: This is a hack to ensure that the calendar shows the events that are on the edge of the range
      setRange([
        convert(newStart.minusWeeks(1)).toDate().toISOString(),
        convert(newEnd.plusWeeks(1)).toDate().toISOString(),
      ])
    }
  }, [showDate])

  const handleRangeChange = (start: Date, end: Date, view: View | undefined) => {
    setRange([DateTime.fromJSDate(start).toISO(), DateTime.fromJSDate(end).toISO()])
  }

  const handleCloseClick = () => {
    setSelected(undefined)
    setSelectedSlot(undefined)
  }

  const handleSelectEvent = (event: any) => {
    setSelected(event)
  }

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    setSelectedSlot(slotInfo)
  }

  return (
    <div>
      <Drawer anchor="right" open={!!selected} onClose={handleCloseClick}>
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <SimpleShowLayout
          record={selected}
          // resource="calendar_entries"
        >
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="type" />
          <OwnerRefField source="customer_id" />
          <UserRefField source="user_id" />
          <DateField source="start_date" />
          <DateField source="end_date" />
          <EntryStatusField source="current_status" label="Current Status" />
          {/* <TextField source="title" className={classes.field} />
          <TextField source="teaser" className={classes.field} /> */}

          <AddStatusButton />

          <EditButton style={{ marginTop: "2em" }} label="View Details" />
        </SimpleShowLayout>
      </Drawer>

      <Drawer anchor="right" open={!!selectedSlot} onClose={handleCloseClick}>
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <div>{selectedSlot?.start.toLocaleString()}</div>
        <div>{selectedSlot?.end.toLocaleString()}</div>
      </Drawer>

      <ResourceContextProvider value={reference || ""}>
        <EventCalendarView
          defaultDate={record.start_date}
          showDate={showDate}
          entries={enhancedEntries}
          onRangeChange={handleRangeChange}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
        />
      </ResourceContextProvider>
    </div>
  )
}

CalendarReferenceManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: { field: "start_date", order: "DESC" },
  source: "id",

  reference: "calendar_entries",
  target: "calendar_id",
  resource: "calendar_entries",
}

export { CalendarReferenceManyField }
