import Chip from "@material-ui/core/Chip"
import { get } from "lodash"
import React from "react"
import { FieldProps, useRecordContext } from "react-admin"

export const TextArrayField: React.FC<FieldProps> = ({ source, ...rest }) => {
  const record = useRecordContext(rest)
  const array: string[] = source ? get(record, source) : undefined

  if (typeof array === "undefined" || array === null || array.length === 0) {
    return <div />
  }

  return (
    <span>
      {array.map((item) => (
        <Chip label={item} key={item} />
      ))}
    </span>
  )
}
