import React from "react"
import { NumberInput, SimpleFormProps, Labeled, SimpleForm } from "react-admin"

import { QuotaRefField } from "../quotas"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const QuotaForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest} spacing={2}>
    <Labeled source="quota_id" fullWidth>
      <QuotaRefField source="quota_id" />
    </Labeled>

    <NumberInput source="year" required />

    <NumberInput source="max_nights" />
    <NumberInput source="max_stays" />
    <NumberInput source="max_inquired_segments" />
    <NumberInput source="max_active_segments" />
    <NumberInput source="max_active_segments_in_mhp" />
    <NumberInput source="max_active_bookings_including_special_date" />
  </SimpleForm>
)
