import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  Pagination,
  PaginationProps,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin"

import appConfig from "../../../config/app.config"
import { OwnerRefField, OwnerRefInput } from "../owners"

const GuestPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

const listFilters = [<OwnerRefInput alwaysOn />]

export const GuestTemplateList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    filters={listFilters}
    exporter={false}
    sort={{ field: "first_name", order: "ASC" }}
    pagination={<GuestPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="id_card_number" />
      <DateField source="birth_date" />
      <TextField source="email" />
      <TextField source="phone" />
      <OwnerRefField source="customer_id" />

      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)
