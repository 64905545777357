import { Alert } from "@mui/material"
import appConfig from "config/app.config"
import {
  Create,
  Edit,
  EditButton,
  List,
  Datagrid,
  Pagination,
  TextField,
  DateField,
  ShowButton,
  PaginationProps,
  Show,
  Tab,
  TabbedShowLayout,
  NumberField,
  ReferenceManyField,
  DeleteButton,
} from "react-admin"
import { useLocation } from "react-router-dom"

import { AddGroupMemberButton } from "../AddGroupMemberButton"
import { UserRefField } from "../users/UserRefField"
import { GroupForm } from "./GroupForm"

const GroupsPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const GroupsList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    exporter={false}
    sort={{ field: "name", order: "ASC" }}
    pagination={<GroupsPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="name" />
      <TextField source="description" />
      <UserRefField source="created_by" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const GroupShow = (props: any) => {
  const router = useLocation()

  return (
    <Show title="Group">
      <TabbedShowLayout>
        <Tab label="Group Details">
          <TextField source="name" />
          <TextField source="description" />
        </Tab>

        <Tab label="Members">
          <AddGroupMemberButton mode="group" />
          <ReferenceManyField reference="group_members" target="group_id" fullWidth label={false}>
            <Datagrid
              bulkActionButtons={false}
              empty={<Alert severity="info">This group is not a members yet.</Alert>}
            >
              <UserRefField />
              <TextField source="role" />
              <DateField source="created_at" label="Created" />
              <EditButton />
              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          <UserRefField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const GroupCreate = (props: any) => (
  <Create {...props} redirect="/groups">
    <GroupForm isExisting={false} />
  </Create>
)

export const GroupEdit = (props: any) => (
  <Edit {...props}>
    <GroupForm isExisting />
  </Edit>
)
