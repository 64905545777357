import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

interface Props {
  source?: string
}

const GroupRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField link="show" source="group_id" {...props} label="Groups" reference="groups">
    <TextField source="name" />
  </ReferenceField>
)

GroupRefField.defaultProps = {
  label: "Group",
  source: "group_id",
}

export { GroupRefField }
