import { SelectTypeInput } from "../../../inputs/SelectTypeInput"
import { SelectSalutationInput } from "../../../inputs/SelectSalutationInput"
import React from "react"
import { FormProps, TextInput, BooleanInput, TabbedForm, FormTab, required } from "react-admin"

type Props = Partial<FormProps> & {
  isExisting: boolean
}

export const OwnerForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => (
  <TabbedForm defaultValues={defaultValues}>
    <FormTab label="Owner Details">
      <TextInput source="short_id" label="Short ID" />
      <SelectTypeInput source="type" validate={[required()]} />
      <TextInput source="name" label="Company Name" helperText="If customer is a company" />
      <TextInput
        source="first_name"
        helperText="First name of person or legal representative for company"
      />
      <TextInput
        source="last_name"
        helperText="Last name of person or legal representative for company"
      />
      <SelectSalutationInput source="salutation" />
      <TextInput source="email" helperText="Will receive billing emails." />
      <TextInput source="phone_cell" />
      <TextInput source="phone_landline" />
      <TextInput source="address_field_1" label="Street + No." helperText={false} />
      <TextInput source="address_field_2" helperText={false} />
      <TextInput source="address_city" label="City" helperText={false} />
      <TextInput source="address_postal_code" label="Postal Code" helperText={false} />
      <TextInput
        source="address_country"
        label="Country"
        helperText="Full country name, eg. `Deutschland`"
      />
      <TextInput source="tax_id" label="Tax ID" helperText="Steuernummer" />
      <TextInput source="vat_id" label="VAT ID" helperText="USt. ID" />
      <TextInput source="commercial_register_number" helperText="Handelsregisternummer" />
      <TextInput source="local_court" helperText="Amtsgericht" />
      <TextInput disabled source="tax_exempt" />
      <TextInput source="internal_notes" multiline rows={3} />
    </FormTab>

    <FormTab label="Contact">
      <TextInput source="contact_first_name" label="First name" />
      <TextInput source="contact_last_name" label="Last name" />
      <SelectSalutationInput source="contact_salutation" label="Salutation" />
      <TextInput source="contact_title" label="Title" />
      <TextInput source="contact_email" label="Email" />
      <TextInput source="contact_phone" label="Phone" />
      <TextInput source="contact_phone_cell" label="Phone cell" />
      <TextInput source="contact_phone_landline" label="Phone landline" />
      <TextInput source="contact_address_field_1" label="Address field 1" />
      <TextInput source="contact_address_field_2" label="Address field 2" />
      <TextInput source="contact_address_city" label="City" />
      <TextInput source="contact_address_postal_code" label="Postal code" />
      <TextInput source="contact_address_country" label="Country" />
    </FormTab>

    <FormTab label="Advanced">
      <BooleanInput
        source="feature_flags.generate_booking_bills"
        label="Auto booking bill generation"
        helperText="Automatically generates/saves bills for bookings and shows expected costs on checkout in app."
      />
      {/*
      <BooleanInput
        source="feature_flags.full_booking_rules_validation"
        label="Full auto booking rules validation"
      />
      */}
    </FormTab>
  </TabbedForm>
)
