import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

interface Props {
  source?: string
}

const ShareRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Shares"
    source="share_id"
    reference="property_shares"
    link="show"
    {...props}
  >
    <TextField source="display_name" />
  </ReferenceField>
)

ShareRefField.defaultProps = {
  source: "share_id",
}

export { ShareRefField }
