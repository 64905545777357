import ProductIcon from "@mui/icons-material/ShoppingBasket"

import { ProductRefField } from "./ProductRefField"
import { ProductRefInput } from "./ProductRefInput"
import { ProductCreate, ProductEdit, ProductList, ProductShow } from "./products"

const resource = {
  icon: ProductIcon,
  list: ProductList,
  show: ProductShow,
  create: ProductCreate,
  edit: ProductEdit,
}

export {
  ProductIcon,
  ProductRefField,
  ProductRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
