import React from "react"
import { Layout, LayoutProps } from "react-admin"

import CustomAppBar from "./CustomAppBar"
import CustomMenu from "./CustomMenu"
import { CustomMenu3 } from "./CustomMenu3"

export const CustomLayout: React.FC<LayoutProps> = (props) => (
  <Layout
    {...props}
    // appBar={CustomAppBar}
    menu={CustomMenu3}
    // theme={theme}
  />
)
