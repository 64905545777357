import { InputProps, SelectInput } from "react-admin"

export const EntryStatusChoices = [
  { id: "requested", name: "requested" },
  { id: "tentative", name: "tentative" },
  { id: "confirmed", name: "confirmed" },
  { id: "declined", name: "declined" },
  { id: "cancelled", name: "cancelled" },
  { id: "waitlisted", name: "waitlisted" },
]

export const SelectEntryStatusInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={EntryStatusChoices} />
)
