import { EUR } from "@dinero.js/currencies"
import { Print } from "@mui/icons-material"
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material"
import { dinero, isZero, toDecimal } from "dinero.js"
import { isEmpty, isNumber, sortBy } from "lodash"
import React from "react"
import { DeleteButton, EditButton } from "react-admin"
import { useReactToPrint } from "react-to-print"
import { AddGroupButton } from "resources/billing/bills/buttons/AddGroupButton"
import { AddProductButton } from "resources/billing/product_categories/AddProductButton"

import { BillDto, BillGroupDto, LineItemDto, PricingModelEnum } from "../../types/Bill"
import { getVATSummary } from "./utils"

const transformer = ({ value, currency }: any) =>
  Intl.NumberFormat("de-DE", { style: "currency", currency: currency.code }).format(value)

const getFormattedAmount = (amountInEuroCents: number | undefined, scale?: number): string => {
  if (!amountInEuroCents) return ""

  const monetary = dinero({ amount: amountInEuroCents, currency: EUR, scale })

  if (isZero(monetary)) return "-"

  return toDecimal(monetary, transformer)
}

interface LineItemProps {
  item: LineItemDto
  showActions?: boolean
}

export const LineItem = ({ item, showActions = true }: LineItemProps) => {
  const discountFormatted = item.discount_amount
    ? getFormattedAmount(item.discount_amount, 2)
    : item.discount_percentage
    ? `${item.discount_percentage} %`
    : null
  const amountDiscountedFormatted = getFormattedAmount(
    item?.amount_discounted_amount,
    item?.amount_discounted_scale,
  )
  const subtotalAmountFormatted = getFormattedAmount(item.subtotal_amount, item.subtotal_scale)
  const vatAmountFormatted = getFormattedAmount(item.vat_amount, item.vat_scale)
  const totalAmountFormatted = getFormattedAmount(item.total_amount, item.total_scale)
  const undiscountedTotalFormatted = getFormattedAmount(
    item?.undiscounted_total_amount,
    item?.undiscounted_total_scale,
  )
  const dueAmountFormatted = getFormattedAmount(item.due_amount, item.due_scale)
  const deferredAmountFormatted = getFormattedAmount(item.deferred_amount, item.deferred_scale)
  const priceFormatted = getFormattedAmount(item.price_amount, item.price_scale)
  let description: string | undefined = undefined

  if (item.pricing_model === PricingModelEnum.per_unit) {
    description = item.unit
  } else if (item.pricing_model === PricingModelEnum.flat_fee) {
    description = `flat fee`
  }

  return (
    <TableRow hover>
      <TableCell>{item.title}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="right">{item.quantity}</TableCell>
      <TableCell align="right">{priceFormatted}</TableCell>
      <TableCell align="right">{item.unit_count}</TableCell>
      <TableCell align="right">{subtotalAmountFormatted}</TableCell>
      <TableCell align="right">
        <Tooltip
          title={`Discounted Amount: ${amountDiscountedFormatted}, Undiscounted Total: ${undiscountedTotalFormatted}`}
        >
          <span>{discountFormatted}</span>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        {isNumber(item.vat_rate) && `${item.vat_rate} %`}
        {vatAmountFormatted && ` / ${vatAmountFormatted}`}
      </TableCell>
      <TableCell align="right">{totalAmountFormatted}</TableCell>
      <TableCell align="right">{dueAmountFormatted}</TableCell>
      <TableCell align="right">{deferredAmountFormatted}</TableCell>
      <TableCell align="right">
        {showActions && (
          <Stack direction="row" spacing={2}>
            <DeleteButton size="small" resource="bill_line_items" record={item} />
            <EditButton size="small" resource="bill_line_items" record={item} />
          </Stack>
        )}
      </TableCell>
    </TableRow>
  )
}

interface GroupProps {
  group: BillGroupDto
  showActions?: boolean
}

export const Group = ({ group, showActions = true }: GroupProps) => {
  const discountFormatted = !!group.discount_percentage
    ? `-${group.discount_percentage}%`
    : getFormattedAmount(group.discount_amount)
  const amountDiscountedFormatted = getFormattedAmount(
    group?.amount_discounted_amount,
    group?.amount_discounted_scale,
  )
  const subtotalAmountFormatted = getFormattedAmount(group.subtotal_amount, group.subtotal_scale)
  const vatAmountFormatted = getFormattedAmount(group.vat_amount, group.vat_scale)
  const totalAmountFormatted = getFormattedAmount(group.total_amount, group.total_scale)
  const undiscountedTotalFormatted = getFormattedAmount(
    group?.undiscounted_total_amount,
    group?.undiscounted_total_scale,
  )
  const dueAmountFormatted = getFormattedAmount(group.due_amount, group.due_scale)
  const deferredAmountFormatted = getFormattedAmount(group.deferred_amount, group.deferred_scale)
  const title = `Group: ${group.title}`

  const lineItems =
    group?.line_items && Array.isArray(group.line_items) && group.line_items.length > 0
      ? sortBy(group.line_items, "title")
      : []

  return (
    <Card
      variant="outlined"
      style={{
        breakInside: "avoid",
      }}
    >
      <CardHeader
        title={title}
        subheader={group.is_condensed ? "CONDENSED GROUP" : ""}
        action={
          showActions && (
            <>
              <DeleteButton resource="bill_groups" label="Delete Group" record={group} />
              <EditButton resource="bill_groups" label="Edit Group" record={group} />
            </>
          )
        }
      />
      <CardContent>
        {/*
        {!!group.description && (
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {group.description}
          </Typography>
        )}
        */}

        {group.line_items?.length < 1 && (
          <Alert severity="warning">Empty group (no line items)</Alert>
        )}

        <Stack spacing={2}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell align="right">Qty.</TableCell>
                  <TableCell align="right">Per Unit</TableCell>
                  <TableCell align="right"># Units</TableCell>
                  <TableCell align="right">Subtotal</TableCell>
                  <TableCell align="right">Discount</TableCell>
                  <TableCell align="right">VAT</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">Due</TableCell>
                  <TableCell align="right">Deferred</TableCell>
                  <TableCell align="right"> </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {lineItems.map((item: LineItemDto) => (
                  <LineItem item={item} showActions={showActions} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow hover>
                  <TableCell rowSpan={6} />
                  <TableCell rowSpan={6} />
                  <TableCell rowSpan={6} />
                  <TableCell rowSpan={6} />
                  <TableCell colSpan={3}>Subtotal (Gesamt Netto)</TableCell>
                  <TableCell align="right">{subtotalAmountFormatted}</TableCell>
                </TableRow>
                {/* {!!amountDiscountedFormatted && ( */}
                <TableRow hover>
                  <TableCell colSpan={2}>Discount</TableCell>
                  <TableCell align="right">{group.discount_title}</TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={`Discounted Amount: ${amountDiscountedFormatted}, Undiscounted Total: ${undiscountedTotalFormatted}`}
                    >
                      <span>{discountFormatted}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                {/* )} */}
                <TableRow hover>
                  <TableCell colSpan={2}>VAT</TableCell>
                  <TableCell align="right">{group.vat_rate && `${group.vat_rate} %`}</TableCell>
                  <TableCell align="right">{vatAmountFormatted}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell colSpan={3}>Total (Gesamt Brutto)</TableCell>
                  <TableCell align="right">{totalAmountFormatted}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell colSpan={3}>Due Amount (Zu zahlen)</TableCell>
                  <TableCell align="right">{dueAmountFormatted}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell colSpan={3}>Deferred Amount (Gestundet)</TableCell>
                  <TableCell align="right">{deferredAmountFormatted}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CardContent>
    </Card>
  )
}

interface BillProps {
  bill?: BillDto
}

export const BookingBill: React.FC<BillProps> = ({ bill }, ref) => {
  const groups =
    bill && Array.isArray(bill?.groups) && bill?.groups.length > 0
      ? sortBy(bill.groups, "title")
      : []

  const vatAmountFormatted = getFormattedAmount(bill?.vat_amount, bill?.vat_scale)
  const vatSummary = bill ? getVATSummary(bill) : undefined
  const hasVAT = !!vatSummary
  const discountFormatted = !!bill?.discount_percentage
    ? `-${bill?.discount_percentage}%`
    : getFormattedAmount(bill?.discount_amount)
  const amountDiscountedFormatted = getFormattedAmount(
    bill?.amount_discounted_amount,
    bill?.amount_discounted_scale,
  )
  const subtotalAmountFormatted = getFormattedAmount(bill?.subtotal_amount, bill?.subtotal_scale)
  const totalAmountFormatted = getFormattedAmount(bill?.total_amount, bill?.total_scale)
  const undiscountedTotalFormatted = getFormattedAmount(
    bill?.undiscounted_total_amount,
    bill?.undiscounted_total_scale,
  )
  const dueAmountFormatted = getFormattedAmount(bill?.due_amount, bill?.due_scale)
  const deferredAmountFormatted = getFormattedAmount(bill?.deferred_amount, bill?.deferred_scale)

  const [showActions, setShowActions] = React.useState(false)

  const printRef = React.useRef<HTMLDivElement>(null)
  const promiseResolveRef = React.useRef<((value?: any) => void) | null>(null)
  const [isPrinting, setIsPrinting] = React.useState(false)

  React.useEffect(() => {
    setShowActions(isEmpty(bill?.unique_number))
  }, [bill])

  React.useEffect(() => {
    if (isPrinting && !showActions && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current()
    }
  }, [isPrinting, showActions])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Abrechnungsübersicht",
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setShowActions(false)
        setIsPrinting(true)
      })
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null
      setShowActions(isEmpty(bill?.unique_number))
      setIsPrinting(false)
    },
  })

  if (!bill) return <div>Loading...</div>

  return (
    <div ref={printRef}>
      <Card>
        <CardHeader
          title="Abrechnungsübersicht"
          subheader={
            <>
              Check-in:{" "}
              {bill?.check_in_day
                ? Intl.DateTimeFormat("de-DE").format(new Date(bill?.check_in_day))
                : "N/A"}
              , check-out:{" "}
              {bill?.check_out_day
                ? Intl.DateTimeFormat("de-DE").format(new Date(bill?.check_out_day))
                : "N/A"}
            </>
          }
          action={
            showActions && (
              <Stack direction="row" spacing={2}>
                <AddGroupButton />
                <AddProductButton />
                <Button onClick={handlePrint} variant="text" startIcon={<Print />}>
                  Print (save as PDF)
                </Button>
              </Stack>
            )
          }
        />
        <CardContent>
          <Stack spacing={2}>
            {groups.map((group, idx) => (
              <Group key={idx} group={group} showActions={showActions} />
            ))}

            {groups.length < 1 && <Alert severity="warning">Empty bill (no groups)</Alert>}

            <Card
              variant="outlined"
              style={{
                breakInside: "avoid",
              }}
            >
              <CardHeader title="Summary" />
              <CardContent>
                <TableContainer>
                  <Table size="small">
                    <TableRow hover>
                      <TableCell rowSpan={8} />
                      <TableCell colSpan={3}>Subtotal (Gesamt Netto)</TableCell>
                      <TableCell align="right">{subtotalAmountFormatted}</TableCell>
                    </TableRow>

                    <TableRow hover>
                      <TableCell colSpan={3}>VAT total (Enthaltene USt)</TableCell>
                      <TableCell align="right">{vatAmountFormatted}</TableCell>
                    </TableRow>

                    {hasVAT &&
                      vatSummary?.rates.map((vg, idx) => (
                        <TableRow hover>
                          <TableCell colSpan={2} align="right">
                            VAT
                          </TableCell>
                          <TableCell align="right">{vg.rate}%</TableCell>
                          <TableCell align="right">
                            {getFormattedAmount(vg.amount, vg.scale)}
                          </TableCell>
                        </TableRow>
                      ))}

                    <TableCell colSpan={3}>
                      {!!bill.discount_title ? `"${bill.discount_title}"` : "Discount"}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={`${amountDiscountedFormatted} ${undiscountedTotalFormatted}`}>
                        <span>{discountFormatted}</span>
                      </Tooltip>
                    </TableCell>

                    <TableRow hover>
                      <TableCell colSpan={3}>Total (Gesamt Brutto)</TableCell>
                      <TableCell align="right">{totalAmountFormatted}</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell colSpan={3}>Due amount (Zu zahlen)</TableCell>
                      <TableCell align="right">{dueAmountFormatted}</TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell colSpan={3}>Deferred amount (Gestundet)</TableCell>
                      <TableCell align="right">{deferredAmountFormatted}</TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Stack>
        </CardContent>
      </Card>
    </div>
  )
}
