import { get } from "lodash"
import PropTypes from "prop-types"
import { FieldProps, useRecordContext } from "react-admin"

const CentAsEuroField = (props: FieldProps) => {
  const { source } = props
  const record = useRecordContext(props)
  const value = source ? get(record, source) : undefined
  const inEur = !!value && value > 0 ? `${value / 100} €` : undefined

  return <span>{inEur}</span>
}

CentAsEuroField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

// export default memo<Props>(CentAsEuroField)
export default CentAsEuroField
