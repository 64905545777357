import { SelectInput, SelectInputProps } from "react-admin"

export const CollaborationStatusChoices = [
  { id: "on_demand", name: "On demand" },
  { id: "on_contract", name: "On contract" },
  { id: "discontinued", name: "Discontinued" },
]

export const SelectCollaborationStatusInput: React.FC<SelectInputProps> = (props) => (
  <SelectInput emptyValue={undefined} resettable {...props} choices={CollaborationStatusChoices} />
)
