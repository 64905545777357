import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

export const BillPaymentStatusRefField: React.FC<Partial<ReferenceFieldProps>> = (props) => (
  <ReferenceField
    {...props}
    source="bill_payment_status_id"
    reference="bill_payment_statuses"
    link="show"
    label="Payment Status"
  >
    <TextField source="status" />
  </ReferenceField>
)
