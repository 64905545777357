import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const BillRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField source="bill_id" reference="bills" link="show" label="Bill" {...props}>
    <TextField source="id" />
  </ReferenceField>
)

BillRefField.defaultProps = {
  source: "bill_id",
}

export { BillRefField }
