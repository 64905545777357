import CalendarIcon from "@mui/icons-material/CalendarToday"

import { CalendarPropertyCreate } from "./calendar_properties"

const calendarPropertiesResource = {
  icon: CalendarIcon,

  create: CalendarPropertyCreate,
}

export default calendarPropertiesResource
