import globalize from "globalize"
import React from "react"
import { Calendar, View, stringOrDate, SlotInfo, globalizeLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { LocalDate, convert } from "@js-joda/core"

import { ICalendarEntryWithNames } from "@/types/ICalendarEntry"

/**
 * @param arr any value that can be stringified
 * @returns string in format [arr[0]] / [arr[1]] / ... / [arr[n]]
 */
const intoStringArray = (arr: any[]): string => {
  const s = arr.reduce<string>((acc, val, index) => {
    if (val) {
      acc += `[${val}]`
    }
    if (index !== arr.length - 1) {
      acc += "  / "
    }
    return acc
  }, "")
  return s
}

const localizer = globalizeLocalizer(globalize)

type EventCalendarViewProps = {
  entries: ICalendarEntryWithNames[] // CalendarEntry[]
  defaultDate?: string | Date
  showDate?: LocalDate
  onRangeChange: (start: Date, end: Date, view: View | undefined) => void
  onSelectEvent: (event: any) => void
  onSelectSlot: (slotInfo: SlotInfo) => void
}

export const EventCalendarView: React.FC<EventCalendarViewProps> = ({
  entries,
  defaultDate,
  showDate,
  onRangeChange,
  onSelectEvent,
  onSelectSlot,
}) => {
  const [events, setEvents] = React.useState<any[] | undefined>()
  // const _defaultDate = React.useMemo(() => defaultDate ?? new Date(), [defaultDate])
  const [date, setDate] = React.useState(new Date())
  const onNavigate = React.useCallback((newDate) => setDate(newDate), [setDate])

  React.useEffect(() => {
    if (showDate) {
      setDate(convert(showDate).toDate())
    }
  }, [showDate])

  React.useEffect(() => {
    setEvents(
      entries
        ? entries.map((entry) => ({
            ...entry,
            allDay: true,
            // title: entry.label,
            start: new Date(entry.start_date),
            end: new Date(entry.end_date),
            resource: entry.id,
          }))
        : [],
    )
  }, [entries])

  const handleRangeChange = (
    range: Date[] | { start: stringOrDate; end: stringOrDate },
    view: View | undefined,
  ) => {
    // console.log("[EventCalendarView] handleRangeChange# range", range)

    const start = Array.isArray(range) ? range[0] : range.start
    const end = Array.isArray(range) ? range[1] : range.end

    onRangeChange(
      typeof start === "string" ? new Date(start) : start,
      typeof end === "string" ? new Date(end) : end,
      view,
    )
  }

  const defaultAccessor = (event: any) => {
    return intoStringArray([
      event.current_status,
      event?.customer_name,
      event?.user_name,
      event.title,
    ])
  }

  return (
    <Calendar
      localizer={localizer}
      date={date}
      defaultDate={defaultDate}
      defaultView="month"
      views={["month", "agenda"]}
      selectable="ignoreEvents"
      events={events}
      titleAccessor={defaultAccessor}
      tooltipAccessor={defaultAccessor}
      style={{ height: "90vh" }}
      showAllEvents
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      onNavigate={onNavigate}
      onRangeChange={handleRangeChange}
      onShowMore={(events, date) => {
        console.log("onShowMore", events, date)
      }}
    />
  )
}
