import { NumberField, RedirectionSideEffect } from "react-admin"
import {
  EditButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  Filter,
} from "react-admin"

import { UserRefField, UserRefInput } from "../../iam/users"
import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { CalendarRefField, CalendarRefInput } from "../calendars"
import { QuotaRefField } from "../quotas"
import { CalendarMemberForm } from "./CalendarMemberForm"

const CalendarMemberListFilter = (props: any) => {
  return (
    <Filter {...props}>
      <CalendarRefInput />
      <UserRefInput />
      <OwnerRefInput />
    </Filter>
  )
}

export const CalendarMemberList = () => (
  <List filters={<CalendarMemberListFilter />} exporter={false}>
    <Datagrid>
      <CalendarRefField source="calendar_id" />
      <UserRefField source="user_id" />
      <OwnerRefField source="customer_id" />
      <QuotaRefField source="default_quota_id" />
      <DateField source="created_at" showTime />

      <EditButton />
    </Datagrid>
  </List>
)

export const CalendarMemberShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Ownership Details">
        <CalendarRefField source="calendar_id" />
        <UserRefField source="user_id" />
        <OwnerRefField source="customer_id" />
        <QuotaRefField source="default_quota_id" />
        <TextField source="internal_notes" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `calendars/${data.calendar_id}/show/members` : "show"

export const CalendarMemberCreate = () => (
  <Create redirect={redirect}>
    <CalendarMemberForm isExisting={false} />
  </Create>
)

export const CalendarMemberEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <CalendarMemberForm isExisting />
  </Edit>
)
