import { CellContext, Row, createColumnHelper } from "@tanstack/react-table"
import Table from "components/Table"
import { useGetManyReference, Loading } from "react-admin"

interface DistributionReportProps {
  propertyId: string
  yearFrom: number
  yearTo: number
  propertyShortName: string
}

interface DistributionReportTable {
  year: number
  days_in_peak: number
  days_in_high: number
  days_in_medium: number
  days_in_low: number
  days_in_no_seasons: number
  all_seasons: number
}

interface DistributionReportResponse {
  days_in_high: number
  days_in_low: number
  days_in_medium: number
  days_in_no_seasons: number
  days_in_peak: number
  all_seasons: number
  id: string
  year: number
}

const getSeasonPercentage = (cell: CellContext<DistributionReportTable, number>) => {
  const total =
    cell.row
      .getAllCells()
      .find((r) => r.id === `${cell.row.index}_all_seasons`)
      ?.getValue<number>() || 0

  const percentage = cell.getValue() / total || 0

  return percentage
}

export const DistributionReport = ({
  propertyId,
  yearFrom,
  yearTo,
  propertyShortName,
}: DistributionReportProps) => {
  const { data, isLoading } = useGetManyReference<DistributionReportResponse>(
    `scheduling_reports/property_season_distribution`,
    {
      target: "property_id",
      id: propertyId,
      filter: {
        year_from: yearFrom,
        year_to: yearTo,
      },
    },
  )

  if (isLoading && !data) return <Loading />

  const columnHelper = createColumnHelper<DistributionReportTable>()

  const columns = [
    columnHelper.accessor("year", {
      cell: (cell) => <strong>{cell.getValue()}</strong>,
      header: () => `Year`,
    }),

    columnHelper.accessor("days_in_peak", {
      cell: (cell) => {
        const percentage = getSeasonPercentage(cell)

        return (
          <span>
            {cell.getValue()} <strong>({Math.round(percentage * 100)}%)</strong>
          </span>
        )
      },
      header: () => "Nights in peak",
    }),

    columnHelper.accessor("days_in_high", {
      cell: (cell) => {
        const percentage = getSeasonPercentage(cell)

        return (
          <span>
            {cell.getValue()} <strong>({Math.round(percentage * 100)}%)</strong>
          </span>
        )
      },
      header: () => "Nights in high",
    }),

    columnHelper.accessor("days_in_medium", {
      id: "days_in_medium",

      cell: (cell) => {
        const percentage = getSeasonPercentage(cell)

        return (
          <span>
            {cell.getValue()} <strong>({Math.round(percentage * 100)}%)</strong>
          </span>
        )
      },
      header: () => "Nights in medium",
    }),

    columnHelper.accessor("days_in_low", {
      cell: (cell) => {
        const percentage = getSeasonPercentage(cell)

        return (
          <span>
            {cell.getValue()} <strong>({Math.round(percentage * 100)}%)</strong>
          </span>
        )
      },
      header: () => "Nights in low",
    }),

    columnHelper.accessor("all_seasons", {
      cell: (cell) => {
        const noSeason =
          cell.row
            .getAllCells()
            .find((r) => r.id === `${cell.row.index}_days_in_no_seasons`)
            ?.getValue<number>() || 0

        const total = noSeason + cell.getValue()
        const percentage = cell.getValue() / total || 0

        return (
          <span>
            {cell.getValue()} <strong>({Math.round(percentage * 100)}%)</strong>
          </span>
        )
      },
      header: () => "All seasons",
    }),

    columnHelper.accessor("days_in_no_seasons", {
      cell: (cell) => cell.getValue(),
      header: () => "No season",
    }),
  ]

  return (
    <Table
      data={data}
      columns={columns}
      exportFileName={`${propertyShortName}_distribution_report`}
    />
  )
}
