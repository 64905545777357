import { ReferenceArrayField, SingleFieldList, ChipField } from "react-admin"

export const TagsListField = ({ label }: { label: string }) => {
  return (
    <ReferenceArrayField label reference="tags" source="tag_ids">
      <SingleFieldList>
        <ChipField source="label" />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}
