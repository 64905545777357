import BillStatusIcon from "@mui/icons-material/LocalOffer"

import { BillStatusRefField } from "./BillStatusRefField"
import { BillStatusCreate, BillStatusEdit, BillStatusList, BillStatusShow } from "./bill_statuses"

const resource = {
  icon: BillStatusIcon,
  list: BillStatusList,
  show: BillStatusShow,
  create: BillStatusCreate,
  edit: BillStatusEdit,
}

export { BillStatusIcon, BillStatusRefField }

export default resource
