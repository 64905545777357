import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddOwnershipButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      title="Add Ownership"
      color="primary"
      component={Link}
      to={{
        pathname: "/ownerships/create",
      }}
      state={{ record: { customer_id: record.id } }}
    >
      <ButtonIcon /> Add Ownership
    </Button>
  )
}
