import {
  TextField,
  NumberField,
  DateField,
  useGetRecordId,
  Show,
  TabbedShowLayout,
  Tab,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
  Create,
  Edit,
} from "react-admin"
import { QuotaForm } from "./QuotaLimitForm"
import { QuotaRefField } from "../quotas"
import { UserRefField } from "resources/iam/users"

export const QuotaLimitShow = () => {
  const recordId = useGetRecordId()
  return (
    <Show>
      <TabbedShowLayout spacing={2}>
        <Tab label="Quota Limit Details">
          <QuotaRefField source="quota_id" />
          <TextField source="year" />
          <NumberField source="max_nights" />
          <NumberField source="max_stays" />
          <NumberField source="max_inquired_segments" />
          <NumberField source="max_active_segments" />
          <NumberField source="max_active_segments_in_mhp" />
          <NumberField source="max_active_bookings_including_special_date" />
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <UserRefField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const redirect: RedirectionSideEffect = (
  _resource?: string,
  _id?: Identifier,
  data?: Partial<RaRecord>,
) => (data?.quota_id ? `booking_quotas/${data.quota_id}/show/quota_limits` : "show")

interface QuotaLimitCreateProps {
  quota_id?: string
}

export const QuotaLimitCreate = (props: QuotaLimitCreateProps) => (
  <Create {...props} redirect={redirect}>
    <QuotaForm isExisting={false} />
  </Create>
)

export const QuotaLimitEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <QuotaForm isExisting />
  </Edit>
)
