export enum BillStatusEnum {
  draft = "draft",
  approved = "approved",
  finalized = "finalized",
  closed = "closed",
  cancelled = "cancelled",
}

export enum BillPaymentStatusEnum {
  draft = "draft",
  awaiting_payment = "awaiting_payment",
  past_due = "past_due",
  paid = "paid",
  payment_failed = "payment_failed",
  voided = "voided",
  uncollectible = "uncollectible",
}

export enum PricingModelEnum {
  "flat_fee" = "flat_fee",
  "per_unit" = "per_unit",
}

export enum UnitEnum {
  "night" = "night",
  "guest" = "guest",
  "night_and_guest" = "night_and_guest",
  "pet" = "pet",
  "local_tourism_tax_total_count" = "local_tourism_tax_total_count",
  "requested_bedrooms" = "requested_bedrooms",
  "requested_laundry_packages" = "requested_laundry_packages",
}

export enum BillableAmountFromEnum {
  "vat" = "vat",
  "net" = "net",
  "gross" = "gross",
}

export enum BillDocumentTypeEnum {
  invoice = "invoice",
  payment_request = "payment_request",
}

interface BillStatusMetaDto {
  cio_send_booking_bill_to_customer_delivery_id: string
  cio_send_booking_bill_to_customer_queued_at: string
}

interface BillStatusDto {
  bill_id: string
  created_at: string
  created_by: string | null
  id: string
  internal_notes: string | null
  metadata: BillStatusMetaDto | null
}

export interface LineItemDto {
  id: string
  bill_id?: string
  group_id?: string
  product_id?: string
  product_code?: string
  title: string
  title_de: string
  description: string
  description_de: string
  quantity?: number
  pricing_model?: PricingModelEnum
  unit?: UnitEnum
  unit_count?: number
  vat_rate?: number
  billable_amount_from?: BillableAmountFromEnum
  price_amount: number
  price_scale: number
  vat_amount?: number
  vat_scale?: number
  amount_discounted_amount?: number
  amount_discounted_scale?: number
  subtotal_amount: number
  subtotal_scale: number
  total_amount: number
  total_scale: number
  undiscounted_total_amount?: number
  undiscounted_total_scale?: number
  due_amount?: number
  due_scale?: number
  deferred_amount?: number
  deferred_scale?: number
  created_by: string

  //
  discount_title?: string
  discount_amount?: number
  discount_percentage?: number
}

export interface BillGroupDto {
  id: string
  bill_id: string
  property_id: string

  category_id: string
  category_code: string
  title: string
  description: string

  is_condensed: boolean
  display_nights_as_units: boolean
  hide_discount: boolean
  vat_rate?: number
  discount_amount: number
  discount_percentage: number
  discount_title: string
  billable_amount_from?: BillableAmountFromEnum

  vat_amount?: number
  vat_scale?: number
  amount_discounted_amount?: number
  amount_discounted_scale?: number
  subtotal_amount: number
  subtotal_scale: number
  total_amount: number
  total_scale: number
  undiscounted_total_amount?: number
  undiscounted_total_scale?: number
  due_amount: number
  due_scale: number
  deferred_amount: number
  deferred_scale: number

  line_items: LineItemDto[]
  created_by: string
}

export interface BillDto {
  id: string

  booking_id: string
  customer_id: string
  property_id: string

  current_status?: string

  internal_notes?: string

  timezone?: string
  check_in_day?: string
  check_out_day?: string

  discount_amount?: number
  discount_percentage?: number
  discount_title?: string

  currency?: string
  currency_code?: string

  vat_rate?: number

  current_payment_status?: string
  collection_method?: string
  payed_amount?: number
  payed_amount_updated_at?: Date
  payed_amount_updated_by?: string

  document_type?: BillDocumentTypeEnum
  unique_number?: string

  language_code?: string
  billing_date?: string
  supply_date?: string
  delivery_date?: string
  period_start?: string
  period_end?: string
  due_date?: string
  due_days?: number
  title_text?: string
  intro_text?: string
  footer_text?: string
  closing_text?: string
  description_text?: string
  property_label?: string

  issuer_name?: string
  issuer_address1?: string
  issuer_address2?: string
  issuer_address_city?: string
  issuer_address_postal_code?: string
  issuer_address_country?: string
  issuer_email?: string
  issuer_phone?: string
  issuer_tax_exempt?: string
  issuer_tax_id?: string
  issuer_vat_id?: string
  issuer_commercial_register_number?: string
  issuer_local_court?: string

  bank_name?: string
  bank_account_owner?: string
  bank_iban?: string
  bank_bic?: string

  recipient_name?: string
  recipient_address1?: string
  recipient_address2?: string
  recipient_address_city?: string
  recipient_address_postal_code?: string
  recipient_address_country?: string
  recipient_email?: string
  recipient_phone?: string
  recipient_tax_exempt?: string
  recipient_tax_id?: string
  recipient_vat_id?: string
  recipient_commercial_register_number?: string
  recipient_local_court?: string

  vat_amount?: number
  vat_scale?: number
  // // DEPRECATED:
  // vat?: {
  //   rate?: number
  //   amount?: number
  //   scale?: number
  // }[]
  amount_discounted_amount?: number
  amount_discounted_scale?: number
  subtotal_amount: number
  subtotal_scale: number
  total_amount: number
  total_scale: number
  undiscounted_total_amount?: number
  undiscounted_total_scale?: number
  due_amount: number
  due_scale: number
  deferred_amount: number
  deferred_scale: number

  created_by: string

  groups: BillGroupDto[]

  statuses?: BillStatusDto[]
}
