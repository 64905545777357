import {
  Datagrid,
  List,
  Create,
  Edit,
  TextField,
  ShowButton,
  EditButton,
  Show,
  DateField,
  NumberField,
  RedirectionSideEffect,
  DeleteWithConfirmButton,
  TabbedShowLayout,
  Tab,
} from "react-admin"

import { UserRefField } from "../../iam/users"
import { OwnerRefField } from "../owners"
import { MemberForm } from "./MemberForm"

export const MemberList = () => (
  <List>
    <Datagrid rowClick="show">
      <OwnerRefField source="customer_id" />
      <UserRefField source="user_id" />
      <TextField source="role" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export const MemberShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Membership Details">
        <OwnerRefField source="customer_id" />
        <UserRefField source="user_id" />
        <TextField source="role" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `customers/${data.customer_id}/show/memberships` : "show"

export const MemberCreate = () => (
  <Create redirect={redirect}>
    <MemberForm isExisting={false} />
  </Create>
)

export const MemberEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <MemberForm isExisting />
  </Edit>
)
