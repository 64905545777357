import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const ProductRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField source="product_id" reference="products" link="show" label="Product" {...props}>
    <TextField source="title" />
  </ReferenceField>
)

ProductRefField.defaultProps = {
  source: "product_id",
}

export { ProductRefField }
