import { InputProps, SelectInput } from "react-admin"

export const choices = [
  { id: "vat", name: "VAT" },
  // { id: "net", name: "Net" },
  // { id: "gross", name: "Gross" },
]

export const BillableAmountFromSelectInput = (props: InputProps) => (
  <SelectInput label="Due Amount" {...props} choices={choices} />
)
