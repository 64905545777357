import { AutocompleteInputProps, ReferenceInputProps, useGetList } from "react-admin"

import appConfig from "../../../config/app.config"
import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"
import { DisabledSelectInput } from "../../../inputs/DisabledSelectInput"

interface Props extends Omit<ReferenceInputProps, "source"> {
  source?: string
  customer_id: string
}

interface ICustomerMembershipResponse {
  id: string
  user_id: string
  customer_id: string
}

const ConditionalUserRefInput: React.FC<
  Props & Omit<Omit<AutocompleteInputProps, "source">, "reference" | "children">
> = (props) => {
  const { data } = useGetList<ICustomerMembershipResponse>("customer_memberships", {
    pagination: {
      page: 1,
      perPage: appConfig.defaultPerPage,
    },
    filter: {
      customer_id: props.customer_id,
    },
  })

  if (!data?.length) {
    return <DisabledSelectInput source="user" label="User" fullWidth />
  }
  const userIds = data?.map((element) => element.user_id)

  return (
    <AutocompleteInputLocal
      source="user_id"
      {...props}
      filter={{ id: userIds }}
      optionText="full_name"
      resource="users"
      label="User"
    />
  )
}

ConditionalUserRefInput.defaultProps = {
  source: "user_id",
}

export { ConditionalUserRefInput }
