import OwnerIcon from "@mui/icons-material/SupervisedUserCircle"

import { GuestTemplateEdit } from "./GuestTemplateEdit"
import { GuestTemplateList } from "./GuestTemplateList"
import { GuestTemplateView } from "./GuestTemplateView"

const resource = {
  icon: OwnerIcon,
  list: GuestTemplateList,
  show: GuestTemplateView,
  edit: GuestTemplateEdit,
}

export { OwnerIcon }

export default resource
