import { LocalDate, TemporalAdjusters } from "@js-joda/core"
import { Card, CardContent, Stack, Typography } from "@mui/material"
import React from "react"
import { DateField, FunctionField, Labeled, useRecordContext } from "react-admin"
import { calculateNightsBetween } from "resources/scheduling/utils/calculate-nights-between"

import { ICalendarEntry } from "@/types/ICalendarEntry"

export const BookingInfoCard = () => {
  const record = useRecordContext<ICalendarEntry>()
  const [nightsStartYearCount, setNightsStartYearCount] = React.useState<number | undefined>()
  const [nightsEndYearCount, setNightsEndYearCount] = React.useState<number | undefined>()

  React.useEffect(() => {
    if (record && record.start_date && record.end_date) {
      const start = LocalDate.parse(record.start_date)
      const end = LocalDate.parse(record.end_date)

      if (start?.year() !== end?.year()) {
        const startLastDayOfYear = LocalDate.from(start).with(TemporalAdjusters.lastDayOfYear())
        const endFirstDayOfYear = LocalDate.from(end).with(TemporalAdjusters.firstDayOfYear())

        setNightsStartYearCount(calculateNightsBetween(start, startLastDayOfYear.plusDays(1)))
        setNightsEndYearCount(calculateNightsBetween(endFirstDayOfYear, end))
      }
    }
  }, [record])

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Booking Info
        </Typography>

        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start">
          <Labeled label="Check-In">
            <DateField source="start_date" />
          </Labeled>
          <Labeled label="Check-Out">
            <DateField source="end_date" />
          </Labeled>
          <Labeled label="Total Nights">
            <FunctionField
              render={(record: ICalendarEntry) =>
                calculateNightsBetween(record.start_date, record.end_date)
              }
            />
          </Labeled>
          <Labeled label="Total Nights in Start Year">
            <FunctionField render={(record: ICalendarEntry) => nightsStartYearCount} />
          </Labeled>
          <Labeled label="Total Nights in End Year">
            <FunctionField render={(record: ICalendarEntry) => nightsEndYearCount} />
          </Labeled>
        </Stack>
      </CardContent>
    </Card>
  )
}
