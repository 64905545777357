import { useEffect, useState } from "react"
import { AutocompleteInputProps, useGetList, AutocompleteInput } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const QuotaRefInput = (props: Props) => {
  return (
    <AutocompleteInputLocal
      perPage={350}
      source="quota_id"
      {...props}
      optionText="title"
      resource="booking_quotas"
    />
  )
}

export { QuotaRefInput }
