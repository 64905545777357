import CalendarMemberIcon from "@mui/icons-material/Settings"

import { CalendarMemberRefField } from "./CalendarMemberRefField"
import { CalendarMemberRefInput } from "./CalendarMemberRefInput"
import {
  CalendarMemberCreate,
  CalendarMemberEdit,
  CalendarMemberList,
  CalendarMemberShow,
} from "./calendar_members"

const resource = {
  icon: CalendarMemberIcon,
  // list: CalendarMemberList,
  show: CalendarMemberShow,
  create: CalendarMemberCreate,
  edit: CalendarMemberEdit,
}

export {
  CalendarMemberIcon,
  CalendarMemberRefField,
  CalendarMemberRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
