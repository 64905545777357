import { InputProps, SelectInput } from "react-admin"

export const MembershipRoleChoices = [
  { id: "owner", name: "owner" },
  { id: "relative", name: "relative" },
  { id: "guest", name: "guest" },
]

export const SelectMembershipRoleInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={MembershipRoleChoices} />
)
