import { Stack, Typography } from "@mui/material"
import {
  BooleanInput,
  DateInput,
  NumberInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  FormDataConsumer,
  TextField,
  required,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  email,
} from "react-admin"
import { ConditionalUserRefInput } from "resources/iam/users/ConditionalUserRefInput"
import { ConditionalOwnerRefInput } from "resources/real_estate/owners/ConditionalOwnerRefInput"

import { DisabledSelectInput } from "../../../inputs/DisabledSelectInput"
import { CalendarRefField } from "../calendars"
import { CalendarRefInput } from "../calendars/CalendarRefInput"
import { ConditionalQuotaRefInput } from "../quotas/ConditionalQuotaRefInput"
import { BookingTypeSelectInput } from "./inputs/BookingTypeSelectInput"
import { EntrySeasonSelectInput } from "./inputs/EntrySeasonSelectInput"
import { SelectEntryTransparencyInput } from "./inputs/SelectEntryTransparencyInput"
import { SelectEntryTypeInput } from "./inputs/SelectEntryTypeInput"
import { SelectStatusInput } from "./inputs/SelectStatusInput"
import { OwnerRefInput } from "resources/real_estate/owners"
import { QuotaRefInput } from "../quotas"
import { PropertyRefField, PropertyRefInput } from "resources/real_estate/properties"

type Props = Omit<Omit<SimpleFormProps, "children">, "render"> & {
  isExisting: boolean
}

type CalendarEntryFormProps = {
  formData: {
    customer_id?: string
    calendar_id?: string
    booking_type?: string
    property_id?: string
  }
  props?: any
}

const typesWithTimeOption = ["other", "viewing", "maintenance"]
const typesWithTransparencyOption = ["other", "viewing", "maintenance"]

export const CalendarEntryForm = ({ isExisting = false, ...props }: Props) => {
  return (
    <SimpleForm {...props}>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            {isExisting && (
              <Stack direction="row" spacing={2} alignItems="baseline">
                <Labeled source="type" fullWidth>
                  <TextField fullWidth source="type" />
                </Labeled>

                {formData.type === "booking" ? (
                  <Labeled source="property_id" fullWidth>
                    <PropertyRefField fullWidth source="property_id" />
                  </Labeled>
                ) : (
                  <Labeled source="calendar_id" fullWidth>
                    <CalendarRefField fullWidth source="calendar_id" />
                  </Labeled>
                )}
              </Stack>
            )}

            <Typography variant="h6" gutterBottom>
              General
            </Typography>
            <Stack direction="row" spacing={2} alignItems="baseline">
              {!isExisting && <SelectEntryTypeInput source="type" isRequired />}

              {formData.type === "booking" && (
                <>
                  <BookingTypeSelectInput
                    // omit={["external_rental"]}
                    source="booking_type"
                    validate={[required()]}
                  />
                  <SelectStatusInput source="current_status" validate={[required()]} />
                </>
              )}

              {formData.type === "season" && (
                <>
                  <EntrySeasonSelectInput source="season_type" />
                </>
              )}
              {["special_date", "main_holiday_period"].includes(formData.type) && (
                <TextInput
                  source="entry_code"
                  helperText="Individual code for some periods like Christmas, Easter etc."
                />
              )}
            </Stack>

            <TextInput fullWidth source="title" isRequired />

            {!isExisting && (
              <>
                <Typography variant="h6" gutterBottom>
                  Relations
                </Typography>
              </>
            )}
            {["booking"].includes(formData.type) && (
              <>
                {!isExisting && (
                  <PropertyRefInput fullWidth source="property_id" validate={[required()]} />
                )}

                <ShowOwnerSelect formData={formData} {...props} />
                {formData.customer_id ? (
                  <ConditionalUserRefInput
                    fullWidth
                    source="user_id"
                    customer_id={formData.customer_id}
                  />
                ) : (
                  <DisabledSelectInput source="users" label="User" fullWidth />
                )}
              </>
            )}

            {formData.type !== "booking" && !isExisting && (
              <CalendarRefInput fullWidth source="calendar_id" validate={[required()]} {...rest} />
            )}

            <Typography variant="h6" gutterBottom>
              Date & Time
            </Typography>

            {typesWithTimeOption.includes(formData.type) && (
              <BooleanInput fullWidth source="is_all_day" />
            )}

            {!(isExisting && formData.type === "booking") && (
              <Stack direction="row" spacing={2} alignItems="baseline">
                <DateInput
                  fullWidth
                  source="start_date"
                  helperText="Check-in date for bookings (local time)"
                  validate={[required()]}
                />
                <DateInput
                  fullWidth
                  source="end_date"
                  helperText="Check-out date for bookings (local time)"
                  validate={[required()]}
                />
              </Stack>
            )}

            <Stack direction="row" spacing={2} alignItems="baseline">
              {!formData.is_all_day && typesWithTimeOption.includes(formData.type) && (
                <>
                  <TextInput
                    fullWidth
                    source="start_time"
                    helperText="Format: 'HH:MM' (local time)"
                    validate={[required()]}
                  />
                  <TextInput
                    fullWidth
                    source="end_time"
                    helperText="Format: 'HH:MM' (local time)"
                    validate={[required()]}
                  />
                </>
              )}
            </Stack>

            {typesWithTransparencyOption.includes(formData.type) && (
              <Stack direction="row" spacing={2} alignItems="baseline">
                <SelectEntryTransparencyInput source="transparency" defaultValue="opaque" />
              </Stack>
            )}

            {formData.type === "booking" && (
              <>
                <Typography variant="h6" gutterBottom>
                  Booking Details
                </Typography>

                <ShowQuotaSelect formData={formData} />

                <Stack direction="row" spacing={2} alignItems="baseline">
                  <NumberInput fullWidth source="adults_count" helperText="Ages 13 or above" />
                  <NumberInput fullWidth source="children_count" helperText="Ages 4-12" />
                  <NumberInput fullWidth source="infants_count" helperText="Under age of 4" />

                  <NumberInput
                    fullWidth
                    source="requested_laundry_packages_count"
                    label="Requested Laundry Packages"
                  />
                  <NumberInput
                    fullWidth
                    source="requested_bedrooms_count"
                    label="Requested Bedrooms"
                  />

                  {formData.booking_type !== "external_rental" && (
                    <BooleanInput fullWidth source="has_pets" />
                  )}
                </Stack>
                <NumberInput
                  source="local_tourism_tax_total_count"
                  helperText="Number of guests x number of nights"
                />
                <TextInput
                  multiline
                  rows={3}
                  fullWidth
                  source="note"
                  label="Notes from Customer"
                  helperText="Special wishes, comments etc."
                />

                {formData.booking_type !== "external_rental" && (
                  <BooleanInput fullWidth source="user_is_not_joining" />
                )}
                {formData.user_is_not_joining === true && (
                  <>
                    <Typography variant="h6">Contact Details</Typography>
                    <Typography variant="caption" gutterBottom>
                      If User is not joining the stay
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="baseline">
                      <TextInput fullWidth source="contact_name" validate={[required()]} />
                      <TextInput fullWidth source="contact_phone" validate={[required()]} />
                      <TextInput
                        fullWidth
                        source="contact_email"
                        validate={[required(), email()]}
                      />
                    </Stack>
                  </>
                )}
              </>
            )}

            <Typography variant="h6" gutterBottom>
              Notes
            </Typography>
            <TextInput source="internal_notes" fullWidth multiline rows={3} />
            <TextInput source="housekeeping_notes" fullWidth multiline rows={3} />

            <ReferenceArrayInput reference="tags" source="tag_ids">
              <AutocompleteArrayInput label="Tags" optionText="label" />
            </ReferenceArrayInput>
            {formData.type === "booking" && formData.booking_type === "external_rental" && (
              <TextInput
                source="provider_resource_id"
                label="provider_resource_id"
                helperText="Guesty Reservation ID. Required for auto-sync process"
              />
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  )
}

const ShowOwnerSelect = ({ formData, props }: CalendarEntryFormProps) => {
  if (!formData.property_id || !formData.booking_type) {
    return <DisabledSelectInput source="owners" label="Owner" fullWidth />
  }

  if (formData.booking_type === "owner") {
    return (
      <ConditionalOwnerRefInput
        fullWidth
        validate={[required()]}
        source="customer_id"
        property_id={formData.property_id}
        {...props}
      />
    )
  }

  return (
    <OwnerRefInput
      source="customer_id"
      validate={formData.booking_type === "owner_other_property" ? [required()] : undefined}
      fullWidth
      label="Owner"
    />
  )
}

const ShowQuotaSelect = ({ formData }: CalendarEntryFormProps) => {
  if (!formData.booking_type) {
    return null
  }

  if (["other", "external_rental"].includes(formData.booking_type)) {
    return null
  }

  if (!formData.property_id) {
    return <DisabledSelectInput source="quota_id" label="Quota" fullWidth />
  }

  if (["owner", "owner_other_property"].includes(formData.booking_type)) {
    return formData.customer_id ? (
      <ConditionalQuotaRefInput
        fullWidth
        source="quota_id"
        customer_id={formData.customer_id}
        property_id={formData.booking_type === "owner" ? formData.property_id : undefined}
      />
    ) : (
      <DisabledSelectInput source="quota_id" label="Quota" fullWidth />
    )
  }

  return <QuotaRefInput source="quota_id" fullWidth />
}
