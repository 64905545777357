import React from "react"
import { Labeled, SelectInput, SimpleForm, SimpleFormProps, TextInput } from "react-admin"

import { GroupRefField } from "../groups/GroupRefField"
import { GroupRefInput } from "../groups/GroupRefInput"
import { UserRefField, UserRefInput } from "../users"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const GroupMemberForm: React.FC<Props> = (props) => {
  return (
    <SimpleForm {...props}>
      {props.isExisting ? (
        <Labeled label="User">
          <UserRefField />
        </Labeled>
      ) : (
        <UserRefInput />
      )}
      {props.isExisting ? (
        <Labeled label="Group">
          <GroupRefField />
        </Labeled>
      ) : (
        <GroupRefInput />
      )}

      <SelectInput
        label="Role"
        source="role"
        choices={[
          { id: "owner", name: "Owner" },
          { id: "manager", name: "Manager" },
        ]}
      />
    </SimpleForm>
  )
}
