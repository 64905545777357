import RecipientProfileIcon from "@mui/icons-material/FolderOpen"

import { RecipientProfileRefField } from "./RecipientProfileRefField"
import { RecipientProfileRefInput } from "./RecipientProfileRefInput"
import {
  RecipientProfileCreate,
  RecipientProfileEdit,
  RecipientProfileList,
  RecipientProfileShow,
} from "./billing_recipient_profiles"

const resource = {
  icon: RecipientProfileIcon,
  list: RecipientProfileList,
  show: RecipientProfileShow,
  create: RecipientProfileCreate,
  edit: RecipientProfileEdit,
}

export { RecipientProfileIcon, RecipientProfileRefField, RecipientProfileRefInput }

export default resource
