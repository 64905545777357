import React from "react"
import { NumberInput, SimpleForm, SimpleFormProps, TextInput } from "react-admin"

import { PropertyRefInput } from "../properties"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const ShareForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? <TextInput source="id" disabled /> : null}

    <PropertyRefInput source="property_id" disabled={isExisting} />

    {/* <OwnerRefInput source="current_customer_id" addLabel /> */}

    <NumberInput source="share_no" />
    <TextInput source="internal_notes" />
  </SimpleForm>
)
