import QuotaIcon from "@mui/icons-material/Tune"

import { QuotaRefField } from "./QuotaRefField"
import { QuotaRefInput } from "./QuotaRefInput"
import { QuotaCreate, QuotaEdit, QuotaList, QuotaShow } from "./quotas"

const resource = {
  icon: QuotaIcon,
  list: QuotaList,
  show: QuotaShow,
  create: QuotaCreate,
  edit: QuotaEdit,
}

export {
  QuotaIcon,
  QuotaRefField,
  QuotaRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
