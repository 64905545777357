import { SelectInput, SelectInputProps } from "react-admin"

import { BookingTypeChoices } from "../../constants"

interface BookingTypeInput extends SelectInputProps {
  omit?: string[]
}

export const BookingTypeSelectInput: React.FC<BookingTypeInput> = (props) => {
  const choices = props.omit?.length
    ? BookingTypeChoices.filter((c) => !props.omit?.includes(c.id))
    : BookingTypeChoices

  return <SelectInput {...props} choices={choices} />
}
