import HelpIcon from "@mui/icons-material/Help"
import { DateTime } from "luxon"
import PropTypes from "prop-types"
import { useState } from "react"
import { useNotify, Button, Confirm, useRecordContext, useCreate, useRefresh } from "react-admin"

const RescheduleButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [open, setOpen] = useState(false)

  const [startDate, setStartDate] = useState(record?.start_date ?? "")
  const [endDate, setEndDate] = useState(record?.end_date ?? "")

  const [create, { isLoading }] = useCreate<any, Error>(undefined, undefined, {
    onError: (error: { message: string }) => {
      notify(`Reschedule error: ${error.message}`, { type: "error" })
    },
    onSettled: () => {
      refresh()
      notify(`Booking has been rescheduled: [${startDate} - ${endDate}] `, { type: "success" })
    },
  })

  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    create(`reservations/${record.id}/actions/reschedule`, {
      data: {
        start_date: startDate,
        end_date: endDate,
      },
    })

    setOpen(false)
  }

  return record && record.type === "booking" ? (
    <>
      <Button
        label="Reschedule booking"
        startIcon={<HelpIcon color="primary" style={{ paddingRight: "0.5em", color: "blue" }} />}
        onClick={handleClick}
        variant="outlined"
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        confirm="Reschedule"
        title="Are you sure to reschedule this booking?"
        content={
          <div>
            <div>Please set a new date range</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                paddingTop: "20px",
              }}
            >
              <input
                style={{ padding: "8px" }}
                name="start_date"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
              <input
                style={{ padding: "8px" }}
                name="end_date"
                type="date"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
          </div>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <span />
  )
}

RescheduleButton.propTypes = {
  record: PropTypes.any,
}

export default RescheduleButton
