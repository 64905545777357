import { LockReset } from "@mui/icons-material"
import { Alert } from "@mui/material"
import appConfig from "config/app.config"
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  TextField,
  EmailField,
  DateField,
  NumberField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  Pagination,
  PaginationProps,
  ReferenceManyField,
  ShowButton,
  EditButton,
  TopToolbar,
  Button,
  DeleteButton,
} from "react-admin"
import { useLocation } from "react-router-dom"
import { OwnerRefField } from "resources/real_estate/owners"
import { CalendarRefField } from "resources/scheduling/calendars"

import { TextArrayField } from "../../../fields"
import { AddGroupMemberButton } from "../AddGroupMemberButton"
import { GroupRefField } from "../groups/GroupRefField"
import { UserEditAside } from "./UserEditAside"
import { UserForm } from "./UserForm"
import { UserRefInput } from "./UserRefInput"
import { UserTitle } from "./UserTitle"
import { AddPropertyToUserButton } from "./AddPropertyToUserButton"
import { UserRefField } from "./UserRefField"
import { PropertyRefField } from "resources/real_estate/properties"

const UserFilter = (props: any) => {
  return (
    <Filter {...props}>
      <UserRefInput alwaysOn source="id" />
    </Filter>
  )
}

const UsersPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const UserList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    filters={<UserFilter />}
    exporter={false}
    sort={{ field: "last_name", order: "ASC" }}
    pagination={<UsersPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="slack_handle" />
      <TextArrayField source="roles" />
      <BooleanField source="is_disabled" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

const UserShowActions = () => (
  <TopToolbar>
    <Button color="primary" label="Reset Password" startIcon={<LockReset />} onClick={() => {}} />
    <EditButton />
  </TopToolbar>
)

export const UserShow = () => {
  const router = useLocation()
  return (
    <Show title={<UserTitle />} actions={<UserShowActions />}>
      <TabbedShowLayout>
        <Tab label="User Details">
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="salutation" />
          <EmailField source="email" />
          <TextField source="phone" />
          <TextArrayField source="roles" />
          <TextField source="slack_handle" />
          <TextField source="language" />
          <TextField source="timezone" />
          <BooleanField source="is_disabled" />
        </Tab>

        <Tab label="Groups">
          <AddGroupMemberButton mode="user" />
          <ReferenceManyField reference="group_members" target="user_id" fullWidth label={false}>
            <Datagrid
              bulkActionButtons={false}
              empty={<Alert severity="info">This user is not a member of any group.</Alert>}
            >
              <GroupRefField />
              <TextField source="role" />
              <DateField source="created_at" label="Created" />
              <EditButton />
              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Owner Memberships">
          <ReferenceManyField
            reference="customer_memberships"
            target="user_id"
            fullWidth
            label={false}
          >
            <Datagrid
              bulkActionButtons={false}
              empty={<Alert severity="info">This user is not a member of any owner.</Alert>}
            >
              <OwnerRefField />
              <TextField source="role" />
              <BooleanField source="is_disabled" />
              <DateField source="created_at" label="Created" />
              <ShowButton />
              <EditButton />
              <DeleteButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Calendar Accesses">
          <ReferenceManyField reference="calendar_members" target="user_id" fullWidth label={false}>
            <Datagrid
              bulkActionButtons={false}
              empty={
                <Alert severity="info">This user is does not have access to any calendar.</Alert>
              }
            >
              <CalendarRefField />
              <OwnerRefField />
              <DateField source="created_at" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Assignees" path="assignees">
          <AddPropertyToUserButton />
          <ReferenceManyField reference="property_assignees" target="property_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <UserRefField source="user_id" />
              <PropertyRefField source="property_id" />
              <TextField source="role" />
              <UserRefField source="created_by" />
              <DateField source="created_at" />

              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <TextField source="account_id" label="Account ID" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <TextField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const UserCreate = () => {
  const defaultValues = {
    language: "de",
    timezone: "Europe/Berlin",
    create_account: true,
  }

  return (
    <Create>
      <UserForm isExisting={false} defaultValues={defaultValues} />
    </Create>
  )
}

export const UserEdit = () => (
  <Edit aside={<UserEditAside />} mutationMode="pessimistic">
    <UserForm isExisting />
  </Edit>
)
