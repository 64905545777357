import React from "react"
import {
  DateInput,
  NumberInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  SimpleForm,
  required,
  minValue,
  maxValue,
  number,
  NumberField,
} from "react-admin"

import { QuotaRefField } from "../quotas"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const QuotaShareForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest} spacing={2}>
    {isExisting ? (
      <Labeled source="quota_id" fullWidth>
        <QuotaRefField source="quota_id" />
      </Labeled>
    ) : (
      <TextInput source="quota_id" label="Quota id" InputProps={{ disabled: true }} />
    )}
    {isExisting ? (
      <Labeled source="share_number" fullWidth>
        <NumberField source="share_number" />
      </Labeled>
    ) : (
      <NumberInput
        source="share_number"
        validate={[required(), number(), minValue(1), maxValue(8)]}
      />
    )}

    <DateInput source="valid_from" />
    <DateInput source="valid_to" />

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
