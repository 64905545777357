import { Create, RedirectionSideEffect } from "react-admin"

import { GuestForm } from "./GuestForm"

//http://localhost:3000/#/calendar_entries/35eaf7c4-7b1b-439e-bf6a-1138596284ce/show/guests
const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `calendar_entries/${data.calendar_entry_id}/show/guests` : "show"

export const GuestCreate = () => (
  <Create redirect={redirect}>
    <GuestForm isExisting={false} />
  </Create>
)
