import { Typography } from "@mui/material"
import React from "react"
import {
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  SimpleFormProps,
  required,
  BooleanInput,
  FormDataConsumer,
} from "react-admin"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const ProviderAccountForm: React.FC<Props> = ({ isExisting = false, ...rest }) => {
  return (
    <SimpleForm {...rest} spacing={2}>
      {isExisting ? (
        <Labeled label="ID">
          <TextField source="id" />
        </Labeled>
      ) : null}

      <Typography variant="h6" component="h2">
        Provider details:
      </Typography>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {!formData.provider_account_id && (
              <>
                <TextInput
                  source="provider_name"
                  helperText="Name of the provider (e.g Guesty)"
                  validate={required()}
                />

                <TextInput
                  source="account_id"
                  helperText="ID of the provider account"
                  label="Account ID"
                  validate={[required()]}
                />
                <TextInput
                  source="account_label"
                  helperText="Label for the particular provider account"
                  validate={[required()]}
                />
                <TextInput source="oauth_id" label="Oauth ID" />
                <Typography variant="h6" component="h2">
                  Secret keys:
                </Typography>

                {isExisting && <BooleanInput source="set_new_credentials" />}

                {(!isExisting || formData.set_new_credentials) && (
                  <TextInput
                    validate={required()}
                    source="client_id"
                    label="Client ID"
                    helperText="Client ID, key for the integration"
                  />
                )}

                {(!isExisting || formData.set_new_credentials) && (
                  <TextInput
                    validate={!isExisting ? required() : undefined}
                    source="client_secret"
                    label="Client secret"
                    helperText="Client secret, key for the integration"
                  />
                )}
              </>
            )}
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  )
}
