import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  Pagination,
  PaginationProps,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin"

import appConfig from "../../../config/app.config"

const AccountListFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Provider name" source="provider_name" alwaysOn />
      <TextInput label="Account label" source="account_label" alwaysOn />
      <TextInput label="Oauth ID" source="oauth_id" alwaysOn />
    </Filter>
  )
}

const AccountListPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const ProviderAccountsList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    filters={<AccountListFilters />}
    exporter={false}
    pagination={<AccountListPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="provider_name" />
      <TextField source="account_label" />
      <TextField source="oauth_id" />
      <DateField source="created_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)
