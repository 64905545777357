import QuotaIcon from "@mui/icons-material/Tune"

import { QuotaShareCreate, QuotaShareEdit, QuotaShareShow } from "./quota_shares"

const resource = {
  icon: QuotaIcon,
  list: QuotaShareEdit,
  show: QuotaShareShow,
  create: QuotaShareCreate,
  edit: QuotaShareEdit,
}

export { QuotaIcon }

export default resource
