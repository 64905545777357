import SyncIcon from "@mui/icons-material/Sync"
import { DateTime } from "luxon"
import React, { useCallback, useState } from "react"
import {
  BooleanField,
  Button,
  Confirm,
  Datagrid,
  DateField,
  EditButton,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useCreate,
  useNotify,
  useShowContext,
} from "react-admin"

import { PropertyRefField } from "../properties"

const EntryShowActions = () => {
  const { record } = useShowContext()
  const notify = useNotify()

  if (record) {
    record.start_date = "2021-01-01"
    record.end_date = new Date().toISOString()
  }
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState("2021-01-01")
  const [endDate, setEndDate] = useState(DateTime.now().toFormat("yyyy-MM-dd"))

  const [create, { isLoading, error }] = useCreate<any, Error>(undefined, undefined, {
    onError: (error) => {
      notify(`Sync error: ${error.message}`, { type: "error" })
    },
    onSettled: (data, error) => {
      notify(`Sync started [${startDate} - ${endDate}] `, { type: "success" })
    },
  })

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    create("rental/create_sync_jobs", {
      data: {
        start_date: startDate,
        end_date: endDate,
        listed_property_id: record.id,
      },
    })
    setOpen(false)
  }

  return (
    <>
      <Confirm
        isOpen={open}
        loading={isLoading}
        confirm="Start Sync"
        title="Are you sure to start manual sync with Guesty"
        content={
          <div>
            <div>Please set a date range</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                paddingTop: "20px",
              }}
            >
              <input
                style={{ padding: "8px" }}
                name="start_date"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
              <input
                style={{ padding: "8px" }}
                name="end_date"
                type="date"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
          </div>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <TopToolbar>
        <Button
          color="primary"
          label="Sync Guesty"
          startIcon={<SyncIcon />}
          onClick={handleClick}
        />

        <EditButton />
      </TopToolbar>
    </>
  )
}

export const ListedPropertiesShow = () => (
  <Show actions={<EntryShowActions />}>
    <TabbedShowLayout spacing={2}>
      <Tab label="Rental Configuration details">
        <PropertyRefField source="property_id" />
        <TextField source="provider_account_id" label="Provider Account ID" />
        <TextField source="provider_listing_id" />
        <BooleanField source="is_sync_enabled" />

        <ReferenceManyField
          label="Provider accounts"
          source="provider_account_id"
          target="id"
          reference="rental/provider_accounts"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="provider_name" />
            <TextField source="account_id" />
            <TextField source="account_label" />
            <TextField source="oauth_id" />
            <TextField source="client_id" label="Client ID" />
            <TextField source="client_secret_ref" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
