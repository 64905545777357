import { InputProps, SelectInput } from "react-admin"

export const selectCompanyTypeInputChoices = [
  { id: "opco", name: "OpCo" },
  { id: "spv", name: "SPV" },
]

export const SelectCompanyTypeInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={selectCompanyTypeInputChoices} fullWidth />
)
