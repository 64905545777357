import {
  Create,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
  required,
  SimpleForm,
} from "react-admin"

import { PropertyRefInput } from "resources/real_estate/properties"
import { UserRefInput } from "resources/iam/users"
import { SelectPropertyAssigneeRoleInput } from "./SelectPropertyAssigneeRoleInput"

const redirect: RedirectionSideEffect = (
  _resource?: string,
  _id?: Identifier,
  data?: Partial<RaRecord>,
) => (data?.property_id ? `properties/${data.property_id}/show/assignees` : "show")

interface PropertyAssigneeCreateProps {
  property_id?: string
  user_id?: string
}

export const PropertyAssigneeCreate = (props: PropertyAssigneeCreateProps) => {
  return (
    <Create {...props} redirect={redirect}>
      <SimpleForm>
        <PropertyRefInput source="property_id" validate={[required()]} />
        <UserRefInput source="user_id" validate={[required()]} />
        <SelectPropertyAssigneeRoleInput source="role" validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}
