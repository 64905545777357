import { Identifier } from "react-admin"
import { useTheme } from "@mui/material/styles"

import { green, orange, red, blue } from '@mui/material/colors';

// export const rowStyle = (selectedRow?: Identifier) => (record: CalendarEntry) => {
export const rowStyle = (selectedRow?: Identifier) => (record: any) => {
  const theme = useTheme()

  let style = {}

  if (!record) {
    return style
  }

  if (selectedRow && selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    }
  }

  if (record.current_status === "confirmed")
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.current_status === "requested")
    return {
      ...style,
      borderLeftColor: blue[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.current_status === "declined")
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.current_status === "cancelled")
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }

  return style
}
