import { isString } from "lodash"
import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps } from "react-admin"

import { ICalendarEntry } from "@/types/ICalendarEntry"

import { formatDate } from "../../../utils/format-date"

interface Props {
  source?: string
}

export const CalendarEntryRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Event"
    reference="calendar_entries"
    link="show"
    // {...props}
    source={props.source ?? "calendar_entry_id"}
    sortBy="created_at"
    sortByOrder="ASC"
  >
    {/* <TextField source="title" /> */}
    <FunctionField
      label="Name"
      render={(record: ICalendarEntry) =>
        `${formatDate(record.start_date)}-${formatDate(record.end_date)} (${[
          record.type,
          record.booking_type,
          record.season_type,
        ]
          .filter(isString)
          .join("/")})`
      }
    />
  </ReferenceField>
)
