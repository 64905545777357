import MemberIcon from "@mui/icons-material/SupervisedUserCircle"

// import { MemberRefField } from "./MemberRefField"
// import { MemberRefInput } from "./MemberRefInput"
import { MemberList, MemberShow, MemberCreate, MemberEdit } from "./memberships"

const resource = {
  icon: MemberIcon,
  // list: MemberList,
  show: MemberShow,
  create: MemberCreate,
  edit: MemberEdit,
}

export // MemberIcon,
// MemberRefField,
// MemberRefInput,
// Title,
// Filter,
// ReferenceInput,
 {}

export default resource
