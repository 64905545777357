import { WarningOutlined } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { orange } from "@mui/material/colors"
import React from "react"
import {
  BooleanInput,
  FormDataConsumer,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  useRecordContext,
} from "react-admin"
import { useWatch } from "react-hook-form"

import { BillStatusSelectInput } from "./inputs/BillStatusSelectInput"

const BillNewStatusHintsField = () => {
  const record = useRecordContext()
  const newStatus = useWatch({ name: "status" })

  let text = ""
  // const values = getValues();

  if (!record) return null

  switch (newStatus) {
    // case "draft":
    //   text = "draft"
    //   break
    case "approved":
      text =
        "Make sure that all billed items and products have been added and quantities are correct. Billed items cannot be added or changed afterwards."
      break
    case "finalized":
      text =
        "Bill cannot be changed afterwards. PDF will be send to the recipient's email address automatically. Please make sure, that the address is correct"
      break
    case "closed":
      text =
        "Bill will be closed, not details can be edited anymore. Unique number cannot be used again."
      break
    case "cancelled":
      text =
        "Set only if bill is faulty. Bill will be closed and cannot be edited anymore. Unique number cannot be used again."
      break

    default:
      return null
  }

  return (
    <span style={{ marginBottom: "2em" }}>
      <Typography variant="subtitle1" sx={{ alignItems: "center", display: "flex" }}>
        <WarningOutlined sx={{ color: orange[500], marginRight: ".4em" }} /> {text}
      </Typography>
    </span>
  )
}

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillStatusForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    <TextInput source="bill_id" label="Bill id" InputProps={{ disabled: true }} />

    <BillStatusSelectInput source="status" />

    <BillNewStatusHintsField />

    <FormDataConsumer>
      {({ formData }) => (
        <>
          {formData.status === "finalized" && (
            <BooleanInput
              source="disable_document_auto_delivery"
              defaultChecked={false}
              label="Don't send bill PDF to customer automatically"
            />
          )}
        </>
      )}
    </FormDataConsumer>

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
