import { ReferenceInputProps, InputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
  property_id?: string
}

const CategoryRefInput = (
  props: Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">,
) => (
  <ReferenceInput
    source={props.source ?? "category_id"}
    reference="product_categories"
    filter={{ property_id: props.property_id }}
  >
    <SelectInput fullWidth={props.fullWidth} optionText="title" {...props} />
  </ReferenceInput>
)

CategoryRefInput.defaultProps = {
  source: "category_id",
}

export { CategoryRefInput }
