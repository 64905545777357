import { Box, Typography } from "@mui/material"
import React from "react"
import { Create, Edit, RedirectionSideEffect } from "react-admin"

import { ProviderAccountForm } from "./ProviderAccountForm"

type ElementProps = {
  children?: React.ReactChild | React.ReactChild[]
}

const Element = ({ children }: ElementProps) => <li style={{ padding: "10px 0" }}>{children}</li>

const Aside = () => (
  <Box sx={{ width: "20%", margin: "1em" }}>
    <Typography variant="h6">Instructions</Typography>
    <ul>
      <Element>The secret key will not be available to show</Element>
      <Element>One property should have only one active profile for the same provider</Element>
    </ul>
  </Box>
)

const redirectTo: RedirectionSideEffect = (resource, id, data) => "rental/provider_accounts"

const redirectToEdit: RedirectionSideEffect = (resource, id, data) =>
  data ? `${resource}/${id}/show` : "show"

export const ProviderAccountCreate = (props: any) => {
  return (
    <Create aside={<Aside />} redirect={redirectTo}>
      <ProviderAccountForm isExisting={false} />
    </Create>
  )
}

export const ProviderAccountEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirectToEdit}>
    <ProviderAccountForm isExisting />
  </Edit>
)
