import React from "react"
import { SimpleForm, SimpleFormProps, TextInput, Labeled, required, DateInput } from "react-admin"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const GroupForm: React.FC<Props> = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      <TextInput multiline rows={3} source="description" />
    </SimpleForm>
  )
}
