import OwnershipIcon from "@mui/icons-material/Assignment"

// import { OwnershipRefField } from "./OwnershipRefField"
// import { OwnershipRefInput } from "./OwnershipRefInput"
import { OwnershipList, OwnershipShow, OwnershipCreate, OwnershipEdit } from "./ownerships"

const resource = {
  icon: OwnershipIcon,
  list: OwnershipList,
  show: OwnershipShow,
  create: OwnershipCreate,
  edit: OwnershipEdit,
}

export // OwnershipIcon,
// OwnershipRefField,
// OwnershipRefInput,
// Title,
// Filter,
// ReferenceInput,
 {}

export default resource
