import {
  BooleanInput,
  Create,
  Edit,
  RedirectionSideEffect,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

import { PropertyRefInput } from "../properties"
import { ProviderAccountRefInput } from "../provider_accounts/ProviderAccountRefInput"

const redirectTo: RedirectionSideEffect = (resource, id, data) =>
  data ? `rental/provider_accounts/${data.provider_account_id}/show/properties` : "show"

export const ListedPropertiesCrete = () => {
  return (
    <Create redirect={redirectTo}>
      <SimpleForm spacing={2}>
        <PropertyRefInput source="property_id" />

        <TextInput source="provider_listing_id" label="Provider Listing ID" validate={required()} />

        <ProviderAccountRefInput source="provider_account_id" />

        <BooleanInput source="is_sync_enabled" />
      </SimpleForm>
    </Create>
  )
}
