import {
  Create,
  Edit,
  TextField,
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  NumberField,
} from "react-admin"
import { useLocation } from "react-router-dom"

import { GroupRefField } from "../groups/GroupRefField"
import { UserRefField } from "../users/UserRefField"
import { GroupMemberForm } from "./GroupMemberForm"

export const GroupMemberShow = () => (
  <Show title="GroupMember">
    <TabbedShowLayout>
      <Tab label="GroupMember Details">
        <GroupRefField source="group_id" />
        <UserRefField source="user_id" />
        <TextField source="role" />
      </Tab>
      <Tab label="Meta">
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <UserRefField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect = (url: string) => {
  return url
}

export const GroupMemberCreate = (props: any) => {
  const router = useLocation()
  const backUrl = router.search.replace("?redirectBack=", "")
  return (
    <Create {...props} redirect={redirect(backUrl)}>
      <GroupMemberForm isExisting={false} />
    </Create>
  )
}

export const GroupMemberEdit = (props: any) => {
  const router = useLocation()
  const backUrl = router.search.replace("?redirectBack=", "")
  return (
    <Edit {...props} redirect={redirect(backUrl)} actions={false}>
      <GroupMemberForm isExisting />
    </Edit>
  )
}
