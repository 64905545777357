import BillIcon from "@mui/icons-material/Receipt"

import { BillCreate, BillEdit, BillList, BillShow } from "./bills"
import { BillRefField } from "./fields/BillRefField"
import { BillRefInput } from "./inputs/BillRefInput"

const resource = {
  icon: BillIcon,
  list: BillList,
  show: BillShow,
  create: BillCreate,
  edit: BillEdit,
}

export {
  BillIcon,
  BillRefField,
  BillRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export * from "./inputs/DocumentTypeSelectInput"

export default resource
