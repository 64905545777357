import { WarningOutlined } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { orange } from "@mui/material/colors"
import React from "react"
import { SimpleForm, SimpleFormProps, TextInput, useRecordContext } from "react-admin"
import { useWatch } from "react-hook-form"

import { BillRefInput } from "../bills"
import { BillPaymentStatusSelectInput } from "./inputs/BillPaymentStatusSelectInput"

const NewPaymentStatusHintsField = () => {
  const record = useRecordContext()
  const newStatus = useWatch({ name: "status" })

  let text = ""
  // const values = getValues();

  if (!record) return null

  switch (newStatus) {
    // case "draft":
    //   text = ""
    //   break
    case "awaiting_payment":
      text = "Set this only, when documents have actually be sent to customer. Bill will be locked."
      break
    // case "open":
    //   text = ""
    //   break
    // case "past_due":
    //   text = ""
    //   break
    case "paid":
      text = "Set only when the full amount has been payed. Bill will be closed."
      break
    case "voided":
      text =
        "Set only if invoice or payment request is faulty and needs to be voided. Bill will be cancelled and cannot be edited anymore. Unique number cannot be used again."
      break
    case "uncollectible":
      text = "Bad debt. Only set if payment will not happen anymore. Bill will be closed."
      break

    default:
      return null
  }

  return (
    <span style={{ marginBottom: "2em" }}>
      <Typography variant="subtitle1" sx={{ alignItems: "center", display: "flex" }}>
        <WarningOutlined sx={{ color: orange[500], marginRight: ".4em" }} /> {text}
      </Typography>
    </span>
  )
}

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillPaymentStatusForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    <BillRefInput source="bill_id" />

    <BillPaymentStatusSelectInput source="status" />

    <NewPaymentStatusHintsField />

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
