import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  Pagination,
  PaginationProps,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin"

import appConfig from "../../../config/app.config"
import { PropertyRefField, PropertyRefInput } from "../properties"

const ListedPropertiesListFilters = (props: any) => {
  return (
    <Filter {...props}>
      <PropertyRefInput alwaysOn source="property_id" label="Property" />
    </Filter>
  )
}

const ListedPropertiesListPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const ListedPropertiesList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    filters={<ListedPropertiesListFilters />}
    exporter={false}
    pagination={<ListedPropertiesListPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <PropertyRefField />
      <TextField source="provider_listing_id" />
      <DateField source="created_at" />
      <BooleanField source="is_sync_enabled" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)
