import { Alert } from "@mui/material"
import { isNotEmptyString } from "lib/guards/is-not-empty-string"
import {
  Create,
  List,
  NumberField,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  Edit,
  Show,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  BooleanField,
  FunctionField,
  Filter,
  Pagination,
  PaginationProps,
} from "react-admin"

import { IOwner } from "@/types/IOwner"

import appConfig from "../../../config/app.config"
import { UserRefField } from "../../iam/users"
import { OwnedSharesField } from "../ownerships/OwnedSharesField"
import { PropertyRefField } from "../properties"
import { AddBillingRecipientProfileButton } from "./AddBillingRecipientProfileButton"
import { AddMembershipButton } from "./AddMembershipButton"
import { AddOwnershipButton } from "./AddOwnershipButton"
import { AddQuotaButton } from "./AddQuotaButton"
import { OwnerForm } from "./OwnerForm"
import "./OwnerRefInput"
import { OwnerRefInput } from "./OwnerRefInput"
import { OwnerTitle } from "./OwnerTitle"

const OwnerFilter = (props: any) => {
  return (
    <Filter {...props}>
      <OwnerRefInput alwaysOn source="id" label="Owner" />
    </Filter>
  )
}

const OwnersPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const OwnerList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    filters={<OwnerFilter />}
    exporter={false}
    sort={{ field: "created_at", order: "DESC" }}
    pagination={<OwnersPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <FunctionField
        label="Type"
        render={(record: IOwner) => (isNotEmptyString(record.name) ? "company" : "private")}
      />
      <TextField source="display_name" label="Name" sortable={false} />
      <TextField source="short_id" label="Short ID" />
      <TextField source="address_city" label="City" />
      <TextField source="address_country" label="Country" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const OwnerShow = () => (
  <Show title={<OwnerTitle />}>
    <TabbedShowLayout spacing={2}>
      <Tab label="Owner Details">
        <TextField source="short_id" label="Short ID" />

        <TextField source="name" label="Company Name" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="salutation" />
        <TextField source="email" />
        <TextField source="phone_cell" />
        <TextField source="phone_landline" />

        <TextField source="address_field_1" />
        <TextField source="address_field_2" />
        <TextField source="address_city" />
        <TextField source="address_postal_code" />
        <TextField source="address_country" />

        <TextField source="tax_id" label="Tax ID" />
        <TextField source="vat_id" label="VAT ID" />
        <TextField source="commercial_register_number" />
        <TextField source="local_court" />
        <TextField source="tax_exempt" />

        <TextField source="internal_notes" />
      </Tab>
      <Tab label="Contact">
        <TextField source="contact_first_name" />
        <TextField source="contact_last_name" />
        <TextField source="contact_salutation" />
        <TextField source="contact_title" />
        <TextField source="contact_email" />
        <TextField source="contact_phone_cell" />
        <TextField source="contact_phone_landline" />
        <TextField source="contact_address_field_1" />
        <TextField source="contact_address_field_2" />
        <TextField source="contact_address_city" />
        <TextField source="contact_address_postal_code" />
        <TextField source="contact_address_country" />
      </Tab>

      <Tab label="Billing Profiles" path="billing_recipient_profiles">
        <AddBillingRecipientProfileButton />
        <ReferenceManyField
          reference="billing_recipient_profiles"
          target="customer_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={
              <Alert severity="info">No billing recipient profiles existing for this owner.</Alert>
            }
          >
            <PropertyRefField source="property_id" />
            <TextField source="booking_type" />
            <TextField source="profile_name" />
            <TextField source="full_name" />
            <TextField source="tax_id" />
            <TextField source="vat_id" />
            <TextField source="has_sepa" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Members" path="memberships">
        <AddMembershipButton />
        <ReferenceManyField
          reference="customer_memberships"
          target="customer_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No members assigned.</Alert>}
          >
            <UserRefField source="user_id" />
            <TextField source="role" />
            <DateField source="created_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Ownerships" path="ownerships">
        <AddOwnershipButton />
        <ReferenceManyField
          reference="ownerships"
          target="customer_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No properties assigned.</Alert>}
          >
            <PropertyRefField source="property_id" />
            <BooleanField source="is_disabled" />
            <OwnedSharesField source="owned_shares" />
            <TextField source="internal_notes" />
            <DateField source="created_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Quotas" path="quotas">
        <AddQuotaButton />
        <ReferenceManyField
          reference="booking_quotas"
          target="customer_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No quotas assigned.</Alert>}
          >
            <PropertyRefField source="property_id" />
            <TextField source="title" />
            <NumberField source="share_count" />
            <DateField source="valid_from" />
            <DateField source="valid_to" />
            <DateField source="created_at" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta" path="advanced">
        <BooleanField
          source="feature_flags.generate_booking_bills"
          label="Generate booking bills"
        />
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const OwnerCreate = () => (
  <Create redirect="show">
    <OwnerForm isExisting={false} />
  </Create>
)

export const OwnerEdit = () => (
  <Edit mutationMode="pessimistic">
    <OwnerForm isExisting />
  </Edit>
)
