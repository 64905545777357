import { CondOperator, QueryFilter, QuerySort, RequestQueryBuilder } from "@nestjsx/crud-request"
// import { stringify } from "querystring"
import { DataProvider, fetchUtils } from "react-admin"

import appConfig from "../../../config/app.config"
import { countDiff } from "../utils"

/**
 * Maps react-admin queries to a nestjsx/crud powered REST API
 *
 * @see https://github.com/nestjsx/crud
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import crudProvider from 'ra-data-nestjsx-crud';
 *
 * import { PostList } from './posts';
 *
 * const dataProvider = crudProvider('http://localhost:3000');
 * const App = () => (
 *     <Admin dataProvider={dataProvider}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

// omitBy(newElements, (v, k) => prevElements[k] === v)

const filterHasNull = (value: string | null) => {
  if (value === null || value === "null") return true

  return false
}

export const composeFilter = (paramsFilter: Record<string, unknown>): QueryFilter[] => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter)

  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split("__")

    let field = splitKey[0]
    let ops = splitKey[1]

    if (!ops) {
      if (Array.isArray(flatFilter[key])) {
        ops = CondOperator.IN
      } else if (filterHasNull(flatFilter[key])) {
        ops = CondOperator.IS_NULL
      } else if (
        typeof flatFilter[key] === "number" ||
        typeof flatFilter[key] === "boolean" ||
        flatFilter[key].match(/^\d+$/) ||
        // Matches UUID v4:
        flatFilter[key].match(
          /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        )
      ) {
        ops = CondOperator.EQUALS
      } else {
        ops = CondOperator.CONTAINS
      }
    } else {
      ops = "$" + ops
    }

    if (field.startsWith("_") && field.includes(".")) {
      field = field.split(/\.(.+)/)[1]
    }

    return {
      field,
      operator: ops,
      value: !filterHasNull(flatFilter[key]) ? flatFilter[key] : undefined,
    } as QueryFilter
  })
}

const composeQueryParams = (queryParams: any = {}): string => {
  // return stringify(fetchUtils.flattenObject(queryParams))
  return new URLSearchParams(fetchUtils.flattenObject(queryParams)).toString()
}

export const mergeEncodedQueries = (...encodedQueries: any) =>
  encodedQueries.map((query: any) => query).join("&")

export const nestCrudDataProviderFactory = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
): DataProvider => {
  const getBaseUrl = () => {
    return apiUrl
  }

  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination
      const { q: queryParams, ...filter } = params.filter || {}

      const encodedQueryParams = composeQueryParams(queryParams)
      const composedFilter = composeFilter(filter)

      const encodedQueryFilter = RequestQueryBuilder.create({
        filter: composedFilter,
      })
        .setLimit(perPage)
        .setPage(page)
        .sortBy(params.sort as QuerySort)
        .setOffset((page - 1) * perPage)
        .query()

      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({
        data: json.data,
        total: json.total,
      }))
    },

    getOne: (resource, params) =>
      httpClient(`${getBaseUrl()}/${resource}/${params.id}`).then(({ json }) => ({
        data: json,
      })),

    getMany: (resource, params: any) => {
      //For the  array autocomplete input - transform value like [0] = [] to [0] = ""
      const value = params.ids.length && params.ids[0]?.length ? params.ids : ""
      let query = ""
      //add the field id param only when the value is exist. Avoid the queries like id=[] or id=""
      if (value) {
        query = RequestQueryBuilder.create()
          .setFilter({
            field: "id",
            // operator: CondOperator.IN,
            operator:
              Array.isArray(params.ids) && params.ids.length > 1
                ? CondOperator.IN
                : CondOperator.EQUALS,
            value: `${value}`,
          })
          .setLimit(appConfig.defaultPerPage)

          .query()
      }

      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({ data: json.data }))
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination
      const { q: queryParams, ...otherFilters } = params.filter || {}
      const filter: QueryFilter[] = composeFilter(otherFilters)

      filter.push({
        field: params.target,
        operator: CondOperator.EQUALS,
        value: params.id,
      })

      const encodedQueryParams = composeQueryParams(queryParams)
      const encodedQueryFilter = RequestQueryBuilder.create({
        filter,
      })
        .sortBy(params.sort as QuerySort)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage)
        .query()

      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)

      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url).then(({ json }) => ({
        data: resource === "scheduling_reports/owner_booking_metrics" ? json : json.data,
        total: json.total,
      }))
    },

    update: (resource, params) => {
      // //TODO: add some date type fields (from-to) for the date validations
      const fieldsAlwaysToSent = ["discount_amount", "discount_percentage"]
      // // no need to send all fields, only updated fields are enough
      let data = countDiff(params.data, params.previousData, fieldsAlwaysToSent)

      return httpClient(`${getBaseUrl()}/${resource}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then(({ json }) => ({ data: json }))
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${getBaseUrl()}/${resource}/${id}`, {
            method: "PATCH",
            body: JSON.stringify(params.data),
          }),
        ),
      ).then((responses) => ({
        data: responses.map(({ json }) => json),
      })),

    create: (resource, params) => {
      // const data = omitBy(params.data, (value) => value === "")

      return httpClient(`${getBaseUrl()}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id } as any,
      }))
    },

    delete: (resource, params) =>
      httpClient(`${getBaseUrl()}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: { ...json, id: params.id } })),

    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${getBaseUrl()}/${resource}/${id}`, {
            method: "DELETE",
          }),
        ),
      ).then((responses) => ({ data: responses.map(({ json }) => json) })),

    getManyWithQueryParams: (resource: any, queryParams: any) => {
      const encodedQueryParams = composeQueryParams(queryParams)

      const query = mergeEncodedQueries(encodedQueryParams)

      const url = `${getBaseUrl()}/${resource}?${query}`

      return httpClient(url)
    },
  }
}
