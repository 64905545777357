import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import {
  BooleanInput,
  FormDataConsumer,
  PasswordInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Validator,
  required,
} from "react-admin"

import { SelectLanguageInput, SelectTimezoneInput } from "../../../inputs"

type Props = Omit<Omit<SimpleFormProps, "children">, "render"> & {
  isExisting: boolean
}

const passwordValidation = (roles: string[]): Validator[] => {
  if (roles?.includes("admin") || roles?.includes("manager")) {
    return [required()]
  }
  return []
}

export const UserForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => {
  return (
    <SimpleForm defaultValues={defaultValues}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom>
            Identity
          </Typography>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <TextInput fullWidth source="first_name" />
            <TextInput fullWidth source="last_name" />
            <TextInput fullWidth source="slack_handle" />
          </Stack>

          <TextInput fullWidth source="email" />

          <Stack direction="row" spacing={2} alignItems="baseline">
            <SelectInput
              fullWidth
              source="salutation"
              choices={[
                { id: "mr", name: "Herr" },
                { id: "ms", name: "Frau" },
                { id: "other", name: "Keine Angabe" },
              ]}
            />
            <TextInput fullWidth source="phone" />
          </Stack>

          <Typography variant="h6" gutterBottom>
            Int
          </Typography>
          <Stack direction="row" spacing={2} alignItems="baseline">
            <SelectLanguageInput fullWidth source="language" />
            <SelectTimezoneInput fullWidth source="timezone" />
          </Stack>

          {isExisting && <BooleanInput source="change_password" name="change_password" />}
          <FormDataConsumer>
            {({ formData }) => (
              <>
                {(formData.change_password || !isExisting) && (
                  <Typography variant="h6" gutterBottom>
                    {isExisting ? "Change" : "Set"} Password
                  </Typography>
                )}
                {(formData.change_password || !isExisting) && (
                  <Stack direction="row" spacing={2} alignItems="baseline">
                    <PasswordInput
                      source="password"
                      resource="customers"
                      fullWidth
                      validate={passwordValidation(formData.roles)}
                    />
                    <PasswordInput
                      source="confirm_password"
                      resource="customers"
                      fullWidth
                      validate={passwordValidation(formData.roles)}
                    />
                  </Stack>
                )}
              </>
            )}
          </FormDataConsumer>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Access
          </Typography>
          {!isExisting && <BooleanInput fullWidth source="create_account" />}

          <SelectArrayInput
            fullWidth
            label="Roles"
            source="roles"
            choices={[
              { id: "admin", name: "Admin" },
              { id: "co_owner", name: "Co-owner" },
              { id: "manager", name: "Manager" },
            ]}
            required
          />

          <BooleanInput fullWidth source="is_disabled" />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}
