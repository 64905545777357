import React from "react"
import { SimpleForm, SimpleFormProps, TextInput, Labeled, required } from "react-admin"

import { UserRefField, UserRefInput } from "../../iam/users"
import { OwnerRefInput } from "../../real_estate/owners"
import { CalendarRefField, CalendarRefInput } from "../calendars"
import { QuotaRefInput } from "../quotas"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const CalendarMemberForm: React.FC<Props> = ({ isExisting = false, ...rest }) => {
  return (
    <SimpleForm {...rest}>
      {isExisting ? (
        <Labeled source="calendar_id" fullWidth>
          <CalendarRefField fullWidth source="calendar_id" />
        </Labeled>
      ) : (
        <CalendarRefInput source="calendar_id" validate={[required()]} />
      )}
      {isExisting ? (
        <Labeled source="user_id" fullWidth>
          <UserRefField fullWidth source="user_id" />
        </Labeled>
      ) : (
        <UserRefInput source="user_id" validate={[required()]} />
      )}

      <OwnerRefInput source="customer_id" />

      <QuotaRefInput label="Default Quota" source="default_quota_id" />

      <TextInput source="internal_notes" multiline rows={3} />
    </SimpleForm>
  )
}
