import React from "react"
import { SimpleForm, SimpleFormProps, TextInput, Labeled, required, DateInput } from "react-admin"

type Props = Partial<SimpleFormProps>

export const TagForm: React.FC<Props> = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="label" />
      <TextInput source="color" />
    </SimpleForm>
  )
}
