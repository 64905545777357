import React from "react"
import {
  DateInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  required,
  Labeled,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  minValue,
  maxValue,
  BooleanInput,
  FormDataConsumer,
} from "react-admin"

import { OwnerRefField, OwnerRefInput } from "../owners"
import { PropertyRefField, PropertyRefInput } from "../properties"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const OwnershipForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest} spacing={2}>
    {isExisting ? (
      <Labeled label="ID">
        <TextField source="id" />
      </Labeled>
    ) : null}

    {isExisting ? (
      <Labeled label="Co-Owner">
        <OwnerRefField source="customer_id" />
      </Labeled>
    ) : (
      <OwnerRefInput source="customer_id" disabled={isExisting} validate={[required()]} />
    )}

    {/* {isExisting ? (
      <Labeled label="Share">
        <ShareRefField source="share_id" />
      </Labeled>
    ) : (
      <ShareRefInput source="share_id" disabled={isExisting} validate={[required()]} />
    )} */}

    {isExisting ? (
      <Labeled label="Property">
        <PropertyRefField source="property_id" />
      </Labeled>
    ) : (
      <PropertyRefInput source="property_id" disabled={isExisting} validate={[required()]} />
    )}

    <BooleanInput source="is_disabled" />

    {/* 
    <ArrayInput source="currently_owned_shares">
      <SimpleFormIterator>
        <NumberInput source="" validate={[minValue(1), maxValue(8)]} />
      </SimpleFormIterator>
    </ArrayInput>
    */}
    <ArrayInput source="owned_shares">
      <SimpleFormIterator>
        <NumberInput source="share_no" validate={[minValue(1), maxValue(8)]} />
        <DateInput source="valid_from" />
        <DateInput source="valid_until" />
      </SimpleFormIterator>
    </ArrayInput>

    {/*
    <BooleanInput source="has_sepa" label="Has SEPA Mandate" />
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.has_sepa && (
          <>
            <TextInput
              source="sepa_mandate_ref"
              label="SEPA Mandate Ref"
              helperText="Unique Mandate Reference"
            />
            <TextInput source="sepa_merchant_name" label="SEPA Merchant Name" />
            <DateInput source="sepa_signature_date" label="SEPA Signature Date" />
          </>
        )
      }
    </FormDataConsumer>
    */}

    <TextInput source="internal_notes" multiline rows={3} />
  </SimpleForm>
)
