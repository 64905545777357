import React from "react"
import { ReferenceInputProps, InputProps, ReferenceInput, SelectInput } from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const CalendarEntryRefInput: React.FC<
  Props & Omit<ReferenceInputProps, "reference" | "children">
> = (props) => (
  <ReferenceInput source={props.source ?? "calendar_entry_id"} reference="calendar_entries">
    <SelectInput
      fullWidth={props.fullWidth}
      optionText={(record) => `${record.start_date}-${record.end_date} (${record.title})`}
      {...props}
    />
  </ReferenceInput>
)

CalendarEntryRefInput.defaultProps = {
  source: "calendar_entry_id",
}

export { CalendarEntryRefInput }
