import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps } from "react-admin"

interface Props {
  source?: string
}

interface ICompany {
  name: string
}

const CompanyRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField label="Company" source="company_id" reference="companies" link="show" {...props}>
    <FunctionField render={(record: ICompany) => (record.name ? record.name : "")} />
  </ReferenceField>
)

CompanyRefField.defaultProps = {
  source: "company_id",
}

export { CompanyRefField }
