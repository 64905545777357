import TagIcon from "@mui/icons-material/Tag"

import { TagCreate } from "./TagCreate"
import { TagEdit } from "./TagEdit"
import { TagShow } from "./TagShow"
import { TagsList } from "./TagsList"

const tagsResource = {
  icon: TagIcon,
  list: TagsList,
  show: TagShow,
  create: TagCreate,
  edit: TagEdit,
}

export { TagIcon }

export default tagsResource
