import CalendarIcon from "@mui/icons-material/CalendarToday"

import { CalendarCreate, CalendarEdit, CalendarList, CalendarShow } from "./calendars"
import { CalendarRefField } from "./CalendarRefField"
import { CalendarRefInput } from "./CalendarRefInput"
import { CalendarTitle } from "./CalendarTitle"

const calendarsResource = {
  icon: CalendarIcon,
  list: CalendarList,
  show: CalendarShow,
  create: CalendarCreate,
  edit: CalendarEdit,
}

export {
  CalendarIcon,
  CalendarRefField,
  CalendarRefInput,
  CalendarTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default calendarsResource
