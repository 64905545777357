import { RedirectionSideEffect } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  ListProps,
  ShowGuesser,
  TextField,
} from "react-admin"

import { BillRefField } from "../bills"
import { BillPaymentStatusForm } from "./BillPaymentStatusForm"
import { BillPaymentStatusUpdateForm } from "./BillPaymentStatusUpdateForm"

const BillPaymentStatusFilter = (props: any) => {
  return (
    <Filter {...props}>
      <BillRefField />
    </Filter>
  )
}

export const BillPaymentStatusList = (props: ListProps) => (
  <List {...props} filters={<BillPaymentStatusFilter />}>
    <Datagrid rowClick="show">
      <BillRefField source="bill_id" />

      <TextField source="status" />
      <TextField source="internal_notes" />

      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
)

export const BillPaymentStatusShow = ShowGuesser

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? (data.bill_id ? `bills/${data.bill_id}/show/statuses` : "edit") : "show"

export const BillPaymentStatusCreate = () => (
  <Create redirect={redirect}>
    <BillPaymentStatusForm isExisting={false} />
  </Create>
)

export const BillPaymentStatusEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <BillPaymentStatusUpdateForm />
  </Edit>
)
