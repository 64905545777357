import UserIcon from "@mui/icons-material/Person"

import { UserRefField } from "./UserRefField"
import { UserRefInput } from "./UserRefInput"
import { UserList, UserShow, UserCreate, UserEdit } from "./users"

const usersResource = {
  icon: UserIcon,
  list: UserList,
  show: UserShow,
  create: UserCreate,
  edit: UserEdit,
}

export {
  UserIcon,
  UserRefField,
  UserRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default usersResource
