import { InputProps, SelectInput } from "react-admin"

export const choices = [
  { id: "low", name: "low" },
  { id: "medium", name: "medium" },
  { id: "high", name: "high" },
  { id: "peak", name: "peak" },
]

export const EntrySeasonSelectInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={choices} />
)
