import { UserRefField } from "resources/iam/users"
import appConfig from "../../../config/app.config"
import {
  Create,
  Edit,
  required,
  Tab,
  TabbedShowLayout,
  TextInput,
  TextField,
  TabbedShowLayoutTabs,
  Show,
  Datagrid,
  DateField,
  EditButton,
  List,
  ShowButton,
  PaginationProps,
  Pagination,
  NumberField,
  EmailField,
  UrlField,
  ReferenceManyField,
  BooleanField,
  DeleteButton,
  TabbedForm,
  FormTab,
} from "react-admin"
import { ContactRefField } from "./ContactRefField"
import { PropertyRefField } from "../properties"
import { useLocation } from "react-router-dom"
import { AddPropertyToContactButton } from "../properties/AddPropertyToContactButton"
import { SelectContactTypeInput } from "./SelectContactType"
import { SelectSalutationInput } from "../../../inputs/SelectSalutationInput"
import { Box, Typography } from "@mui/material"

interface ContactProps {
  name: string
  address_field_1?: string
  address_field_2?: string
  address_city?: string
  address_postal_code?: string
  address_country?: string
  website?: string
  email?: string
  tax_number?: string
  category: string
  subcategory: string
  contact_type: string

  contact_primary_first_name?: string
  contact_primary_last_name?: string
  contact_primary_title?: string
  contact_primary_salutation?: string
  contact_primary_email?: string
  contact_primary_phone?: string
  contact_primary_company_role?: string
  contact_primary_notes?: string

  contact_secondary_first_name?: string
  contact_secondary_last_name?: string
  contact_secondary_title?: string
  contact_secondary_salutation?: string
  contact_secondary_phone?: string
  contact_secondary_email?: string
  contact_secondary_company_role?: string
  contact_secondary_notes?: string
}

interface ContactFormProps extends ContactProps {
  isExisting?: boolean
}

const ContactForm = (_props: ContactFormProps) => {
  return (
    <TabbedForm>
      <FormTab label="General">
        <Typography variant="h6" gutterBottom>
          General
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="name" validate={[required()]} fullWidth />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectContactTypeInput source="contact_type" validate={[required()]} fullWidth />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="category" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="subcategory" fullWidth />
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Address
        </Typography>

        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="address_field_1" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="address_field_2" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="address_city" fullWidth label="City" />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="address_postal_code" fullWidth label="Postal code" />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="address_country" fullWidth label="Country" />
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Contact
        </Typography>

        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="website" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="email" fullWidth />
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="phone" fullWidth />
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Tax
        </Typography>

        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="tax_number" />
          </Box>
        </Box>
      </FormTab>
      <FormTab label="Primary Contact">
        <TextInput source="contact_primary_first_name" label="First name" />
        <TextInput source="contact_primary_last_name" label="Last name" />
        <TextInput source="contact_primary_title" label="Title" />
        <SelectSalutationInput source="contact_primary_salutation" label="Salutation" />
        <TextInput source="contact_primary_email" label="Email" />
        <TextInput source="contact_primary_phone" label="Phone" />
        <TextInput source="contact_primary_company_role" label="Company role" />
        <TextInput source="contact_primary_notes" label="Notes" multiline rows={3} fullWidth />
      </FormTab>
      <FormTab label="Secondary Contact">
        <TextInput source="contact_secondary_first_name" label="First name" />
        <TextInput source="contact_secondary_last_name" label="Last name" />
        <TextInput source="contact_secondary_title" label="Title" />
        <SelectSalutationInput source="contact_secondary_salutation" label="Salutation" />
        <TextInput source="contact_secondary_email" label="Email" />
        <TextInput source="contact_secondary_phone" label="Phone" />
        <TextInput source="contact_secondary_company_role" label="Company role" />
        <TextInput source="contact_secondary_notes" label="Notes" multiline rows={3} fullWidth />
      </FormTab>
    </TabbedForm>
  )
}

export const ContactCreate = (props: ContactProps) => {
  return (
    <Create {...props} redirect="list">
      <ContactForm {...props} />
    </Create>
  )
}

export const ContactEdit = (props: ContactProps) => {
  const propsWithIsExisting = { ...props, isExisting: true }
  return (
    <Edit mutationMode="pessimistic">
      <ContactForm {...propsWithIsExisting} />
    </Edit>
  )
}

export const ContactShow = (props: ContactProps) => {
  const router = useLocation()
  return (
    <Show title="Contacts">
      <TabbedShowLayout
        spacing={2}
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
      >
        <Tab label="Contact Details">
          <TextField source="name" />
          <TextField source="contact_type" />
          <TextField source="category" />
          <TextField source="subcategory" />
          <TextField source="address_field_1" />
          <TextField source="address_field_2" />
          <TextField source="address_city" />
          <TextField source="address_postal_code" />
          <TextField source="address_country" />
          <UrlField source="website" />
          <EmailField source="email" />
          <TextField source="phone" />
          <TextField source="tax_number" />
        </Tab>

        <Tab label="Primary Contact">
          <TextField source="contact_primary_first_name" label="First name" />
          <TextField source="contact_primary_last_name" label="Last name" />
          <TextField source="contact_primary_title" label="Title" />
          <TextField source="contact_primary_salutation" label="Salutation" />
          <EmailField source="contact_primary_email" label="Email" />
          <TextField source="contact_primary_phone" label="Phone" />
          <TextField source="contact_primary_company_role" label="Company role" />
          <TextField source="contact_primary_notes" label="Notes" />
        </Tab>
        <Tab label="Secondary Contact">
          <TextField source="contact_secondary_first_name" label="First name" />
          <TextField source="contact_secondary_last_name" label="Last name" />
          <TextField source="contact_secondary_title" label="Title" />
          <TextField source="contact_secondary_salutation" label="Salutation" />
          <EmailField source="contact_secondary_email" label="Email" />
          <TextField source="contact_secondary_phone" label="Phone" />
          <TextField source="contact_secondary_company_role" label="Company role" />
          <TextField source="contact_secondary_notes" label="Notes" />
        </Tab>

        <Tab label="Properties">
          <AddPropertyToContactButton />
          <ReferenceManyField reference="property_contacts" target="Contact_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <ContactRefField source="contact_id" />
              <PropertyRefField source="property_id" />
              <ContactRefField source="contact_id" />
              <PropertyRefField source="property_id" />
              <TextField source="collaboration_status" />
              <BooleanField source="is_visible_to_customers" />
              <BooleanField source="is_visible_to_customers" />
              <UserRefField source="created_by" />
              <DateField source="created_at" />

              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          <UserRefField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const ContactPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const ContactList = () => {
  return (
    <List
      perPage={appConfig.paginationOptions[0]}
      exporter={false}
      sort={{ field: "name", order: "DESC" }}
      pagination={<ContactPagination />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <TextField source="tax_number" />
        <TextField source="address_field_1" />
        <TextField source="address_city" label="City" />
        <TextField source="address_country" label="Country" />
        <TextField source="address_postal_code" label="Postal code" />
        <TextField source="email" />
        <TextField source="phone" />
        <DateField source="created_at" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}
