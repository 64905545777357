import React from "react"
import {
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  DateInput,
  FormDataConsumer,
} from "react-admin"

import { OwnerRefField } from "../owners"
import { GuestTemplateRefInput } from "./GuestTemplateRefInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const GuestTemplateForm: React.FC<Props> = ({ isExisting = false, ...rest }) => {
  return (
    <SimpleForm {...rest}>
      <Labeled source="customer_id" fullWidth>
        <OwnerRefField fullWidth source="customer_id" />
      </Labeled>

      <FormDataConsumer>
        {({ formData }) =>
          formData.calendar_entry_id && (
            <GuestTemplateRefInput
              source="guest_template_id"
              calendar_entry_id={formData.calendar_entry_id}
            />
          )
        }
      </FormDataConsumer>

      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="id_card_number" />
      <DateInput source="birth_date" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="internal_notes" multiline rows={3} />
    </SimpleForm>
  )
}
