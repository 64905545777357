import { GuestCreate } from "./GuestAdd"
import { GuestEdit } from "./GuestEdit"
import { GuestView } from "./GuestView"

const resource = {
  show: GuestView,
  create: GuestCreate,
  edit: GuestEdit,
}

export {}

export default resource
