import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddBillingIssuerProfileButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/billing_issuer_profiles/create",
      }}
      state={{ record: { property_id: record.id } }}
    >
      <ButtonIcon /> Create Billing Issuer Profile
    </Button>
  )
}
