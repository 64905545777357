import CalendarEntryIcon from "@mui/icons-material/Event"

import { CalendarEntryList } from "./CalendarEntryList"
import { CalendarEntryRefField } from "./CalendarEntryRefField"
import { CalendarEntryRefInput } from "./CalendarEntryRefInput"
import { CalendarEntryCreate, CalendarEntryEdit, CalendarEntryShow } from "./calendar_entries"

const calendarEntriesResource = {
  icon: CalendarEntryIcon,
  list: CalendarEntryList,
  show: CalendarEntryShow,
  create: CalendarEntryCreate,
  edit: CalendarEntryEdit,
}

export {
  CalendarEntryIcon,
  CalendarEntryRefField,
  CalendarEntryRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export * from "./inputs/BookingTypeSelectInput"

export default calendarEntriesResource
