import { useEffect, useState } from "react"
import { AutocompleteInput, useGetList, AutocompleteInputProps, OptionText } from "react-admin"

import appConfig from "../config/app.config"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  perPage?: number
  source: string
  resource: string
  optionText: OptionText
  filter?: any
  sort?: any
}

const AutocompleteInputLocal = (props: Props) => {
  let options = {
    pagination: { page: 1, perPage: props.perPage ?? appConfig.defaultPerPage },
  }

  if (props.filter) {
    options = { ...options, ...{ filter: props.filter } }
  }

  if (props.sort) {
    options = { ...options, ...{ sort: props.sort } }
  }

  const { data } = useGetList(props.resource, options)

  const [choices, setChoices] = useState<any[]>([])

  useEffect(() => {
    if (!data) {
      return
    }

    setChoices(data)
  }, [data])
  return (
    <AutocompleteInput
      {...props}
      source={props.source}
      choices={choices}
      optionText={props.optionText}
    />
  )
}

export { AutocompleteInputLocal }
