import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import { green, orange, purple, red } from "@mui/material/colors"
import makeStyles from "@mui/styles/makeStyles"
import { get } from "lodash"
import React, { memo } from "react"
import { FieldProps, useRecordContext } from "react-admin"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

const PaymentStatusField: React.FC<FieldProps> = ({ source }) => {
  const record = useRecordContext()
  const classes = useStyles()

  if (!record) return null

  const status = get(record, source ?? "current_payment_status")
  let color = "black"

  switch (status) {
    case "draft":
      color = ""
      break
    case "awaiting_payment":
      color = purple[300]
      break
    case "past_due":
      color = orange[500]
      break
    case "paid":
      color = green[500]
      break
    case "payment_failed":
      color = red[500]
      break
    case "voided":
      color = red[500]
      break
    case "uncollectible":
      color = red[900]
      break

    default:
      break
  }

  return (
    <div className={classes.root}>
      {status && <RadioButtonCheckedIcon className={classes.icon} style={{ color }} />}
      {status}
    </div>
  )
}

PaymentStatusField.defaultProps = {
  label: "Payment Status",
}

export default PaymentStatusField
