import React from "react"
import { AutocompleteInputProps, InputProps, useGetList } from "react-admin"

import appConfig from "../../../config/app.config"
import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"
import { DisabledSelectInput } from "../../../inputs/DisabledSelectInput"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

interface IProviderAccount {
  provider_name: string
  account_label: string
  is_enabled: boolean
  id: string
}

const ProviderAccountRefInput: React.FC<
  Props & Omit<Omit<AutocompleteInputProps, "source">, "reference" | "children">
> = (props) => {
  const optionRenderer = (choice: IProviderAccount) =>
    `[${choice.provider_name}]: ${choice.account_label ?? ""}`
  return (
    <AutocompleteInputLocal
      source="id"
      {...props}
      optionText={optionRenderer}
      resource="rental/provider_accounts"
      label="Existing provider account"
      style={{ width: "350px" }}
      helperText="Get existing provider account if you want to link it with this property"
    />
  )
}

ProviderAccountRefInput.defaultProps = {
  source: "id",
}

export { ProviderAccountRefInput }
