interface ErrorMessage {
  error: string
  message: string | string[]
  statusCode: number
}

export const errorHandler = (error: ErrorMessage) => {
  error.message = Array.isArray(error.message) ? error.message.join(", ") : error.message
  throw error
}
