import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddQuotaShareButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      title="Add Quota share"
      color="primary"
      component={Link}
      to={{
        pathname: "/booking_quota_shares/create",
      }}
      state={{ record: { quota_id: record.id } }}
    >
      <ButtonIcon /> Add share
    </Button>
  )
}
