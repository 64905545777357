import { InputProps, SelectArrayInput } from "react-admin"

const COUNTRY_CODE_OPTIONS = [
  {
    id: "de",
    name: "de",
  },
  {
    id: "es",
    name: "es",
  },
  {
    id: "pt",
    name: "pt",
  },
  {
    id: "at",
    name: "at",
  },
  {
    id: "ch",
    name: "ch",
  },
]

const LocationCountriesInput: React.FC<InputProps> = (props) => (
  <SelectArrayInput {...props} choices={COUNTRY_CODE_OPTIONS} />
)

LocationCountriesInput.defaultProps = {
  source: "country_codes",
}

export { LocationCountriesInput }
