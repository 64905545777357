import { InputProps, SelectInput } from "react-admin"

export const selectPropertyAssigneeRoleInputChoices = [
  { id: "supervisor", name: "Supervisor" },
  { id: "regional_manager", name: "Regional manager" },
  { id: "regional_manager_substitute", name: "Regional manager substitute" },
]

export const SelectPropertyAssigneeRoleInput: React.FC<InputProps> = (props) => (
  <SelectInput {...props} choices={selectPropertyAssigneeRoleInputChoices} />
)
