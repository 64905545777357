import { EUR } from "@dinero.js/currencies"
import { dinero, toDecimal } from "dinero.js"
import { get } from "lodash"
import { ReferenceFieldProps } from "react-admin"
import { useRecordContext } from "react-admin"

const transformer = ({ value, currency }: any) =>
  Intl.NumberFormat("de-DE", { style: "currency", currency: currency.code }).format(value)

const MoneyField = (props: Partial<ReferenceFieldProps>) => {
  const { source } = props
  const record = useRecordContext(props)

  const amount = get(record, source + "_amount")
  const scale = get(record, source + "_scale")
  const formatted = amount ? toDecimal(dinero({ amount, currency: EUR, scale }), transformer) : null

  return <div>{formatted}</div>
}

MoneyField.defaultProps = {
  source: "",
}

export { MoneyField }
