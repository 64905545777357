import {
  DateField,
  DeleteButton,
  EditButton,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from "react-admin"

import { TagTitle } from "./TagTitle"

const UserShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteButton />
  </TopToolbar>
)

export const TagShow = () => (
  <Show title={<TagTitle />} actions={<UserShowActions />}>
    <TabbedShowLayout>
      <Tab label="Tag Details">
        <TextField source="label" />
        <TextField source="color" />
      </Tab>
      <Tab label="Meta">
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
