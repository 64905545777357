import React from "react"
import { useRecordContext } from "react-admin"

const PropertyTitle: React.FC = () => {
  const record = useRecordContext()

  if (!record) return null

  return <span>{record?.short_id ?? record?.title ?? ""} - Property</span>
}

export { PropertyTitle }
