import React from "react"
import {
  NumberInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  SimpleForm,
  required,
  minValue,
  maxValue,
  number,
  BooleanInput,
  FormDataConsumer,
} from "react-admin"

import { BillRefField, BillRefInput } from "../bills"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const BillGroupForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? (
      <Labeled source="bill_id" fullWidth>
        <BillRefField source="bill_id" />
      </Labeled>
    ) : (
      <BillRefInput source="bill_id" validate={[required()]} InputProps={{ disabled: true }} />
    )}

    {/* TODO: source_product_category_id */}
    {/* <TextInput source="group_code" /> */}

    <TextInput source="title" />
    <TextInput multiline rows={3} source="description" />

    <BooleanInput source="is_condensed" helperText="If enabled, only a group total is shown" />

    <FormDataConsumer>
      {({ formData }) =>
        formData.is_condensed && (
          <>
            <BooleanInput source="display_nights_as_units" />
            <BooleanInput source="hide_discount" />
            <NumberInput
              source="vat_rate"
              validate={[number(), minValue(0), maxValue(100)]}
              helperText="As integer only (eg. 7 or 19). For Net = Gross leave empty."
            />
            {/*
            <NumberInput
              source="discount_amount"
              helperText="A fixed amount will be discounted from all items in this group. Only specify discount amount OR percentage."
            />
            <NumberInput
              source="discount_percentage"
              helperText="A percentage will be discounted from all items in this category. Only specify discount amount OR percentage."
            />
            <TextInput
              source="discount_title"
              helperText="Will be shown to describe the discount to customers."
            />
            */}
            {/*
            <BillableAmountFromSelectInput
              source="billable_amount_from"
              helperText="The amount that has actually to be payed"
            />
            */}
          </>
        )
      }
    </FormDataConsumer>
  </SimpleForm>
)
