export const SeasonChoices = [
  { id: "peak", name: "Peak" },
  { id: "high", name: "High" },
  { id: "medium", name: "Medium" },
  { id: "low", name: "Low" },
]

export const EntryTypeChoices = [
  { id: "booking", name: "Booking" },
  { id: "main_holiday_period", name: "Main Holiday Period" },
  { id: "special_date", name: "Special Date" },
  { id: "season", name: "Season" },
  { id: "rental_slot", name: "Rental Slot" },
  { id: "viewing", name: "Viewing" },
  { id: "maintenance", name: "Maintenance" },
  { id: "other", name: "Generic (was other)" },
]

export const BookingTypeChoices = [
  { id: "owner", name: "Owner" },
  { id: "owner_other_property", name: "Owner of other property" },
  { id: "external_rental", name: "External Rental (eg. Guesty)" },
  { id: "other", name: "Other" },
]
