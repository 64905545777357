import ThumbDown from "@mui/icons-material/ThumbDown"
import PropTypes from "prop-types"
import React from "react"
import { useUpdate, useNotify, useRedirect, Button, Confirm, useRecordContext } from "react-admin"

const RESOURCE = "calendar_entries"

const CancelButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const redirectTo = useRedirect()
  const [open, setOpen] = React.useState(false)

  const [approve, { isLoading }] = useUpdate(
    RESOURCE,
    {
      id: record?.id,
      data: { current_status: "cancelled" },
      previousData: record,
    },
    {
      onSuccess: () => {
        notify("Declined", {
          undoable: true,
        })
        redirectTo("/calendar_entries/" + record.id)
      },
      // onFailure: () => {
      //   notify("Error declining", "warning")
      // },
    },
  )

  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    approve()
    setOpen(false)
  }

  return record && ["confirmed"].includes(record.current_status) ? (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        label="Cancel Booking"
        startIcon={<ThumbDown color="primary" style={{ paddingRight: "0.5em", color: "orange" }} />}
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Cancel Booking"
        content="Are you sure you want to CANCEL the booking?"
        cancel="No"
        confirm="Yes"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <span />
  )
}

CancelButton.propTypes = {
  record: PropTypes.any,
}

export default CancelButton
