import { Add } from "@mui/icons-material"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddStatusButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/calendar_entry_statuses/create",
      }}
      state={{
        record: { calendar_entry_id: record.id },
      }}
    >
      <Add /> Set new Status
    </Button>
  )
}
