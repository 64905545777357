import {
  BooleanInput,
  Edit,
  RedirectionSideEffect,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

import { PropertyRefField } from "../properties"

const redirectTo: RedirectionSideEffect = (resource, id, data) =>
  data ? `rental/listed_properties` : "show"

export const ListedPropertiesEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={redirectTo}>
      <SimpleForm spacing={2}>
        <PropertyRefField source="property_id" />

        <TextInput
          source="provider_listing_id"
          label="Provider listing ID"
          helperText="Name of the provider (e.g Guesty)"
          validate={required()}
        />
        <BooleanInput source="is_sync_enabled" />
      </SimpleForm>
    </Edit>
  )
}
