import { isNumber } from "lodash"
import React from "react"
import {
  BooleanInput,
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  FormDataConsumer,
  NumberInput,
  number,
  minValue,
  maxValue,
  useRecordContext,
  useGetOne,
  SimpleForm,
} from "react-admin"

import { IProduct } from "@/types/IProduct"
import { IProductCategory } from "@/types/IProductCategory"

import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { CategoryRefField, CategoryRefInput } from "../product_categories"
import { BillableAmountFromSelectInput } from "../product_categories/inputs/BillableAmountFromSelectInput"
import { ProductPricingModelSelectInput } from "./inputs/ProductPricingModelSelectInput"
import { ProductUnitSelectInput } from "./inputs/ProductUnitSelectInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const ProductForm: React.FC<Props> = ({ isExisting = false, defaultValues }) => {
  const record = useRecordContext<IProduct>()
  const { data: category } = useGetOne<IProductCategory>(
    "product_categories",
    { id: record?.category_id ?? "" },
    { enabled: !!record?.category_id },
  )

  return (
    <SimpleForm defaultValues={defaultValues}>
      {isExisting ? (
        <Labeled source="property_id" fullWidth>
          <PropertyRefField source="property_id" />
        </Labeled>
      ) : (
        <PropertyRefInput source="property_id" validate={[required()]} fullWidth />
      )}

      {isExisting ? (
        <Labeled source="category_id" fullWidth>
          <CategoryRefField source="category_id" />
        </Labeled>
      ) : (
        <FormDataConsumer>
          {({ formData }) => {
            formData.property_id && (
              <CategoryRefInput
                source="category_id"
                validate={[required()]}
                property_id={formData.property_id}
              />
            )
          }}
        </FormDataConsumer>
      )}

      <BooleanInput
        source="is_published"
        helperText="Only published products will be included in billing/invoices"
      />

      <TextInput
        source="product_code"
        validate={[required()]}
        helperText="Can only contain lowercase a-z, underscores or hyphens."
      />

      <TextInput source="title" validate={[required()]} helperText="Displayed to customers." />
      <TextInput source="description" />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <ProductPricingModelSelectInput source="pricing_model" validate={[required()]} />

            {formData.pricing_model === "per_unit" && (
              <>
                <ProductUnitSelectInput source="unit" />
                {(formData.unit === "guest" || formData.unit === "night_and_guest") && (
                  <BooleanInput source="guest_count_includes_adults" />
                )}
                {(formData.unit === "guest" || formData.unit === "night_and_guest") && (
                  <BooleanInput source="guest_count_includes_children" />
                )}
                {(formData.unit === "guest" || formData.unit === "night_and_guest") && (
                  <BooleanInput source="guest_count_includes_infants" />
                )}
              </>
            )}
          </>
        )}
      </FormDataConsumer>

      <NumberInput
        source="vat_rate"
        validate={[number(), minValue(0), maxValue(100)]}
        disabled={category?.is_condensed}
        defaultValue={isNumber(category?.vat_rate) ? category?.vat_rate : undefined}
        helperText={
          category?.is_condensed
            ? "Can only be set on category level"
            : "As integer only (eg. 7 or 19). For Net = Gross leave empty."
        }
      />

      <BillableAmountFromSelectInput
        source="billable_amount_from"
        helperText="The amount that has actually to be payed"
      />

      {!category?.is_condensed && (
        <>
          <NumberInput
            source="discount_percentage"
            helperText="A percentage will be discounted from this product. Only specify discount amount OR percentage."
          />
          <TextInput
            source="discount_title"
            helperText="Will be shown to describe the discount to customers."
          />
        </>
      )}
    </SimpleForm>
  )
}
