// https://github.com/marmelab/ra-auth-auth0/blob/main/packages/ra-auth-auth0/src/httpClient.ts
//
import { fetchUtils } from "ra-core"
import { errorHandler } from "./errorHandler"

/**
 * An httpClient that adds authentication headers needed by Auth0 in all requests.
 * @param auth0Client the Auth0 client.
 * @returns a function with the same definition as `httpClient` that adds an `Authorization` header containing the Auth0 token.
 */
export const httpClient =
  (auth0Client: any) => async (url: any, options: fetchUtils.Options | undefined) => {
    let token: string | null = null

    try {
      token = await auth0Client.getTokenSilently()
    } catch (error) {
      // Nothing to do, we just won't set the Authorization header
    }

    const requestHeaders = getAuth0Headers(token, options)

    return fetchUtils
      .fetchJson(url, {
        ...options,
        headers: requestHeaders,
      })
      .catch((error) => errorHandler(error))
  }

/**
 * Return the headers needed by Auth0.
 * @param token the Auth0 token
 * @param options the fetch options (so that we do not override other headers)
 * @returns the headers needed by Auth0
 */
export const getAuth0Headers = (
  token: string | null,
  options: fetchUtils.Options | undefined,
): Headers => {
  const headers = ((options && options.headers) ||
    new Headers({
      Accept: "application/json",
    })) as Headers

  if (token) {
    headers.set("Authorization", `Bearer ${token}`)
  }

  return headers
}
