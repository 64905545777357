import React from "react"
import {
  NumberInput,
  BooleanInput,
  SimpleFormProps,
  Labeled,
  SimpleForm,
  required,
  TextInput,
  number,
  minValue,
  maxValue,
  FormDataConsumer,
} from "react-admin"

import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const CategoryForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? (
      <Labeled source="property_id" fullWidth>
        <PropertyRefField source="property_id" />
      </Labeled>
    ) : (
      <PropertyRefInput source="property_id" validate={[required()]} />
    )}

    <BooleanInput
      source="auto_bill_products"
      label="Automatically bill products"
      helperText="Include all products of this category in the bill by default."
    />

    <TextInput source="title" validate={[required()]} helperText="Shown to the customer" />

    <BooleanInput source="is_condensed" />

    <FormDataConsumer>
      {({ formData }) =>
        formData.is_condensed && (
          <>
            <BooleanInput source="display_nights_as_units" />
            <BooleanInput source="hide_discount" />
            <NumberInput
              source="vat_rate"
              label="VAT Rate"
              validate={[number(), minValue(0), maxValue(100)]}
              helperText="As integer only (eg. 7 or 19). For Net = Gross leave empty."
            />
            {/*
            <NumberInput
              source="discount_amount"
              helperText="A fixed amount will be discounted from all items in this category. Only specify discount amount OR percentage."
            />
            <NumberInput
              source="discount_percentage"
              helperText="A percentage will be discounted from all items in this category. Only specify discount amount OR percentage."
            />
            <TextInput
              source="discount_title"
              helperText="Will be shown to describe the discount to customers."
            />
            */}
            {/*
            <BillableAmountFromSelectInput
              source="billable_amount_from"
              helperText="Leave empty by default!"
            />
            */}
          </>
        )
      }
    </FormDataConsumer>
  </SimpleForm>
)
