import React from "react"
import {
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  DateInput,
  NumberInput,
  Toolbar,
  SaveButton,
  BooleanInput,
} from "react-admin"

import { CalendarEntryRefField } from "../calendar_entries"
import { PropertyUsageKindInput } from "./UsageKindSelectInput"
import { PropertyUsageTypeInput } from "./UsageTypeInput"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

const CustomToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
)

export const PropertyUsageForm: React.FC<Props> = ({ isExisting = false, ...rest }) => {
  return (
    <SimpleForm {...rest} toolbar={<CustomToolbar />}>
      {isExisting ? (
        <Labeled source="booking_id" fullWidth>
          <CalendarEntryRefField source="booking_id" />
        </Labeled>
      ) : (
        <TextInput
          source="booking_id"
          label="Booking ID"
          validate={[required()]}
          InputProps={{ disabled: true }}
        />
      )}

      <NumberInput source="amount" validate={[required()]} />
      <TextInput source="code" />
      <DateInput source="from" validate={[required()]} />
      <DateInput source="to" validate={[required()]} />

      <PropertyUsageKindInput style={{ width: 300 }} source="kind" validate={[required()]} />
      <PropertyUsageTypeInput source="type" validate={[required()]} />

      <BooleanInput source="is_pending" />
    </SimpleForm>
  )
}
