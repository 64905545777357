import React from "react"
import {
  NumberInput,
  SimpleFormProps,
  Labeled,
  SimpleForm,
  minValue,
  maxValue,
  required,
  number,
  TextInput,
  DateInput,
} from "react-admin"

import { ProductRefField, ProductRefInput } from "../products"

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const PriceForm: React.FC<Props> = ({ isExisting = false, ...rest }) => (
  <SimpleForm {...rest}>
    {isExisting ? (
      <Labeled source="product_id" fullWidth>
        <ProductRefField source="product_id" />
      </Labeled>
    ) : (
      <ProductRefInput
        source="product_id"
        validate={[required()]}
        InputProps={{ disabled: true }}
      />
    )}

    <DateInput
      source="valid_from"
      validate={[required()]}
      helperText="First day the price is in effect."
    />
    <DateInput
      source="valid_to"
      helperText="If left empty, the price is always valid starting at valid_from."
    />

    <NumberInput
      source="precedence"
      validate={[number(), minValue(0), maxValue(10)]}
      helperText="Min. 0, max. 10. For two or more prices on a same day, the price with higher precedence will be used. Lowest = 0, highest = 10."
    />

    <TextInput
      source="title"
      // helperText="Will be shown next to the product title, to distinguish different prices for the same product."
      helperText="If specified, replaces the product title. Displayed to customers."
    />
    {/* <TextInput source="title_de" /> */}

    <NumberInput
      source="amount"
      label="Amount (GROSS)"
      validate={[required()]}
      step={100}
      helperText="IN CENT. For unit based products, this is the price per unit."
      // helperText="IN CENT. If left empty, will be calculated automatically based on VAT rate (if specified). Net OR gross price need to be specified."
    />
  </SimpleForm>
)
