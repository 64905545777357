import { Card, CardContent, Divider, FormLabel, Stack, Typography } from "@mui/material"

type BookingRuleValidationErrorCode = {
  code: string
  limit: string | number | boolean | undefined
  value: string | number | boolean | undefined
  message?: string
}

type BookingAttributes = {
  isSpontaneous: boolean
  isLongStay: boolean
}

type RuleError = { systemName: string; errorCodes: BookingRuleValidationErrorCode[] }

type ValidationOkResult = { isValid: true }
type ValidationFailureResult = {
  isValid: false
  errors: RuleError[]
}

type ValidationResult = ValidationOkResult | ValidationFailureResult

export type BookingRuleValidationResult = ValidationResult & {
  bookingAttributes: BookingAttributes
}

interface Props {
  validationResult: BookingRuleValidationResult
}

const getAttributeValue = (validationResult: BookingRuleValidationResult, key: string): boolean => {
  const typedKey = key as keyof BookingAttributes
  return validationResult.bookingAttributes[typedKey]
}

const DisplayReason = (error: BookingRuleValidationErrorCode) => {
  return (
    <li>
      <strong>{error.code} </strong>: limit: {error.limit}, value: {error.value}{" "}
      {error.message ? `message: ${error.message}` : ""}
    </li>
  )
}

const RuleResult = (error: RuleError) => {
  return (
    <li>
      <div>
        <div>{error.systemName}</div>
        <span>Reasons:</span>
        <ul>
          {error.errorCodes.map((e) => {
            return <DisplayReason {...e} />
          })}
        </ul>
      </div>
    </li>
  )
}

export const BookingRulesValidationResult = ({ validationResult }: Props) => {
  if (!validationResult) {
    return null
  }
  return (
    <Card style={{ padding: 0 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Booking rules validation
        </Typography>

        <Typography variant="body1" component="p">
          <span> All rules passed?: {validationResult.isValid ? "Yes" : "No"}</span>
        </Typography>

        {validationResult.isValid === false && (
          <div>
            <Typography variant="body1" component="p">
              <span>Broken rules</span>
            </Typography>

            <Typography variant="body1" component="p">
              <ul>
                {validationResult.errors.map((error) => (
                  <RuleResult {...error} />
                ))}
              </ul>
            </Typography>
          </div>
        )}
        <Divider />
        <Typography variant="h6" component="div">
          <span> Booking attributes:</span>
        </Typography>
        <ul>
          {Object.keys(validationResult.bookingAttributes).map((k) => (
            <li>
              {k}: {getAttributeValue(validationResult, k) ? "Yes" : "No"}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  )
}
