import {
  Create,
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  Edit,
  Show,
  ReferenceManyField,
  NumberField,
  TabbedShowLayout,
  Tab,
  RedirectionSideEffect,
  DeleteWithConfirmButton,
} from "react-admin"

import { OwnerRefField } from "../owners"
import { PropertyRefField } from "../properties"
import { ShareForm } from "./ShareForm"
import { ShareTitle } from "./ShareTitle"

export const ShareList = () => (
  <List>
    <Datagrid rowClick="show">
      <PropertyRefField source="property_id" />
      <NumberField source="share_no" />
      <TextField source="internal_notes" />
      <OwnerRefField source="current_customer_id" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export const ShareShow = () => (
  <Show title={<ShareTitle />}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <PropertyRefField source="property_id" />
        <NumberField source="share_no" />
        <TextField source="internal_notes" />
        <TextField source="created_by" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <NumberField source="version" />
      </Tab>

      <Tab label="Ownerships" path="ownerships">
        <ReferenceManyField
          reference="ownerships"
          target="share_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid rowClick="show">
            <OwnerRefField source="customer_id" />
            <DateField showTime source="valid_from" />
            <DateField showTime source="valid_to" />
            <DateField source="created_at" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `properties/${data.property_id}/show/shares` : "show"

export const ShareCreate = () => (
  <Create redirect={redirect}>
    <ShareForm isExisting={false} />
  </Create>
)

export const ShareEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <ShareForm isExisting />
  </Edit>
)
