import { UserRefField } from "resources/iam/users"
import appConfig from "../../../config/app.config"
import {
  Create,
  Edit,
  required,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextInput,
  TextField,
  TabbedShowLayoutTabs,
  Show,
  Datagrid,
  DateField,
  EditButton,
  List,
  ShowButton,
  PaginationProps,
  Pagination,
  NumberField,
  EmailField,
  UrlField,
} from "react-admin"
import { useLocation } from "react-router-dom"
import { SelectCompanyTypeInput } from "./SelectCompanyTypeInput"
import { Box, Divider, Stack, Typography } from "@mui/material"

interface CompanyProps {
  name: string
  type: "opco" | "spv"
  address_field_1?: string
  address_field_2?: string
  address_city?: string
  address_postal_code?: string
  address_country?: string
  website?: string
  email?: string
  phone?: string
  tax_number?: string
  tax_exempt?: string
  commercial_register_number?: string
  bank_account_owner?: string
  bank_name?: string
  bank_iban?: string
  bank_bic?: string
}

interface CompanyFormProps extends CompanyProps {
  isExisting?: boolean
}

const CompanyForm = (_props: CompanyFormProps) => {
  return (
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        General
      </Typography>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="name" validate={[required()]} fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <SelectCompanyTypeInput source="type" validate={[required()]} defaultValue="opco" />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Address
      </Typography>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="address_field_1" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="address_field_2" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="address_city" label="City" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="address_postal_code" label="Postal code" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="address_country" label="Country" fullWidth />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Contact
      </Typography>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="website" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="email" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="phone" fullWidth />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Tax & Registration
      </Typography>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="tax_number" fullWidth />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="tax_exempt" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="commercial_register_number" fullWidth />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Bank Account
      </Typography>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="bank_account_owner" fullWidth label="Account owner" />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="bank_name" fullWidth label="Name" />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="bank_iban" fullWidth label="IBAN" />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="bank_bic" fullWidth label="BIC" />
        </Box>
      </Box>
    </SimpleForm>
  )
}

export const CompanyCreate = (props: CompanyProps) => {
  return (
    <Create {...props} redirect="list">
      <CompanyForm {...props} />
    </Create>
  )
}

export const CompanyEdit = (props: CompanyProps) => {
  const propsWithIsExisting = { ...props, isExisting: true }
  return (
    <Edit mutationMode="pessimistic">
      <CompanyForm {...propsWithIsExisting} />
    </Edit>
  )
}

export const CompanyShow = (props: CompanyProps) => {
  const router = useLocation()
  return (
    <Show title="Company">
      <TabbedShowLayout
        spacing={2}
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
      >
        <Tab label="Company Details">
          <TextField source="name" />
          <TextField source="type" />
          <TextField source="address_field_1" />
          <TextField source="address_field_2" />
          <TextField source="address_city" />
          <TextField source="address_postal_code" />
          <TextField source="address_country" />
          <UrlField source="website" />
          <EmailField source="email" />
          <TextField source="phone" />
          <TextField source="tax_number" />
          <TextField source="tax_exempt" />
          <TextField source="commercial_register_number" />
          <TextField source="bank_account_owner" />
          <TextField source="bank_name" />
          <TextField source="bank_iban" />
          <TextField source="bank_bic" />
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          <UserRefField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const CompanyPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const CompanyList = () => {
  return (
    <List
      perPage={appConfig.paginationOptions[0]}
      exporter={false}
      sort={{ field: "name", order: "DESC" }}
      pagination={<CompanyPagination />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="tax_number" />
        <TextField source="address_field_1" />
        <TextField source="address_city" label="City" />
        <TextField source="address_country" label="Country" />
        <TextField source="address_postal_code" label="Postal code" />
        <TextField source="email" />
        <TextField source="phone" />
        <DateField source="created_at" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}
