const appConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
  leadApiBaseUrl: process.env.REACT_APP_LEAD_API_BASE_URL || "",
  defaultPerPage: 150,

  paginationOptions: [25, 50, 100, 150],
  csvBoxImportURL: process.env.REACT_APP_CSVBOX_IMPORT_URL || "",

  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
}

export default appConfig
