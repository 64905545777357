import { Edit, RedirectionSideEffect } from "react-admin"

import { GuestForm } from "./GuestForm"

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `calendar_entries/${data.calendar_entry_id}/show/guests` : "show"

export const GuestEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <GuestForm isExisting />
  </Edit>
)
