import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useState } from "react"

import { DistributionReport } from "./DistributionReport"
import { UtilizationReport } from "./UtilizationReport"

interface PropertyReportsProps {
  propertyShortName: string
  propertyId: string
}

export const PropertyReports = ({ propertyShortName, propertyId }: PropertyReportsProps) => {
  const now = DateTime.now()
  const [year, setYear] = useState<string>(now.year.toString())

  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value)
  }

  const years = [
    now.minus({ years: 2 }).year,
    now.minus({ years: 1 }).year,
    now.year,
    now.plus({ years: 1 }).year,
    now.plus({ years: 2 }).year,
  ]

  return (
    <>
      <Typography variant="h6" color="inherit" align="center" marginTop={1}>
        Property distribution report
      </Typography>
      <DistributionReport
        propertyId={propertyId}
        propertyShortName={propertyShortName}
        yearFrom={years[0]}
        yearTo={[...years].pop() as number}
      />

      <Divider />

      <Typography variant="h6" color="inherit" align="center" marginTop={2}>
        Property utilization report per customer and year
      </Typography>

      <FormControl sx={{ m: 1, maxWidth: 120 }}>
        <InputLabel>Year</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={year}
          label="Year"
          onChange={handleChange}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year.toString()}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <UtilizationReport
        propertyId={propertyId}
        propertyShortName={propertyShortName}
        year={+year}
      />
    </>
  )
}
