import { FieldProps, useRecordContext } from "react-admin"

import { BillDto } from "@/types/Bill"

import { BookingBill } from "../../../../components/BookingBill/BookingBill"

const BookingBillField = (props: Partial<FieldProps>) => {
  const record = useRecordContext(props) as BillDto

  return <BookingBill bill={record} />
}

export default BookingBillField
