import { Add } from "@mui/icons-material"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddContactToPropertyButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/property_contacts/create",
      }}
      state={{ record: { property_id: record.id } }}
    >
      <Add /> Add Contact
    </Button>
  )
}
