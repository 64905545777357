import { Alert } from "@mui/material"
import React from "react"
import {
  Datagrid,
  DateField,
  EditButton,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

import { AddPropertyButton } from "../listed_properties/AddProperty"
import { PropertyRefField } from "../properties"
import { AddProviderButton } from "../properties/AddLProviderButton"

export const ProviderAccountShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Account details">
        <TextField source="provider_name" />
        <TextField source="account_id" />
        <TextField source="account_label" />
        <TextField source="oauth_id" />
      </Tab>
      <Tab label="Credentials">
        <TextField source="client_id" label="Client ID" />
        <TextField source="client_secret_ref" label="Client Secret Ref" />
      </Tab>
      <Tab label="Meta">
        <TextField source="id" />
        <TextField source="created_by" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
      </Tab>
      <Tab label="Properties" path="properties">
        <AddPropertyButton />
        <ReferenceManyField
          reference="rental/listed_properties"
          source="id"
          target="provider_account_id"
          sort={{ field: "created_at", order: "ASC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            empty={<Alert severity="info">No profiles existing for this property.</Alert>}
          >
            <PropertyRefField />
            <TextField source="provider_listing_id" label="Provider listing ID" />
            <TextField source="provider_account_id" label="Provider account ID" />
            <DateField source="created_at" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
