import ShareIcon from "@mui/icons-material/PieChart"

import { ShareRefField } from "./ShareRefField"
import { ShareRefInput } from "./ShareRefInput"
import { ShareTitle } from "./ShareTitle"

import { ShareList, ShareShow, ShareCreate, ShareEdit } from "./shares"

const resource = {
  icon: ShareIcon,
  // list: ShareList,
  show: ShareShow,
  create: ShareCreate,
  edit: ShareEdit,
}

export {
  // ShareIcon,
  ShareRefField,
  ShareRefInput,
  ShareTitle,
  // Filter,
  // ReferenceInput,
}

export default resource
