import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const QuotaRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField source="quota_id" reference="booking_quotas" link="show" label="Quota" {...props}>
    <TextField source="title" />
  </ReferenceField>
)

QuotaRefField.defaultProps = {
  source: "quota_id",
}

export { QuotaRefField }
