import { Alert, Avatar, Badge, BadgeOwnProps } from "@mui/material"
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ImageField,
  List,
  NumberField,
  Filter,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  ReferenceManyField,
  Pagination,
  PaginationProps,
  UrlField,
  useShowController,
  ArrayField,
  DeleteButton,
  TabbedShowLayoutTabs,
  FunctionField,
} from "react-admin"

import appConfig from "../../../config/app.config"
import { OwnerRefField } from "../owners"
import { OwnedSharesField } from "../ownerships/OwnedSharesField"
import { AddBillingIssuerProfileButton } from "./AddBillingIssuerProfileButton"
import { AddOwnershipButton } from "./AddOwnershipButton"
import { AddPropertyProfileButton } from "./AddPropertyProfileButton"
import { PropertyForm } from "./PropertyForm"
import { PropertyRefInput } from "./PropertyRefInput"
import { PropertyTitle } from "./PropertyTitle"
import PropertyReports from "./property_reports"
import { useLocation } from "react-router-dom"
import { UserRefField } from "resources/iam/users"
import { PropertyRefField } from "./PropertyRefField"
import { CalendarRefField } from "resources/scheduling/calendars"
import { AddCalendarToPropertyButton } from "resources/scheduling/calendar_properties/AddCalendarToPropertyButton"
import { AddUserToPropertyButton } from "../property_assignees/AddUserToPropertyButton"
import { IProperty } from "@/types/IProperty"
import { HomeWorkOutlined } from "@mui/icons-material"
import { CompanyRefField } from "../companies/CompanyRefField"
import { AddContactToPropertyButton } from "../property_contacts/AddContactToPropertyButton"
import { ContactRefField } from "../contacts/ContactRefField"

const getStatusBadgeColor = (status?: string): BadgeOwnProps["color"] => {
  if (status === "live") {
    return "success"
  } else if (status === "not_live") {
    return "error"
  }

  return "default"
}

const PropertyFilter = (props: any) => {
  return (
    <Filter {...props}>
      <PropertyRefInput alwaysOn source="id" label="Property" />
    </Filter>
  )
}

const PropertiesPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const PropertyList = () => (
  <List
    perPage={appConfig.paginationOptions[0]}
    exporter={false}
    filters={<PropertyFilter />}
    sort={{ field: "short_id", order: "DESC" }}
    pagination={<PropertiesPagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <FunctionField
        source="thumb_image_url"
        label=""
        render={(record: IProperty) => (
          <Badge
            color={getStatusBadgeColor(record.status)}
            overlap="circular"
            variant="dot"
            badgeContent=" "
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Avatar src={record.thumb_image_url}>
              <HomeWorkOutlined />
            </Avatar>
          </Badge>
        )}
      />
      <TextField source="short_id" label="Short ID" />
      <TextField source="spv_code" />
      <TextField source="internal_name" />
      <TextField source="status" />
      <TextField source="address_city" label="City" />
      <TextField source="address_country" label="Country" />
      <TextField source="timezone" label="Timezone" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const PropertyShow = () => {
  const { record } = useShowController()

  const router = useLocation()

  return (
    <Show title={<PropertyTitle />}>
      <TabbedShowLayout
        spacing={2}
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
      >
        <Tab label="Property Details">
          <TextField label="Short ID" source="short_id" />
          <TextField source="internal_name" />
          <TextField source="name" />
          <TextField source="spv_code" />
          <TextField source="spv_name" />
          <CompanyRefField source="spv_id" label="SPV company" />
          <CompanyRefField source="opco_id" label="OpCo" />
          <TextField source="description" />
          <TextField source="info_text" />
          <TextField source="timezone" />
          <NumberField source="max_guests_count" />
          <TextField source="status" />
          <TextField source="country_code" />
          <TextField source="type" />
          <BooleanField source="is_available_for_external_rental" />
          <TextField source="address_apartment_no" />
        </Tab>

        <Tab label="Amenities">
          <TextField label="Google Maps URL" source="amenities.gmaps_url" />
          <TextField label="Client profiling URL" source="amenities.client_profiling_url" />
          <TextField label="Google Drive folder URL" source="amenities.gdrive_folder_url" />
          <NumberField label="PAX adults" source="amenities.pax_adults" />
          <NumberField label="PAX children" source="amenities.pax_children" />
          <NumberField label="PAX infants" source="amenities.pax_infants" />
          <TextField label="Operating company" source="amenities.operating_company" />
          <TextField label="Property management company" source="amenities.property_mgmt_company" />
          <TextField
            label="Property management company phone"
            source="amenities.property_mgmt_company_phone"
          />
          <TextField
            label="Property management company email"
            source="amenities.property_mgmt_company_email"
          />
          <NumberField label="Plot area sqm" source="amenities.plot_area_sqm" />
          <NumberField label="Living area sqm" source="amenities.living_area_sqm" />
          <BooleanField
            label="Has private parking lot"
            source="amenities.has_private_parking_lot"
          />
          <TextField
            label="Private parking lot notes"
            source="amenities.private_parking_lot_notes"
          />
          <BooleanField label="Has public parking lot" source="amenities.has_public_parking_lot" />
          <TextField label="Public parking lot notes" source="amenities.public_parking_lot_notes" />
          <TextField label="Pool type" source="amenities.pool_type" />
          <TextField label="Sauna type" source="amenities.sauna_type" />
          <TextField label="Primary energy source" source="amenities.primary_energy_source" />
          <TextField label="Energy source notes" source="amenities.energy_source_notes" />
          <BooleanField label="Has solar system" source="amenities.has_solar_system" />
          <TextField label="Solar system notes" source="amenities.solar_system_notes" />
          <TextField label="Primary heating system" source="amenities.primary_heating_system" />
          <TextField label="Heating system notes" source="amenities.heating_system_notes" />
          <TextField label="Air conditioning system" source="amenities.air_conditioning_system" />
          <BooleanField label="Has whirlpool" source="amenities.has_whirlpool" />
          <TextField label="Whirlpool notes" source="amenities.whirlpool_notes" />
        </Tab>

        <Tab label="Address">
          <TextField source="address_field_1" />
          <TextField source="address_field_2" />
          <TextField source="address_postal_code" />
          <TextField source="address_city" />
          <TextField source="address_country" />
          <TextField source="address_lat" label="Latitude" />
          <TextField source="address_long" label="Longitude" />
        </Tab>

        <Tab label="Billing Profiles" path="billing_issuer_profiles">
          <AddBillingIssuerProfileButton />
          <ReferenceManyField
            reference="billing_issuer_profiles"
            target="property_id"
            sort={{ field: "created_at", order: "ASC" }}
            label={false}
          >
            <Datagrid
              rowClick="show"
              bulkActionButtons={false}
              empty={
                <Alert severity="info">
                  No billing issuer profiles existing for this property.
                </Alert>
              }
            >
              <TextField source="booking_type" />
              <TextField source="profile_name" />
              <TextField source="bank_account_owner" />
              <TextField source="bank_name" />
              <TextField source="default_bill_property_label" />
              <TextField source="default_bill_due_days" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Images">
          <ImageField source="card_image_url" sx={{ "& .RaImageField-image": { maxWidth: 400 } }} />
          <ImageField
            source="header_image_url"
            sx={{ "& .RaImageField-image": { maxWidth: 400 } }}
          />
          <ImageField
            source="thumb_image_url"
            sx={{ "& .RaImageField-image": { maxWidth: 400 } }}
          />
        </Tab>

        <Tab label="Property Reports">
          <PropertyReports propertyShortName={record?.short_id} propertyId={record?.id} />
        </Tab>

        <Tab label="Stay Info">
          <TextField source="wifi_ssid" />
          <TextField source="wifi_password" />
          <TextField source="door_code" />
          <UrlField source="owners_guide_url" />
          <ArrayField source="quick_infos">
            <Datagrid bulkActionButtons={false}>
              <TextField source="title" />
              <TextField source="description" />
              <TextField source="url" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Profiles" path="profiles">
          <AddPropertyProfileButton />
          <ReferenceManyField
            reference="property_profiles"
            target="property_id"
            sort={{ field: "created_at", order: "ASC" }}
            label={false}
          >
            <Datagrid
              bulkActionButtons={false}
              empty={<Alert severity="info">No profiles existing for this property.</Alert>}
            >
              <TextField source="checkinout_description" />
              <NumberField source="max_adults_count" />
              <NumberField source="max_children_count" />
              <NumberField source="max_infants_count" />
              <DateField source="valid_from" />
              <DateField source="valid_until" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Owners" path="ownerships">
          <AddOwnershipButton />
          <ReferenceManyField
            reference="ownerships"
            target="property_id"
            sort={{ field: "created_at", order: "ASC" }}
            label={false}
          >
            <Datagrid
              bulkActionButtons={false}
              empty={<Alert severity="info">No owners existing for this property.</Alert>}
            >
              <OwnerRefField source="customer_id" />
              <OwnedSharesField source="owned_shares" />
              <BooleanField source="is_disabled" />
              <TextField source="internal_notes" />
              <DateField source="created_at" showTime />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Calendars" path="calendars">
          <AddCalendarToPropertyButton />
          <ReferenceManyField reference="calendar_properties" target="property_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <CalendarRefField source="calendar_id" />
              <PropertyRefField source="property_id" />
              <BooleanField source="is_main" />
              <DateField source="created_at" />
              <UserRefField source="created_by" />
              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Assignees" path="assignees">
          <AddUserToPropertyButton />
          <ReferenceManyField reference="property_assignees" target="property_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <UserRefField source="user_id" />
              <PropertyRefField source="property_id" />
              <TextField source="role" />
              <UserRefField source="created_by" />
              <DateField source="created_at" />

              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Contacts" path="Contacts">
          <AddContactToPropertyButton />
          <ReferenceManyField reference="property_contacts" target="property_id" label={false}>
            <Datagrid bulkActionButtons={false}>
              <ContactRefField source="contact_id" />
              <PropertyRefField source="property_id" />
              <TextField source="collaboration_status" />
              <BooleanField source="is_visible_to_customers" />
              <BooleanField source="is_visible_to_customers" />
              <UserRefField source="created_by" />
              <DateField source="created_at" />

              <DeleteButton redirect={router.pathname} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Meta">
          <TextField source="id" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          <TextField source="created_by" />
          <NumberField source="version" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const PropertyCreate = () => (
  <Create redirect="show">
    <PropertyForm isExisting={false} defaultValues={{ total_shares_count: 8 }} />
  </Create>
)

export const PropertyEdit = () => (
  <Edit mutationMode="pessimistic">
    <PropertyForm isExisting />
  </Edit>
)
