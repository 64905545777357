import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const PriceRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField source="price_id" reference="product_prices" link="show" label="Price" {...props}>
    <TextField source="title" />
  </ReferenceField>
)

PriceRefField.defaultProps = {
  source: "price_id",
}

export { PriceRefField }
