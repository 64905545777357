import appConfig from "config/app.config"
import { Button, Loading, useGetList, useNotify, useRecordContext } from "react-admin"
import { useParams } from "react-router-dom"

import { BillPdfPreview } from "./fields/BillPdfPreview"
import { useState } from "react"
import Table from "components/Table"
import { createColumnHelper } from "@tanstack/react-table"
import { DateTime } from "luxon"
import { Alert, Card, CardContent, CardHeader, Container, Divider } from "@mui/material"
import { UserRefField } from "resources/iam/users"
import { BillDto } from "@/types/Bill"
import { isNumber } from "lodash"

interface DocumentsTable {
  filename: string
  created_by?: string
  created_at: string
  id?: string
  bill_id: string
}

interface PdfResource {
  url: string
  label: string
}

const BillSentToCustomerInfo = () => {
  const record = useRecordContext<BillDto>()

  const sentAt =
    record?.statuses?.find((status) => status.metadata?.cio_send_booking_bill_to_customer_queued_at)
      ?.metadata?.cio_send_booking_bill_to_customer_queued_at || null

  if (sentAt && isNumber(sentAt)) {
    const date = DateTime.fromSeconds(Number.parseInt(sentAt))
    return (
      <Alert
        severity="info"
        sx={{
          marginBottom: 1,
        }}
      >
        PDF send via email to <strong>{record?.recipient_email}</strong> at{" "}
        {date.toFormat("dd/MM/yyyy HH:mm:ss")}
      </Alert>
    )
  }

  return null
}

export const BillsDocuments = () => {
  const PreviewButton = ({ label }: { label: string }) => {
    return <Button label="Show" onClick={() => onClickPreviewButton(label)} />
  }

  const ShowButton = ({ fileId, label }: { fileId: string; label: string }) => {
    return <Button label="Show" onClick={() => onClickShowButton(fileId, label)} />
  }
  const onClickPreviewButton = (label: string) => {
    const url = `${appConfig.apiBaseUrl}/v1/bills/${id}/preview`
    setPdfResource({
      url,
      label,
    })
  }

  const onClickShowButton = (fileId: string, label: string) => {
    const url = `${appConfig.apiBaseUrl}/v1/bills/${id}/files/${fileId}`
    setPdfResource({
      url,
      label,
    })
  }

  const [pdfResource, setPdfResource] = useState<PdfResource | undefined>(undefined)

  const notify = useNotify()

  const { id } = useParams()

  const { data, isLoading, error } = useGetList(`bills/${id}/files`)

  if (error) {
    notify(error.message, { type: "error" })
  }

  if (isLoading && !data) return <Loading />

  const columnHelper = createColumnHelper<DocumentsTable>()

  const columns = [
    columnHelper.accessor("filename", {
      cell: (cell) => cell.getValue(),
      header: () => "File name",
    }),

    columnHelper.accessor("created_at", {
      cell: (cell) => DateTime.fromISO(cell.getValue()).toFormat("dd/MM/yyyy"),
      header: () => "Created at",
    }),

    columnHelper.accessor("created_by", {
      cell: () => {
        return <UserRefField source="created_by" />
      },
      header: () => "Created by",
    }),

    columnHelper.accessor("id", {
      cell: (cell) => {
        const fileId = cell.getValue()

        const label = cell.row.original.filename

        if (fileId) return <ShowButton fileId={fileId} label={label} />
        return <PreviewButton label={label} />
      },
      header: () => "Actions",
    }),
  ]

  return (
    <>
      <BillSentToCustomerInfo />

      {!isLoading && (
        <Table
          data={
            data
              ? [
                  {
                    filename: "Bill Preview",
                    created_at: DateTime.now().toISODate(),
                    created_by: null,
                    id: null,
                    bill_id: id,
                  },
                  ...data,
                ]
              : []
          }
          columns={columns}
        />
      )}

      {pdfResource && (
        <Card>
          <CardHeader title={pdfResource.label} />
          <CardContent>
            <BillPdfPreview url={pdfResource.url} />
          </CardContent>
        </Card>
      )}
    </>
  )
}
