import { LocalDate, TemporalAdjusters } from "@js-joda/core"

import { ICalendarEntry } from "@/types/ICalendarEntry"

import { calculateNightsBetween } from "./calculate-nights-between"

export function calculateTotalNightsInYear(
  entries: ICalendarEntry[],
  year: number,
  statuses: string[] = ["requested", "tentative", "confirmed"],
): number {
  let nightsCount = 0

  entries
    .filter((entry) => statuses.includes(entry.current_status))
    .forEach((entry) => {
      let start = LocalDate.parse(entry.start_date)
      let end = LocalDate.parse(entry.end_date)

      if (start.year() < year) {
        start = LocalDate.of(year, 1, 1)
      }

      if (end.year() > year) {
        end = LocalDate.of(year, 1, 1).with(TemporalAdjusters.lastDayOfYear()).plusDays(1)
      }

      nightsCount += calculateNightsBetween(start, end)
    })

  return nightsCount
}
