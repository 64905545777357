import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const BillGroupRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField
    source="group_id"
    reference="bill_groups"
    link="show"
    label="BillGroup"
    {...props}
  >
    <TextField source="title" />
  </ReferenceField>
)

BillGroupRefField.defaultProps = {
  source: "group_id",
}

export { BillGroupRefField }
