import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

export const AddProductButton: React.FC<ButtonProps> = (props) => {
  const record = useRecordContext(props)

  return (
    <Button
      title="Add Product"
      color="primary"
      component={Link}
      to={{
        pathname: "/products/create",
      }}
      state={{ record: { category_id: record.id, property_id: record.property_id } }}
    >
      <ButtonIcon /> Add Product
    </Button>
  )
}
