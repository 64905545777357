import makeStyles from "@mui/styles/makeStyles"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { Button } from "react-admin"
import { CSVLink } from "react-csv"

interface Props {
  data: any
  columns: any
  exportFileName?: string
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },

  th: {
    backgroundColor: theme.palette.grey[900],
    padding: "5px",
    color: theme.palette.grey[100],
    textAlign: "center",
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  td: {
    textAlign: "center",
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: "5px",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "5px",
  },
}))

export const Table = ({ data, columns, exportFileName }: Props) => {
  const classes = useStyles()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  //Headers to csv
  const headers = table
    .getHeaderGroups()
    .map((headerGroup) =>
      headerGroup.headers.map((header) => {
        return { label: header.id, key: header.id }
      }),
    )
    .flat()

  //Rows to csv
  const rows = table.getRowModel().rows.map((row) =>
    row
      .getVisibleCells()
      .map((cell) => {
        return {
          [cell.column.id]: cell.getValue(),
        }
      })
      .flat(),
  )

  return (
    <div>
      {exportFileName && (
        <div className={classes.buttonContainer}>
          <CSVLink data={data} headers={headers} separator={";"} filename={exportFileName}>
            <Button label="Download CSV" />
          </CSVLink>
        </div>
      )}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={classes.th}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td className={classes.td} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
