import { AutocompleteInputProps } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const UserRefInput = (props: Props) => {
  return (
    <AutocompleteInputLocal
      perPage={450}
      source="user_id"
      {...props}
      optionText="full_name"
      resource="users"
      label="User"
      sort={{ field: "created_at", order: "DESC" }}
    />
  )
}

export { UserRefInput }
