import PropertyIcon from "@mui/icons-material/House"
import { CompanyCreate, CompanyEdit, CompanyList, CompanyShow } from "./companies"

const companiesResource = {
  icon: PropertyIcon,
  list: CompanyList,
  show: CompanyShow,
  create: CompanyCreate,
  edit: CompanyEdit,
}

export default companiesResource
