import { AutocompleteInputProps, AutocompleteInput } from "react-admin"

export const PropertyUsageTypeChoices = [
  { id: "debit", name: "Debit" },
  {
    id: "credit",
    name: "Credit",
  },
]

export const PropertyUsageTypeInput: React.FC<AutocompleteInputProps> = (props) => (
  <AutocompleteInput emptyValue={undefined} {...props} choices={PropertyUsageTypeChoices} />
)
