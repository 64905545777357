import OwnerIcon from "@mui/icons-material/SupervisedUserCircle"

// import OwnerIcon from "@mui/icons-material/Business"
import { OwnerRefField } from "./OwnerRefField"
import { OwnerRefInput } from "./OwnerRefInput"
import { OwnerTitle } from "./OwnerTitle"
import { OwnerList, OwnerShow, OwnerCreate, OwnerEdit } from "./owners"

const resource = {
  icon: OwnerIcon,
  list: OwnerList,
  show: OwnerShow,
  create: OwnerCreate,
  edit: OwnerEdit,
}

export {
  OwnerIcon,
  OwnerRefField,
  OwnerRefInput,
  OwnerTitle,
  // Filter,
  // ReferenceInput,
}

export default resource
