import { Viewer, Worker } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { useAuthProvider } from "react-admin"
import { useQuery } from "react-query"

interface BillPDFPreviewProps {
  url: string
}

export const BillPdfPreview = (props: BillPDFPreviewProps) => {
  const authProvider = useAuthProvider()

  const { data: token, error } = useQuery(["token"], () => authProvider.getJWTToken())

  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  if (error) return <span>{error}</span>
  if (!token) return null

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3/build/pdf.worker.min.js">
      <Viewer
        fileUrl={props.url}
        httpHeaders={{
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/pdf",
        }}
        plugins={[defaultLayoutPluginInstance]}
      />
    </Worker>
  )
}
