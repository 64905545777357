import { createColumnHelper } from "@tanstack/react-table"
import Table from "components/Table"
import { useGetManyReference, Loading } from "react-admin"

interface UtilizationReportProps {
  propertyShortName: string
  propertyId: string
  year: number
}

interface UtilizationReportTable {
  customer_name: string
  nights_in_peak: number
  nights_in_high: number
  nights_in_medium: number
  nights_in_low: number
  nights_in_no_seasons: number
}

interface UtilizationReportResponse {
  nights_in_high: number
  nights_in_low: number
  nights_in_medium: number
  nights_in_no_seasons: number
  nights_in_peak: number
  customer_name: string
  customer_id: string
  id: string
}

export const UtilizationReport = ({
  year,
  propertyShortName,
  propertyId,
}: UtilizationReportProps) => {
  const { data, isLoading } = useGetManyReference<UtilizationReportResponse>(
    `scheduling_reports/property_season_utilization`,
    {
      target: "property_id",
      id: propertyId,
      filter: {
        year,
      },
    },
  )

  if (isLoading && !data) return <Loading />

  const columnHelper = createColumnHelper<UtilizationReportTable>()

  const columns = [
    columnHelper.accessor("customer_name", {
      cell: (cell) => <strong>{cell.getValue()}</strong>,
      header: () => `${propertyShortName}, ${year}`,
    }),

    columnHelper.accessor("nights_in_peak", {
      cell: (cell) => cell.getValue(),
      header: () => "Nights in peak",
    }),

    columnHelper.accessor("nights_in_high", {
      cell: (cell) => cell.getValue(),
      header: () => "Nights in high",
    }),

    columnHelper.accessor("nights_in_medium", {
      id: "nights_in_medium",

      cell: (cell) => cell.getValue(),
      header: () => "Nights in medium",
    }),

    columnHelper.accessor("nights_in_low", {
      cell: (cell) => cell.getValue(),
      header: () => "Nights in low",
    }),

    columnHelper.accessor("nights_in_no_seasons", {
      cell: (cell) => cell.getValue(),
      header: () => "No season",
    }),
  ]

  return (
    <Table
      data={data}
      columns={columns}
      exportFileName={`${propertyShortName}_utilization_report`}
    />
  )
}
