import {
  ReferenceInputProps,
  InputProps,
  ReferenceInput,
  SelectInput,
  AutocompleteArrayInput,
} from "react-admin"

interface Props extends Omit<InputProps, "source"> {
  source?: string
}

const TagRefInput = (
  props: Props & Omit<Omit<ReferenceInputProps, "source">, "reference" | "children">,
) => (
  <ReferenceInput source="tag_ids" reference="tags" perPage={30}>
    <AutocompleteArrayInput optionText="label" {...props} />
  </ReferenceInput>
)

TagRefInput.defaultProps = {
  source: "tag_ids",
  addLabel: true,
}

export { TagRefInput }

export const TagInput: React.FC<InputProps> = (props) => <SelectInput {...props} />
