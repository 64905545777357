import appConfig from "../../../../config/app.config"
import { Button, useAuthProvider, useListContext, useNotify } from "react-admin"
import { useQuery } from "react-query"

const downloadFile = async (response: Response) => {
  const fileName = getFileName(response)
  const blob = await response.blob()

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

interface DownloadButtonProps {
  resourceUrl: string
  name: string
}

const getFileName = (response: Response) => {
  const contentDisposition = response.headers.get("Content-Disposition")
  const fileName = contentDisposition?.split("filename=")[1]
  return fileName || "file"
}

export const DownloadButton = ({ resourceUrl, name }: DownloadButtonProps) => {
  let url = `${appConfig.apiBaseUrl}/${resourceUrl}`
  const authProvider = useAuthProvider()
  const { data: token } = useQuery(["token"], () => authProvider.getJWTToken())
  const { filterValues, page, perPage } = useListContext()
  const notify = useNotify()

  const params = []

  if (filterValues) {
    params.push(new URLSearchParams(filterValues).toString())
  }

  if (page) {
    params.push(`page=${page - 1}`)
  }
  if (perPage) {
    params.push(`limit=${perPage}`)
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`
  }

  const onClickHandler = () => {
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return downloadFile(response)
        }
        response.json().then((data) => {
          return notify(`Error while downloading: ${data.message}`, { type: "error" })
        })
        notify(`Error while downloading: ${response?.statusText}`, { type: "error" })
      })
      .catch((error) => {
        notify(`Error while download: ${error?.message}`, { type: "error" })
      })
  }

  return <Button label={name} onClick={() => onClickHandler()} />
}
