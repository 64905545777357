import { Create, RedirectionSideEffect, SimpleForm } from "react-admin"

import { PropertyRefInput } from "resources/real_estate/properties"
import { CalendarRefInput } from "../calendars"

const redirect: RedirectionSideEffect = (resource, id, data, state) =>
  data?.property_id ? `properties/${data.property_id}/show/calendars` : "show"

export const CalendarPropertyCreate = (props: any) => {
  return (
    <Create {...props} redirect={redirect}>
      <SimpleForm>
        <PropertyRefInput source="property_id" />
        <CalendarRefInput source="calendar_id" />
      </SimpleForm>
    </Create>
  )
}
