import { get } from "lodash"
import { FieldProps, useRecordContext } from "react-admin"

import { IOwnershipOwnedShare } from "@/types/IOwnership"

import { formatDate } from "../../../utils/format-date"

interface Props {
  source?: string
}

const OwnedSharesField: React.FC<Props & Omit<FieldProps, "reference" | "children">> = ({
  source,
}) => {
  const record = useRecordContext()
  const field = get(record, source ?? "owned_shares")

  return (
    <ul>
      {field &&
        field.map((item: IOwnershipOwnedShare) => (
          <li key={item.share_no}>
            No. {item.share_no} ({formatDate(item.valid_from) ?? "unknown"} -{" "}
            {formatDate(item.valid_until) ?? "ongoing"})
          </li>
        ))}
    </ul>
  )
}

OwnedSharesField.defaultProps = {
  label: "Owned Shares",
  source: "owned_shares",
}

export { OwnedSharesField }
