import { AutocompleteInputProps, AutocompleteInput } from "react-admin"

export const PropertyUsageKindChoices = [
  { id: "nights", name: "Nights" },
  {
    id: "active_segments",
    name: "Active Segments",
  },
  {
    id: "inquired_segments",
    name: "Inquired Segments",
  },
  {
    id: "main_holiday_segments",
    name: "Main Holiday Segments",
  },
  {
    id: "long_stay",
    name: "Long Stay",
  },
  {
    id: "special_date",
    name: "Special Date",
  },
  {
    id: "spontaneous",
    name: "Spontaneous",
  },
]

export const PropertyUsageKindInput: React.FC<AutocompleteInputProps> = (props) => (
  <AutocompleteInput emptyValue={undefined} {...props} choices={PropertyUsageKindChoices} />
)
