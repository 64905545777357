import BillLineItemIcon from "@mui/icons-material/Toc"

import { BillLineItemRefField } from "./BillLineItemRefField"
import { BillLineItemRefInput } from "./BillLineItemRefInput"
import {
  BillLineItemCreate,
  BillLineItemEdit,
  BillLineItemList,
  BillLineItemShow,
} from "./bill_line_items"

const resource = {
  icon: BillLineItemIcon,
  list: BillLineItemList,
  show: BillLineItemShow,
  create: BillLineItemCreate,
  edit: BillLineItemEdit,
}

export {
  BillLineItemIcon,
  BillLineItemRefField,
  BillLineItemRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
