import React from "react"
import { AutocompleteInputProps, InputProps, useGetOne } from "react-admin"

import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"

interface QuotaFilterParams {
  customer_id?: string
  property_id?: string
}
interface Props extends Omit<InputProps, "source">, QuotaFilterParams {
  source?: string
}

const ConditionalQuotaRefInput: React.FC<
  Props & Omit<Omit<AutocompleteInputProps, "source">, "reference" | "children">
> = (props) => {
  let filter: QuotaFilterParams = {}

  if (props.customer_id) {
    filter.customer_id = props.customer_id
  }

  if (props.property_id) {
    filter.property_id = props.property_id
  }

  return (
    <AutocompleteInputLocal
      source="customer_id"
      {...props}
      filter={filter}
      optionText="title"
      resource="booking_quotas"
      label="Quota"
    />
  )
}

ConditionalQuotaRefInput.defaultProps = {
  source: "customer_id",
}

export { ConditionalQuotaRefInput }
