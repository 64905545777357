import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const BillLineItemRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField
    source="bill_line_item_id"
    reference="bill_line_items"
    link="show"
    label="BillLineItem"
    {...props}
  >
    <TextField source="title" />
  </ReferenceField>
)

BillLineItemRefField.defaultProps = {
  source: "bill_line_item_id",
}

export { BillLineItemRefField }
