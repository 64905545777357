import React from "react"
import { ReferenceField, ReferenceFieldProps, TextField } from "react-admin"

const CategoryRefField = (props: Partial<ReferenceFieldProps>) => (
  <ReferenceField
    source="category_id"
    reference="product_categories"
    link="show"
    label="Category"
    {...props}
  >
    <TextField source="title" />
  </ReferenceField>
)

CategoryRefField.defaultProps = {
  source: "category_id",
}

export { CategoryRefField }
