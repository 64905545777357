import ButtonIcon from "@mui/icons-material/Add"
import { Button } from "@mui/material"
import React from "react"
import { ButtonProps, Link, useRecordContext } from "react-admin"

type AddGroupMemberButtonProps = ButtonProps & {
  mode: "user" | "group"
}

export const AddGroupMemberButton: React.FC<AddGroupMemberButtonProps> = (
  props: AddGroupMemberButtonProps,
) => {
  const record = useRecordContext(props)

  const state =
    props.mode === "user" ? { record: { user_id: record.id } } : { record: { group_id: record.id } }

  const redirectBackLink =
    props.mode === "user" ? `/users/${record.id}/show/1` : `/groups/${record.id}/show/1`

  return (
    <Button
      title="Add Group Member"
      color="primary"
      component={Link}
      to={{
        pathname: `/group_members/create`,
        search: `?redirectBack=${redirectBackLink}`,
      }}
      state={state}
    >
      <ButtonIcon /> Add Group Member
    </Button>
  )
}
