import PropertyProfileIcon from "@mui/icons-material/Settings"

import { PropertyProfileRefField } from "./PropertyProfileRefField"
import { PropertyProfileRefInput } from "./PropertyProfileRefInput"
import {
  PropertyProfileCreate,
  PropertyProfileEdit,
  PropertyProfileList,
  PropertyProfileShow,
} from "./property_profiles"

const resource = {
  icon: PropertyProfileIcon,
  list: PropertyProfileList,
  show: PropertyProfileShow,
  create: PropertyProfileCreate,
  edit: PropertyProfileEdit,
}

export {
  PropertyProfileIcon,
  PropertyProfileRefField,
  PropertyProfileRefInput,
  // ActivityTitle,
  // ActivityFilter,
  // ActivityReferenceInput,
}

export default resource
