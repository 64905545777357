import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
  ShowButton,
  EditButton,
  RedirectionSideEffect,
  BooleanField,
} from "react-admin"

import { BillRefField, BillRefInput } from "../bills"
import { MoneyField } from "../bills/MoneyField"
import { CategoryRefField } from "../product_categories"
import { ProductRefField } from "../products"
import { AddLineItemButton } from "./AddLineItemButton"
import { BillGroupForm } from "./BillGroupForm"

const listFilters = [<BillRefInput source="bill_id" />]

export const BillGroupList = () => (
  <List filters={listFilters} exporter={false}>
    <Datagrid rowClick="show">
      <BillRefField source="bill_id" />
      <CategoryRefField source="category_id" />

      {/* <TextField source="category_code" /> */}
      <TextField source="title" />

      <BooleanField source="is_condensed" />
      <NumberField source="vat_rate" />
      <MoneyField source="discount" label="Discount amount" />
      <NumberField source="discount_percentage" />
      <TextField source="billable_amount_from" />

      <MoneyField source="subtotal" />
      <MoneyField source="vat" />
      <MoneyField source="total" />
      <MoneyField source="due" />

      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const BillGroupShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Group Details">
        <BillRefField source="bill_id" />
        <CategoryRefField source="category_id" label="Source Category" />

        <TextField source="title" />
        {/* <TextField source="category_code" /> */}

        <BooleanField source="is_condensed" />

        <MoneyField source="subtotal" />
        <MoneyField source="vat" />
        <MoneyField source="total" />
        <MoneyField source="due" />
        <MoneyField source="deferred" />
      </Tab>

      <Tab label="Condensed Options">
        <BooleanField source="is_condensed" />
        <BooleanField source="display_nights_as_units" />
        <BooleanField source="hide_discount" />

        <TextField source="vat_rate" />
        <MoneyField source="discount" label="Discount amount" />
        <NumberField source="discount_percentage" />
        <TextField source="discount_title" />
        <TextField source="billable_amount_from" />
      </Tab>

      <Tab label="Line Items" path="line_items">
        <AddLineItemButton />
        <ReferenceManyField
          reference="bill_line_items"
          target="group_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid bulkActionButtons={false} rowClick="show">
            <ProductRefField source="product_id" />
            <TextField source="product_code" />
            <TextField source="title" />
            <NumberField source="quantity" />
            <TextField source="pricing_model" />
            <TextField source="unit" />
            <NumberField source="unit_count" />
            <TextField source="vat_rate" />
            <NumberField source="discount_percentage" />
            <MoneyField source="price" />
            <MoneyField source="subtotal" />
            <MoneyField source="vat" />
            <MoneyField source="total" />
            <TextField source="billable_amount_from" />
            <MoneyField source="deferred" />
            <MoneyField source="due" />
            <DateField source="created_at" showTime />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `bills/${data.bill_id}/show` : "show"

export const BillGroupCreate = (props: any) => (
  <Create {...props} mutationMode="pessimistic" redirect={redirect}>
    <BillGroupForm isExisting={false} />
  </Create>
)

export const BillGroupEdit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic" redirect={redirect}>
    <BillGroupForm isExisting />
  </Edit>
)
