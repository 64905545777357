import { EUR } from "@dinero.js/currencies"
import { dinero, add, isZero, toDecimal, toSnapshot } from "dinero.js"
import { Liquid } from "liquidjs"
import { Dictionary, groupBy, isNumber, reduce, toInteger } from "lodash"
import { DateTime } from "luxon"

import { BillDto, BillGroupDto } from "../../types/Bill"

export interface VATItem {
  rate: number
  amount: number
  scale?: number
}

export const liquidEngine = new Liquid()

export const transformer = ({ value, currency }: any) =>
  Intl.NumberFormat("de-DE", { style: "currency", currency: currency.code }).format(value)

export const getFormattedAmount = (
  amountInEuroCents: number | undefined,
  scale?: number,
  formatZero = true,
): string | undefined => {
  // if (amountInEuroCents === 0) {
  //   console.log("amountInEuroCents", amountInEuroCents)
  // }

  if (!isNumber(amountInEuroCents)) return

  const monetary = dinero({ amount: amountInEuroCents, currency: EUR, scale })

  // if (amountInEuroCents === 0) {
  //   console.log("amountInEuroCents", amountInEuroCents)
  //   console.dir(monetary.toJSON(), { depth: 8 })
  // }

  if (isZero(monetary) && !formatZero) return

  return toDecimal(monetary, transformer)
}

export const getFormattedDiscount = ({
  amount,
  percentage,
}: {
  amount?: number
  percentage?: number
}): string => {
  let discountText = ""

  if (amount && amount > 0) {
    discountText = getFormattedAmount(amount)!.toString()
  }

  if (percentage && percentage > 0) {
    discountText = `${percentage} %`
  }

  if (discountText) {
    discountText = `- ${discountText}`
  }

  return discountText
}

export const formatDate = (v: string | undefined) =>
  !!v ? Intl.DateTimeFormat("de-DE").format(new Date(v)) : "N/A"

//TODO: add test
export const formatDateWithoutYear = (v: string) => {
  return DateTime.fromSQL(v).toFormat("dd.M")
}

export const pxToCm = (px: number): string => {
  return `${px * 0.0264583333}cm`
}

/**
 * Collects all different VAT rates from the bill and creates sums of
 * the amounts per different rate.
 * Also returns a grand total VAT amount
 *
 * @param bill
 * @returns
 */
export function getVATSummary(bill: BillDto):
  | {
      total: { amount: number; scale: number }
      rates: VATItem[]
    }
  | undefined {
  const vats: VATItem[] = []

  // Find all different VAT rates on the bill
  if (Array.isArray(bill.groups)) {
    bill.groups.forEach((group) => {
      if (group.vat_rate) {
        // VAT can either be set on a group ...
        vats.push({ rate: group.vat_rate, amount: group.vat_amount || 0, scale: group.vat_scale })
      } else {
        // ... or on individual line items
        if (Array.isArray(group.line_items)) {
          group.line_items.forEach((lineItem) => {
            if (lineItem.vat_rate) {
              vats.push({
                rate: lineItem.vat_rate,
                amount: lineItem.vat_amount || 0,
                scale: lineItem.vat_scale,
              })
            }
          })
        }
      }
    })
  }

  if (vats.length < 1) {
    // Bill has no VATs
    return
  }
  console.log("vats", vats)

  // Reduce equal rates
  const rates = reduce<Dictionary<VATItem[]>, VATItem[]>(
    groupBy(vats, (item) => item.rate),
    (result, value, key) => {
      // Sum up amounts of all equal rates
      const snap = toSnapshot(
        value.reduce(
          (acc, { amount, scale }) =>
            add(acc, dinero({ amount, scale: scale ?? 2, currency: EUR })),
          dinero({ amount: 0, scale: 2, currency: EUR }),
        ),
      )

      result.push({ rate: toInteger(key), ...snap })

      return result
    },
    [],
  )

  // Calculate total VAT amount
  const total = rates.reduce(
    (acc, { amount, scale }) => add(acc, dinero({ amount, scale, currency: EUR })),
    dinero({ amount: 0, scale: 2, currency: EUR }),
  )

  return { total: toSnapshot(total), rates }
}
