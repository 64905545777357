import IssuerProfileIcon from "@mui/icons-material/FolderOpen"

import { IssuerProfileRefField } from "./IssuerProfileRefField"
import { IssuerProfileRefInput } from "./IssuerProfileRefInput"
import {
  IssuerProfileCreate,
  IssuerProfileEdit,
  IssuerProfileList,
  IssuerProfileShow,
} from "./billing_issuer_profiles"

const resource = {
  icon: IssuerProfileIcon,
  list: IssuerProfileList,
  show: IssuerProfileShow,
  create: IssuerProfileCreate,
  edit: IssuerProfileEdit,
}

export { IssuerProfileIcon, IssuerProfileRefField, IssuerProfileRefInput }

export default resource
