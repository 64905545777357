import {
  BooleanInput,
  Create,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

import { PropertyRefInput } from "resources/real_estate/properties"
import { ContactRefInput } from "../contacts/ContactRefInput"
import { SelectCollaborationStatusInput } from "./SelectCollaborationStatus"

const redirect: RedirectionSideEffect = (
  _resource?: string,
  _id?: Identifier,
  data?: Partial<RaRecord>,
) => (data?.property_id ? `properties/${data.property_id}/show/contacts` : "show")

interface PropertyContactCreateProps {
  property_id?: string
  contact_id?: string
}

export const PropertyContactCreate = (props: PropertyContactCreateProps) => {
  return (
    <Create {...props} redirect={redirect}>
      <SimpleForm>
        <PropertyRefInput source="property_id" validate={[required()]} />
        <ContactRefInput source="contact_id" validate={[required()]} />
        <SelectCollaborationStatusInput source="collaboration_status" label="Status" />
        <TextInput source="collaboration_notes" multiline rows={3} fullWidth />
        <BooleanInput source="is_main_property_management" defaultValue={false} />
        <BooleanInput source="is_visible_to_customers" defaultValue={false} />
        <TextInput source="services_provided_notes" multiline rows={3} fullWidth />
      </SimpleForm>
    </Create>
  )
}
