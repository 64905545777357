import { SelectInput, SelectInputProps } from "react-admin"

export const ContactTypeChoices = [
  { id: "person", name: "Person" },
  { id: "company", name: "Company" },
  { id: "public_service", name: "Public Service" },
]

export const SelectContactTypeInput: React.FC<SelectInputProps> = (props) => (
  <SelectInput emptyValue={undefined} resettable {...props} choices={ContactTypeChoices} />
)
