import { Alert } from "@mui/material"
import { Pagination, PaginationProps, useRecordContext } from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  BooleanField,
  Edit,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  List,
  Show,
  NumberField,
  TextField,
  ShowButton,
  EditButton,
} from "react-admin"

import appConfig from "../../../config/app.config"
import { PropertyRefField, PropertyRefInput } from "../../real_estate/properties"
import { MoneyField } from "../bills/MoneyField"
import { AddProductButton } from "./AddProductButton"
import { CategoryForm } from "./CategoryForm"

const listFilters = [<PropertyRefInput source="property_id" alwaysOn />]

const CategoryPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const CategoryList = () => (
  <List
    pagination={<CategoryPagination />}
    filters={listFilters}
    exporter={false}
    perPage={appConfig.paginationOptions[0]}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <PropertyRefField source="property_id" />
      <BooleanField source="auto_bill_products" label="Auto billing" />
      <TextField source="title" />
      {/* <TextField source="category_code" /> */}
      <BooleanField source="is_condensed" />
      <NumberField source="vat_rate" />
      <MoneyField source="discount" label="Discount amount" />
      <NumberField source="discount_percentage" />
      <TextField source="billable_amount_from" />
      <DateField source="created_at" showTime />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

const ProductCategoryTitle = () => {
  const record = useRecordContext()

  return <span>{record ? record.title : ""} [Product Category]</span>
}

export const CategoryShow = () => (
  <Show title={<ProductCategoryTitle />}>
    <TabbedShowLayout>
      <Tab label="Category Details">
        <PropertyRefField source="property_id" />
        {/* <TextField source="category_code" /> */}
        <TextField source="title" />
        <BooleanField source="auto_bill_products" />
        <BooleanField source="is_condensed" />
      </Tab>

      <Tab label="Condensed Options">
        <BooleanField source="is_condensed" />
        <BooleanField source="display_nights_as_units" />
        <BooleanField source="hide_discount" />
        <NumberField source="vat_rate" />
        <MoneyField source="discount" label="Discount amount" />
        <NumberField source="discount_percentage" />
        <TextField source="discount_title" />
      </Tab>

      <Tab label="Products" path="products">
        <AddProductButton />
        <ReferenceManyField
          reference="products"
          target="category_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            empty={<Alert severity="info">This category has no products yet.</Alert>}
          >
            <PropertyRefField source="property_id" />
            <BooleanField source="is_published" />
            <TextField source="product_code" />
            <TextField source="title" />
            <TextField source="pricing_model" />
            <TextField source="unit" />
            <NumberField source="discount_percentage" />
            <NumberField source="vat_rate" />
            <TextField source="billable_amount_from" />
            <DateField source="created_at" showTime />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const CategoryCreate = (props: any) => {
  const defaultValues = {
    auto_bill_products: true,
    display_nights_as_units: true,
    hide_discount: true,
  }

  return (
    <Create {...props} mutationMode="pessimistic" redirect="show">
      <CategoryForm isExisting={false} defaultValues={defaultValues} />
    </Create>
  )
}

export const CategoryEdit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
    <CategoryForm isExisting />
  </Edit>
)
