import React from "react"
import { FunctionField, ReferenceField, ReferenceFieldProps } from "react-admin"

interface Props {
  source?: string
}

interface IContact {
  name: string
}

const ContactRefField: React.FC<
  Props & Omit<Omit<ReferenceFieldProps, "source">, "reference" | "children">
> = (props) => (
  <ReferenceField
    label="Contacts"
    source="contact_id"
    reference="Contacts"
    link="show"
    {...props}
  >
    <FunctionField render={(record: IContact) => (record.name ? record.name : "")} />
  </ReferenceField>
)

ContactRefField.defaultProps = {
  source: "Contact_id",
}

export { ContactRefField }
