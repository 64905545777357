import React from "react"
import { AutocompleteInputProps, InputProps, useGetList, useGetOne } from "react-admin"
import { useFormContext } from "react-hook-form"

import appConfig from "../../../config/app.config"
import { AutocompleteInputLocal } from "../../../inputs/AutocompleteInputLocal"
import { DisabledSelectInput } from "../../../inputs/DisabledSelectInput"

interface Props extends Omit<InputProps, "source"> {
  source?: string
  property_id: string
}

interface IOwnershipResponse {
  customer_id: string
  id: string
}

const ConditionalOwnerRefInput: React.FC<
  Props & Omit<Omit<AutocompleteInputProps, "source">, "reference" | "children">
> = (props) => {
  const { resetField } = useFormContext()

  const { data: ownershipsData } = useGetList<IOwnershipResponse>("ownerships", {
    pagination: {
      page: 1,
      perPage: appConfig.defaultPerPage,
    },
    filter: {
      property_id: props.property_id,
    },
  })

  if (!ownershipsData?.length) {
    return <DisabledSelectInput source="owner" label="Owner" fullWidth />
  }

  const customerIds = ownershipsData?.map((ownership) => ownership.customer_id)

  return (
    <AutocompleteInputLocal
      source="customer_id"
      {...props}
      filter={{ id: customerIds }}
      optionText={(choice) => `${choice.display_name} (${choice.short_id})`}
      resource="customers"
      label="Owner"
      onChange={() => {
        resetField("user_id")
      }}
    />
  )
}

ConditionalOwnerRefInput.defaultProps = {
  source: "customer_id",
}

export { ConditionalOwnerRefInput }
